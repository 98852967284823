import { useNavigate } from "react-router-dom";
import { MenuItemComponent } from "../../../../uis/Item/MenuItem";
import { MANAGEMENT_DOCUMENT_PATH } from "../../../../utils/constant";

export const Document = () => {
  const navigate = useNavigate();

  const menuItems = [
    {
      title: "書類",
      items: [
        {
          text: "見積書一覧",
          link: `${MANAGEMENT_DOCUMENT_PATH}/quotation_list`,
        },
        {
          text: "注文書一覧",
          link: `${MANAGEMENT_DOCUMENT_PATH}/order_form_list`,
        },
        {
          text: "納品書一覧",
          link: `${MANAGEMENT_DOCUMENT_PATH}/delivery_note_list`,
        },
        {
          text: "請求書一覧",
          link: `${MANAGEMENT_DOCUMENT_PATH}/invoice_list`,
        },
        {
          text: "産地証明書の申請",
          link: `${MANAGEMENT_DOCUMENT_PATH}/certificate`,
        },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    navigate(link);
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={false}
    />
  );
};
