import { Box, Button, Typography } from "@mui/material";
import { useDownloadFromS3 } from "../../hooks/S3/useSignedURLHook";
import { toast } from "react-toastify";
import { FileReference } from "../../utils/types/item_type";

export type AcceptedCategories =
  | "quotation"
  | "order_form"
  | "delivery_note"
  | "invoice";

interface FileDownloadProps {
  file: FileReference;
  category: AcceptedCategories;
}

const categoryLabels: Record<AcceptedCategories, string> = {
  quotation: "見積書",
  order_form: "注文書",
  delivery_note: "納品書",
  invoice: "請求書",
};

export const FileDownload: React.FC<FileDownloadProps> = ({
  file,
  category,
}) => {
  const { mutateAsync, isPending } = useDownloadFromS3();

  const handleClick = async (filetype: "xlsx" | "pdf") => {
    try {
      if (!file.id || !file.itemType) {
        toast.error("ファイルが存在しません");
        return;
      }
      const res = await mutateAsync({
        filetype,
        id: file.id,
        category: `${file.itemType}_${category}`,
      });
      const link = document.createElement("a");
      link.href = res;
      link.download = `${file.itemType}_${category}.${filetype}`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 2,
        p: 2,
        border: "1px solid #e0e0e0",
        background: "#F2F2F2",
        borderRadius: 2,
      }}
    >
      {/* 左上のテキスト */}
      <Typography variant="h6" sx={{ mb: 2, fontSize: "1rem" }}>
        {categoryLabels[category]}をダウンロードする
      </Typography>

      {/* スペースを埋めるためのBox */}
      <Box sx={{ flexGrow: 1 }} />

      {/* 下部のボタン */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        <Button
          variant="outlined"
          onClick={() => handleClick("xlsx")}
          disabled={isPending}
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            borderColor: "#000000",
            width: "45%",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              borderColor: "#000000",
            },
          }}
        >
          Excel
        </Button>
        <Button
          variant="outlined"
          onClick={() => handleClick("pdf")}
          disabled={isPending}
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            borderColor: "#000000",
            width: "45%",
            "&:hover": {
              backgroundColor: "#f5f5f5",
              borderColor: "#000000",
            },
          }}
        >
          PDF
        </Button>
      </Box>
    </Box>
  );
};
