import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import {
  LogMarketPriceDetail,
  useGetLogMarketPriceById,
} from "../../../../hooks/useLogMarketPriceHook";
import { useParams } from "react-router-dom";
import { ColumnDefinitionForTable } from "../../../../uis/Table";

export const PastLogMarketPrice = () => {
  const { log_market_price_id } = useParams<{ log_market_price_id: string }>();
  const { data, error, isPending } = useGetLogMarketPriceById(
    Number(log_market_price_id),
  );

  const columns: ColumnDefinitionForTable<LogMarketPriceDetail>[] = [
    { label: "樹種", field: "species", type: "text" },
    { label: "素材", field: "material", type: "text" },
    { label: "産地", field: "origin", type: "text" },
    { label: "等級", field: "grade", type: "text" },
    { label: "末口径", field: "top_diameter", type: "number" },
    { label: "長さ", field: "length", type: "number" },
    { label: "単材積", field: "unit_volume", type: "number", step: "any" },
    { label: "本数", field: "quantity", type: "number" },
    { label: "材積", field: "total_volume", type: "number", step: "any" },
    { label: "相場", field: "market_price", type: "number" },
  ];

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell key={col.field as string}>{col.label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.log_market_price_details &&
            data.log_market_price_details.length > 0 &&
            data.log_market_price_details.map(
              (log_market_price_detail, index) => (
                <TableRow key={index}>
                  {columns.map((col) => (
                    <TableCell key={col.field}>
                      <span style={{ color: "gray" }}>
                        {col.type === "number"
                          ? Number(
                              log_market_price_detail[col.field],
                            ).toLocaleString()
                          : log_market_price_detail[col.field]}
                      </span>
                    </TableCell>
                  ))}
                </TableRow>
              ),
            )}
        </TableBody>
      </Table>
    </Box>
  );
};
