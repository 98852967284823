import { useMutation } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import config from "../config";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { handleResponse } from "../utils/response";
import { ReadonlyResponseWithURL } from "../utils/types/general_type";

export type PaymentMethod = "bank" | "card";

export function usePayStripe() {
  const stripe = useStripe();
  const elements = useElements();
  return useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        toast.error("Stripeが正しく設定されていません");
        return;
      }
      const result = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
      if (result.error) {
        throw new Error(result.error.message || "支払いに失敗しました");
      }
      return;
    },
    onSuccess: async () => {
      toast.success(`Stripeの支払いに成功しました`);
    },
  });
}

export function useStripeAccountLinkCreate() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(`${config.backendUrl}/api/stripe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        credentials: "include",
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithURL>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
    },
  });
}
