import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import config from "../config";
import { ItemType } from "../utils/types/item_type";
import { toast } from "react-toastify";
import { documentKeys } from "../utils/query-key";
import { PAGE_SIZE } from "../utils/constant";

interface Email {
  email: string;
  deliveryNoteId: number;
  type: ItemType;
  selectedFile: File;
}

interface EmailResponse {
  url: string;
}

export function useSendEmail() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      deliveryNoteId,
      email,
      type,
      selectedFile,
    }: Email) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const presignedResponse = await fetch(`${config.backendUrl}/api/email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          deliveryNoteId,
          email,
          type,
          filename: selectedFile.name,
          filetype: selectedFile.type,
        }),
      });

      if (!presignedResponse.ok) {
        const responseJson = await presignedResponse.json();
        throw new Error(
          responseJson.error || "署名付きURLの取得中にエラーが発生しました",
        );
      }
      const { url } = (await presignedResponse.json()) as EmailResponse;
      const response = await fetch(url, {
        method: "PUT",
        body: selectedFile,
      });
      if (!response.ok) {
        throw new Error("署名付きURLの送信中にエラーが発生しました");
      }

      return;
    },
    onSuccess: async () => {
      toast.success("メールを送信しました");
      await queryClient.invalidateQueries({
        queryKey: documentKeys.deliveryNotesByReceiver({
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}
