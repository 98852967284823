import {
  MANAGEMENT_DOCUMENT_PATH,
  MANAGEMENT_MONEY_PATH,
} from "../../utils/constant";
import { TabbedLayout } from "./TabbedLayout";

export const ManagementLayout = () => {
  const tabs = [
    { label: "在庫", path: "/management/stock" },
    { label: "書類", path: MANAGEMENT_DOCUMENT_PATH },
    { label: "お金", path: MANAGEMENT_MONEY_PATH },
  ] as const;

  return <TabbedLayout tabs={tabs} />;
};
