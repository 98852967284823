import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UsersItem, ItemType } from "../../utils/types/item_type";
import { getUserLists } from "../../utils/user";
import { UserInfo } from "../UserInfo";
import { FileDownload } from "../File/FileDownload";
import { WoodOrder, WoodOrders } from "../../hooks/Wood/useWoodOrderHook";
import { LogOrder, LogOrders } from "../../hooks/Log/useLogOrderHook";
import {
  BlueprintOrder,
  BlueprintOrders,
} from "../../hooks/Blueprint/useBlueprintOrderHook";
import Avatar from "../Avatar"; // Avatarコンポーネントをインポート
import { Color } from "../../utils/color";
import { ItemsRenderDetails } from "../RenderDetails";

interface OrderDetailModalProps {
  open: boolean;
  onClose: () => void;
  userItem: UsersItem | null;
  isUserShown?: boolean;
}

// 定義: ドキュメントとアイテムタイプを含む型
type DocumentWithItemType<T extends Document> = {
  document: T;
  itemType: ItemType;
};

interface Document {
  id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

// ヘルパー関数: ドキュメントとアイテムタイプを取得
function getDocumentWithItemType<T extends Document>(
  wood_orders: WoodOrders | undefined,
  log_orders: LogOrders | undefined,
  blueprint_orders: BlueprintOrders | undefined,
  predicate: (it: WoodOrder | LogOrder | BlueprintOrder) => boolean,
  getDocument: (order: WoodOrder | LogOrder | BlueprintOrder) => T | undefined,
): DocumentWithItemType<T> | undefined {
  const orderTypes: ItemType[] = ["wood", "log", "blueprint"];

  for (const type of orderTypes) {
    let orders: LogOrders | WoodOrders | BlueprintOrders | undefined;
    switch (type) {
      case "wood":
        orders = wood_orders;
        break;
      case "log":
        orders = log_orders;
        break;
      case "blueprint":
        orders = blueprint_orders;
        break;
      default:
        orders = undefined;
    }

    if (orders) {
      let order: WoodOrder | LogOrder | BlueprintOrder | undefined;
      // 各タイプに応じて型を明示的に扱う
      switch (type) {
        case "wood":
          order = (orders as WoodOrders).find(
            predicate as (it: WoodOrder) => boolean,
          );
          break;
        case "log":
          order = (orders as LogOrders).find(
            predicate as (it: LogOrder) => boolean,
          );
          break;
        case "blueprint":
          order = (orders as BlueprintOrders).find(
            predicate as (it: BlueprintOrder) => boolean,
          );
          break;
        default:
          order = undefined;
      }

      if (order) {
        const document = getDocument(order);
        if (document) {
          return { document, itemType: type };
        }
      }
    }
  }

  return undefined;
}

// ヘルパー関数: 複数のドキュメントとアイテムタイプを取得
function getDocumentsWithItemType<T extends Document>(
  wood_orders: WoodOrders | undefined,
  log_orders: LogOrders | undefined,
  blueprint_orders: BlueprintOrders | undefined,
  predicate: (it: WoodOrder | LogOrder | BlueprintOrder) => boolean,
  getDocument: (order: WoodOrder | LogOrder | BlueprintOrder) => T | undefined,
): DocumentWithItemType<T>[] {
  const results: DocumentWithItemType<T>[] = [];
  const orderTypes: ItemType[] = ["wood", "log", "blueprint"];

  for (const type of orderTypes) {
    let orders: LogOrders | WoodOrders | BlueprintOrders | undefined;
    switch (type) {
      case "wood":
        orders = wood_orders;
        break;
      case "log":
        orders = log_orders;
        break;
      case "blueprint":
        orders = blueprint_orders;
        break;
      default:
        orders = undefined;
    }

    if (orders) {
      let matchedOrders: (WoodOrder | LogOrder | BlueprintOrder)[] = [];

      switch (type) {
        case "wood":
          matchedOrders = (orders as WoodOrders).filter(
            predicate as (it: WoodOrder) => boolean,
          );
          break;
        case "log":
          matchedOrders = (orders as LogOrders).filter(
            predicate as (it: LogOrder) => boolean,
          );
          break;
        case "blueprint":
          matchedOrders = (orders as BlueprintOrders).filter(
            predicate as (it: BlueprintOrder) => boolean,
          );
          break;
        default:
          matchedOrders = [];
      }

      for (const order of matchedOrders) {
        const document = getDocument(order);
        if (document) {
          results.push({ document, itemType: type });
        }
      }
    }
  }

  return results;
}

export const OrderDetailModal: React.FC<OrderDetailModalProps> = ({
  open,
  onClose,
  userItem,
  isUserShown = true,
}) => {
  if (!userItem) return null;

  const handleClose = (
    event: {},
    reason: "backdropClick" | "escapeKeyDown",
  ) => {
    // バックドロップクリックやエスケープキーによる閉鎖を無効化
    // 何もしない
  };

  const { users, item } = userItem;

  const wood = item?.wood;
  const log = item?.log;
  const blueprint = item?.blueprint;

  const wood_orders = wood?.wood_orders;
  const log_orders = log?.log_orders;
  const blueprint_orders = blueprint?.blueprint_orders;

  // ドキュメントの取得とItemTypeの付与
  const quotations = getDocumentsWithItemType(
    wood_orders,
    log_orders,
    blueprint_orders,
    (it) => it.is_received,
    (order) => {
      if ("wood_quotation" in order && order.wood_quotation)
        return order.wood_quotation;
      if ("log_quotation" in order && order.log_quotation)
        return order.log_quotation;
      if ("blueprint_quotation" in order && order.blueprint_quotation)
        return order.blueprint_quotation;
      return undefined;
    },
  );

  const order_form = getDocumentWithItemType(
    wood_orders,
    log_orders,
    blueprint_orders,
    (it) => it.is_contract_signed,
    (order) => {
      if ("wood_order_form" in order && order.wood_order_form)
        return order.wood_order_form;
      if ("log_order_form" in order && order.log_order_form)
        return order.log_order_form;
      if ("blueprint_order_form" in order && order.blueprint_order_form)
        return order.blueprint_order_form;
      return undefined;
    },
  );

  const delivery_note = getDocumentWithItemType(
    wood_orders,
    log_orders,
    blueprint_orders,
    (it) => it.is_contract_signed,
    (order) => {
      if ("wood_delivery_note" in order && order.wood_delivery_note)
        return order.wood_delivery_note;
      if ("log_delivery_note" in order && order.log_delivery_note)
        return order.log_delivery_note;
      if ("blueprint_delivery_note" in order && order.blueprint_delivery_note)
        return order.blueprint_delivery_note;
      return undefined;
    },
  );

  const invoice = getDocumentWithItemType(
    wood_orders,
    log_orders,
    blueprint_orders,
    (it) => it.is_contract_signed,
    (order) => {
      if ("wood_invoice" in order && order.wood_invoice)
        return order.wood_invoice;
      if ("log_invoice" in order && order.log_invoice) return order.log_invoice;
      if ("blueprint_invoice" in order && order.blueprint_invoice)
        return order.blueprint_invoice;
      return undefined;
    },
  );

  // 使用例
  const {
    isContractingUser,
    isReceivingUsers,
    isPendingUsers,
    isDenyingUsers,
    isApplyingUsers,
  } = getUserLists({ users, wood, log, blueprint });

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 0, // ヘッダーとコンテンツを分けるためにパディングを0に
          width: { xs: "90%", sm: "80%" }, // レスポンシブ対応
          maxHeight: "90vh",
          overflowY: "auto",
          borderRadius: 2,
        }}
      >
        {/* ヘッダー */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: Color.Main,
            color: "white",
            p: 2,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
          }}
        >
          <Typography variant="h6">詳細確認</Typography>
          <IconButton
            onClick={onClose}
            sx={{ color: "white" }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* コンテンツ */}
        <Box sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            注文の詳細
          </Typography>
          {isUserShown && (
            <Box>
              {/* ユーザーセクション */}
              <Box sx={{ mb: 4 }}>
                {/* 契約しているユーザー */}
                {isContractingUser && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      契約しているユーザー
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                      <Avatar
                        user={isContractingUser}
                        isSmallScreen={false} // 必要に応じて調整
                      />
                      <UserInfo
                        key={isContractingUser.user_id}
                        user={isContractingUser}
                        status="契約中"
                      />
                    </Box>
                  </Box>
                )}

                {/* 受注したユーザー */}
                {isReceivingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      受注したユーザー
                    </Typography>
                    {isReceivingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isSmallScreen={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="受注中"
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {/* 保留中のユーザー */}
                {isPendingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      保留中のユーザー
                    </Typography>
                    {isPendingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isSmallScreen={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="保留中"
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {/* 拒否したユーザー */}
                {isDenyingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      拒否したユーザー
                    </Typography>
                    {isDenyingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isSmallScreen={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="拒否済み"
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {/* 申請中のユーザー */}
                {isApplyingUsers.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                      申請中のユーザー
                    </Typography>
                    {isApplyingUsers.map((user) => (
                      <Box
                        key={user.user_id}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Avatar
                          user={user}
                          isSmallScreen={false} // 必要に応じて調整
                        />
                        <UserInfo
                          key={user.user_id}
                          user={user}
                          status="申請中"
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {/* すべてのユーザーが表示された場合の注意 */}
                {!isContractingUser &&
                  [
                    isReceivingUsers,
                    isPendingUsers,
                    isDenyingUsers,
                    isApplyingUsers,
                  ].every((list) => list.length === 0) && (
                    <Typography variant="body1">
                      ユーザーが存在しません。
                    </Typography>
                  )}
              </Box>
              <Box sx={{ mb: 4 }}>
                {/* Quotationの表示 */}
                {quotations.length > 0 && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      見積書
                    </Typography>
                    {quotations.map((quotation, idx) => (
                      <FileDownload
                        key={`quotation-${idx}`}
                        file={{
                          id: quotation.document.id,
                          itemType: quotation.itemType,
                        }}
                        category={"quotation"}
                      />
                    ))}
                  </>
                )}

                {/* Order Formの表示 */}
                {order_form && (
                  <FileDownload
                    file={{
                      id: order_form.document.id, // 修正
                      itemType: order_form.itemType,
                    }}
                    category={"order_form"}
                  />
                )}

                {/* Delivery Noteの表示 */}
                {delivery_note && (
                  <FileDownload
                    file={{
                      id: delivery_note.document.id, // 修正
                      itemType: delivery_note.itemType,
                    }}
                    category={"delivery_note"}
                  />
                )}

                {/* Invoiceの表示 */}
                {invoice && (
                  <FileDownload
                    file={{
                      id: invoice.document.id, // 修正
                      itemType: invoice.itemType,
                    }}
                    category={"invoice"}
                  />
                )}
              </Box>
            </Box>
          )}
          <ItemsRenderDetails wood={wood} log={log} blueprint={blueprint} />
        </Box>
      </Box>
    </Modal>
  );
};
