import { Box } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { PrivateRoute } from "../PrivateRoute";
import { TabBar, TabDefinition, TabProps } from "../../uis/Bars/Tab";
import Sidebar from "../../uis/Bars/Sidebar";
import { Header } from "../../uis/Bars/Header";

export interface TabDefinitionWithPath extends TabDefinition {
  label: string;
  path: string;
}

export const TabbedLayout = ({
  tabs,
  tabSx,
  tabsSx,
  tabIndicatorProps,
  spanStyle,
}: TabProps<TabDefinitionWithPath>) => {
  const navigate = useNavigate();
  const location = useLocation();

  // パスからタブのインデックスを取得
  const getTabIndexFromPath = (path: string) => {
    const index = tabs.findIndex((tab) => tab.path === path);
    return index >= 0 ? index : 0; // デフォルトは最初のタブ
  };

  // インデックスからパスを取得
  const getPathFromTabIndex = (index: number) => {
    return tabs[index]?.path || tabs[0].path; // デフォルトは最初のパス
  };

  const [selectedTab, setSelectedTab] = useState<number>(
    getTabIndexFromPath(location.pathname),
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    const newPath = getPathFromTabIndex(newValue);
    navigate(newPath);
  };

  return (
    <PrivateRoute>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh", // 全体の高さを画面全体に設定
        }}
      >
        <Sidebar isLoggedIn={true} />
        <Box
          sx={{
            width: "80%",
          }}
        >
          <Header title={"トーク"} />
          <TabBar
            tabs={tabs}
            selectedTab={selectedTab}
            tabIndicatorProps={tabIndicatorProps}
            tabsSx={tabsSx}
            tabSx={tabSx}
            handleTabChange={handleTabChange}
            spanStyle={spanStyle}
          />
          <Outlet />
        </Box>
      </Box>
    </PrivateRoute>
  );
};
