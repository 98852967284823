import { Amplify } from "aws-amplify";
import React, { createContext, useContext } from "react";
import config from "../config";
import {
  AuthUser,
  signIn as awsSignIn,
  signOut as awsSignOut,
  signUp as awsSignUp,
  fetchAuthSession,
  getCurrentUser,
  JWT,
  updatePassword,
  resetPassword,
  confirmResetPassword,
} from "aws-amplify/auth";
import {
  PasswordResetConfirmType,
  PasswordResetType,
  SignInType,
} from "../utils/schema";
import { toast } from "react-toastify";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  logKeys,
  messageRoomKeys,
  notificationKeys,
  orderMessageRoomKeys,
  sessionKeys,
  userKeys,
  woodKeys,
} from "../utils/query-key";
import { PAGE_SIZE, THIRTY_MINUTES } from "../utils/constant";

Amplify.configure({
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: config.cognito.userPoolId!,
      userPoolClientId: config.cognito.userPoolClientId!,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      // identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      // allowGuestAccess: true,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      // signUpVerificationMethod: 'code', // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: config.cognito.domain!,
          scopes: ["openid", "aws.cognito.signin.user.admin"],
          redirectSignIn: [config.cognito.redirectSignIn!],
          redirectSignOut: [config.cognito.redirectSignOut!],
          responseType: "code",
        },
      },
    },
  },
});

interface UseAuth {
  isLoading: boolean;
  sessionData: Readonly<AuthUser> | undefined;
  error: Error | null;
  signUp: (signUpParam: SignInType) => Promise<ReadonlyResult>;
  // confirmSignUp: (verificationCode: string) => Promise<ReadonlyResult>;
  signIn: (signInParam: SignInType) => Promise<ReadonlyResult>;
  signOut: () => Promise<ReadonlyResult>;
  forgotPassword: (data: PasswordResetType) => Promise<ReadonlyResult>;
  forgotPasswordConfirm: ({
    email,
    confirmationCode,
    newPassword,
  }: PasswordResetConfirmType) => Promise<ReadonlyResult>;
  getAccessToken: () => Promise<ReadonlyJWTResult>;
  // changeEmail: (email: string) => Promise<ReadonlyResult>;
  // changeEmailConfirm: (verificationCode: string) => Promise<ReadonlyResult>;
  changePassword: (
    oldPassword: string,
    newPassword: string,
  ) => Promise<ReadonlyResult>;
  // deleteAccount: () => Promise<ReadonlyResult>;
}

interface Result {
  success: boolean;
  message: string;
}

interface JWTResult {
  success: boolean;
  token: JWT | undefined;
}

type ReadonlyResult = Readonly<Result>;

type ReadonlyJWTResult = Readonly<JWTResult>;

const authContext = createContext({} as UseAuth);

export const ProvideAuth = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const queryClient = useQueryClient();

  // `getSession` 関数を作成して、カスタム属性を含むセッション情報を取得
  const getSession = async () => {
    try {
      const res = await getCurrentUser();
      return res;
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        throw new Error(error.message);
      } else {
        throw new Error("Error fetching session");
      }
    }
  };

  // useQueryを使用してセッションデータを取得
  const { isPending, data, error } = useQuery({
    queryKey: sessionKeys.session, // Query key
    queryFn: () => getSession(),
    retry: 1, // This is where the retry option should be placed
    staleTime: THIRTY_MINUTES,
    gcTime: THIRTY_MINUTES,
  });

  const signUp = async (signUpParam: SignInType) => {
    try {
      await awsSignUp({
        username: signUpParam.email,
        password: signUpParam.password,
      });
      toast.success("メールアドレスにメッセージを送信しました。");
      return { success: true, message: "" };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };

  const signIn = async (signInParam: SignInType) => {
    try {
      const res = await awsSignIn({
        username: signInParam.email,
        password: signInParam.password,
      });
      if (res.isSignedIn) {
        const allKeys = [
          sessionKeys.session,
          userKeys.user,
          userKeys.users,
          messageRoomKeys.messageRooms,
          orderMessageRoomKeys.orderMessageRooms,
          woodKeys.woods,
          logKeys.logs,
          notificationKeys.notifications({
            searchText: "",
            page: 1,
            pageSize: PAGE_SIZE,
          }),
        ] as const;

        // すべてのquery-keyのキャッシュを無効にする
        await Promise.all(
          allKeys.map((key) =>
            queryClient.invalidateQueries({ queryKey: key }),
          ),
        );
        return { success: true, message: "" };
      } else {
        return {
          success: false,
          message: "ユーザーが未確認です。アカウントを確認してください。",
        };
      }
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: "認証に失敗しました。",
      };
    }
  };

  // const setUserIdentity = (user: CognitoUser) => {
  //   user.getUserAttributes((err, attributes) => {
  //     if (attributes !== undefined) {
  //       setUserId(user.getUsername());
  //     }
  //   });
  // };

  // /**
  //  * アクセストークンの取得
  //  * Backendにリクエストを送る際に必要なので、その都度取得する
  //  */
  const getAccessToken = async () => {
    try {
      const { idToken } = (await fetchAuthSession()).tokens ?? {};
      return { success: true, token: idToken };
    } catch (error) {
      console.error(error);
      return { success: false, token: undefined }; // 修正: tokenをundefinedに変更
    }
  };

  /**
   * パスワードを忘れた際の確認コードの送信
   */
  const forgotPassword = async (data: PasswordResetType) => {
    try {
      await resetPassword({ username: data.email });
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "確認コードの送信に失敗しました。",
      };
    }
  };

  // /**
  //  * パスワードの再設定
  //  */
  const forgotPasswordConfirm = async ({
    email,
    confirmationCode,
    newPassword,
  }: PasswordResetConfirmType) => {
    try {
      await confirmResetPassword({
        username: email,
        newPassword,
        confirmationCode,
      });
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "パスワードの再設定に失敗しました。",
      };
    }
  };

  // const changeEmail = async (email: string) => {
  //   try {
  //     const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
  //     if (!user) throw new Error("ユーザーが見つかりませんでした。");
  //     await Auth.updateUserAttributes(user, {
  //       email: email,
  //     });
  //     return { success: true, message: "" };
  //   } catch (error) {
  //     return {
  //       success: false,
  //       message: "メールアドレス変更コードの送信に失敗しました。",
  //     };
  //   }
  // };

  // const changeEmailConfirm = async (verificationCode: string) => {
  //   try {
  //     await Auth.verifyCurrentUserAttributeSubmit("email", verificationCode);
  //     return { success: true, message: "" };
  //   } catch (error) {
  //     return {
  //       success: false,
  //       message: "メールアドレスの変更に失敗しました。",
  //     };
  //   }
  // };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      // const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
      // if (!user) {
      //   throw new Error("ユーザーが見つかりませんでした。");
      // }
      await updatePassword({ oldPassword, newPassword });
      return { success: true, message: "" };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: "パスワードの変更に失敗しました。",
      };
    }
  };

  // const deleteAccount = async () => {
  //   const token = await getAccessToken();
  //   const { backendUrl } = config;
  //   try {
  //     const response = await fetch(`${backendUrl}/api/users/lambda`, {
  //       method: "DELETE",
  //       headers: {
  //         Authorization: `${token.message}`,
  //       },
  //     });
  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }
  //     Auth.signOut().catch((err) => {
  //       console.error(err);
  //       return {
  //         success: false,
  //         message: "アカウント削除に失敗しました",
  //       };
  //     });
  //     setEmail("");
  //     setIsAuthenticated(false);
  //     return {
  //       success: true,
  //       message: "アカウント削除に成功しました",
  //     };
  //   } catch (error) {
  //     console.error("削除エラー:", error);
  //     return {
  //       success: false,
  //       message: "アカウント削除に失敗しました",
  //     };
  //   }
  // };

  const signOut = async () => {
    try {
      await awsSignOut();
      await queryClient.invalidateQueries({
        queryKey: sessionKeys.session, // Query key
      });
      toast.success("ログアウトしました");
      return { success: true, message: "" };
    } catch (error) {
      return {
        success: false,
        message: "ログアウトに失敗しました。",
      };
    }
  };

  return {
    isLoading: isPending,
    sessionData: data,
    error,
    signUp,
    // confirmSignUp,
    signIn,
    signOut,
    forgotPassword,
    forgotPasswordConfirm,
    getAccessToken,
    // changeEmail,
    // changeEmailConfirm,
    changePassword,
    // deleteAccount,
  };
};
