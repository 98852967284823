import { BlueprintDetail } from "../hooks/Blueprint/Blueprint/type";
import { LogDetail } from "../hooks/Log/Log/type";
import { WoodDetail } from "../hooks/Wood/Wood/type";
import { ColumnDefinitionForTable } from "../uis/Table";
import { PicSameDetailProperties } from "./types/item_type";

// export interface ColumnDefinitionForItemList<T> {
//   label: string;
//   field: keyof T | ((item: T) => React.ReactNode);
//   align?: "left" | "right" | "center" | "inherit" | "justify";
// }

interface ColumnType<T> {
  label: string;
  field: keyof T;
  type?: "text" | "number";
  step?: string;
  align?: "left" | "right" | "center" | "inherit" | "justify";
}

// 基本となるカラム情報を一箇所にまとめる
const baseColumns: ReadonlyArray<ColumnType<PicSameDetailProperties>> = [
  { label: "樹種", field: "species", type: "text", align: "left" },
  { label: "素材", field: "material", type: "text", align: "left" },
  { label: "産地", field: "origin", type: "text", align: "left" },
  { label: "等級", field: "grade", type: "text", align: "left" },
  { label: "長さ", field: "length", type: "number", align: "right" },
  {
    label: "単材積",
    field: "unit_volume",
    type: "number",
    step: "any",
    align: "right",
  },
  { label: "本数", field: "quantity", type: "number", align: "right" },
  {
    label: "材積",
    field: "total_volume",
    type: "number",
    step: "any",
    align: "right",
  },
];

const baseLogColumns: ReadonlyArray<ColumnType<LogDetail>> = [
  ...baseColumns.slice(0, 4),
  { label: "末口径", field: "top_diameter", type: "number", align: "right" },
  ...baseColumns.slice(4), // 残りのカラムを追加
];

// logColumns を生成
// export const logColumnsForItem: ReadonlyArray<
//   ColumnDefinitionForItemList<LogDetail>
// > = baseLogColumns.map(({ label, field, align }) => ({
//   label,
//   field,
//   align,
// }));

// columns を生成
export const logColumnsForTable: ReadonlyArray<
  ColumnDefinitionForTable<LogDetail>
> = baseLogColumns.map(({ label, field, type, step }) => ({
  label,
  field,
  type,
  step,
}));

const baseWoodColumns: ReadonlyArray<ColumnType<WoodDetail>> = [
  { label: "名称", field: "name", type: "text", align: "left" },
  ...baseColumns.slice(0, 4),
  { label: "幅", field: "width", type: "number" },
  { label: "高さ", field: "height", type: "number" },
  ...baseColumns.slice(4), // 残りのカラムを追加
];

// woodColumns を生成
// export const woodColumnsForItem: ReadonlyArray<
//   ColumnDefinitionForItemList<WoodDetail>
// > = baseWoodColumns.map(({ label, field, align }) => ({
//   label,
//   field,
//   align,
// }));

// columns を生成
export const woodColumnsForTable: ReadonlyArray<
  ColumnDefinitionForTable<WoodDetail>
> = baseWoodColumns.map(({ label, field, type, step }) => ({
  label,
  field,
  type,
  step,
}));

const baseBlueprintColumns: ReadonlyArray<ColumnType<BlueprintDetail>> = [
  { label: "名称", field: "name", type: "text", align: "left" },
  ...baseColumns.slice(0, 4),
  { label: "幅", field: "width", type: "number" },
  { label: "高さ", field: "height", type: "number" },
  ...baseColumns.slice(4), // 残りのカラムを追加
];

// blueprintColumns を生成
// export const blueprintColumnsForItem: ReadonlyArray<
//   ColumnDefinitionForItemList<BlueprintDetail>
// > = baseBlueprintColumns.map(({ label, field, align }) => ({
//   label,
//   field,
//   align,
// }));
// columns を生成
export const blueprintColumnsForTable: ReadonlyArray<
  ColumnDefinitionForTable<BlueprintDetail>
> = baseBlueprintColumns.map(({ label, field, type, step }) => ({
  label,
  field,
  type,
  step,
}));
