import React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  SxProps,
  Theme,
  Select,
  MenuItem,
  TextareaAutosize,
} from "@mui/material";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";

interface FormFieldProps<T extends FieldValues> {
  register: UseFormRegister<T>;
  name: Path<T>;
  isError: boolean;
  errorMessage?: string;
  type?: React.HTMLInputTypeAttribute;
  options?: ReadonlyArray<{ label: string; value: string | number }>;
  pattern?: { value: RegExp; message: string };
  placeholder: string;
  required?: boolean;
  defaultValue?: string | number;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  labelName?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  formType?: "select" | "input" | "textarea";
}

const FormField = <T extends FieldValues>({
  register,
  name,
  isError,
  errorMessage,
  type = "text",
  options = [],
  pattern,
  placeholder,
  required = true,
  defaultValue,
  onChange,
  labelName,
  disabled = false,
  sx,
  formType = "input",
}: FormFieldProps<T>) => {
  const requiredText = required
    ? `${placeholder}を${formType === "select" ? "選択" : "入力"}してください`
    : undefined;

  return (
    <FormControl
      error={isError}
      disabled={disabled}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "1rem",
        ...sx,
      }}
    >
      {labelName && (
        <FormLabel sx={{ width: "30%" }} htmlFor={labelName}>
          {labelName}
        </FormLabel>
      )}
      {formType === "textarea" ? (
        <TextareaAutosize
          id={labelName}
          placeholder={placeholder}
          {...register(name, {
            required: requiredText,
            pattern,
          })}
          defaultValue={defaultValue}
          onChange={onChange}
          minRows={3}
          style={{
            width: "70%",
            padding: "1rem",
            borderRadius: "8px",
            border: "1px solid #ccc",
            resize: "vertical",
          }}
          disabled={disabled}
        />
      ) : formType === "select" ? (
        <Select
          id={labelName}
          {...register(name, {
            required: requiredText,
          })}
          defaultValue={defaultValue}
          sx={{
            width: "70%",
            borderRadius: "0px",
            border: "none",
            paddingLeft: "1rem",
          }}
          disabled={disabled}
        >
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem value={option.value} key={index}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Input
          id={labelName}
          placeholder={placeholder}
          type={type}
          {...register(name, {
            required: requiredText,
            setValueAs: (value) => (value === "" ? undefined : value),
            pattern,
          })}
          defaultValue={defaultValue}
          onChange={onChange}
          sx={{
            width: "70%",
            borderRadius: "0px",
            border: "none",
            paddingLeft: "1rem",
          }}
          disabled={disabled}
        />
      )}
      {errorMessage && (
        <FormHelperText sx={{ color: "red" }}>{errorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormField;
