import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../../config";
import { handleResponse } from "../../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, logKeys } from "../../../utils/query-key";
import {
  PAGE_SIZE,
  SEARCH_BOX_ITEMS,
  THIRTY_SECONDS,
} from "../../../utils/constant";
import {
  OrderStatusWithId,
  SearchIdRequest,
  ReadonlyResponse,
  ReadonlyResponseWithId,
  OrderStatusWithSearchParams,
} from "../../../utils/types/general_type";
import { useAuth } from "../../useAuth";
import {
  Log,
  Logs,
  ReadonlyLog,
  ReadonlyResponseLogs,
  UpdateLog,
} from "./type";

export function useCreateLog() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "log",
          isTemporarilyStored: true,
          isOrdered: false,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}

export function useUpdateLog() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Log) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/log`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          title: data.title,
          file_path: data.file_path,
          is_temporarily_stored: data.is_temporarily_stored,
          is_ordered: data.is_ordered,
          log_details: data.log_details.map((detail) => ({
            species: detail.species,
            material: detail.material,
            origin: detail.origin,
            grade: detail.grade,
            top_diameter: detail.top_diameter,
            length: detail.length,
            unit_volume: detail.unit_volume,
            quantity: detail.quantity,
            total_volume: detail.total_volume,
          })),
        } satisfies UpdateLog),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("丸太リストを一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: logKeys.log(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "log",
          isTemporarilyStored: true,
          isOrdered: false,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}

export function useGetLog({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logKeys.log(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/log/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyLog>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useGetLogs({
  isTemporarilyStored,
  isOrdered,
  page,
  pageSize = PAGE_SIZE,
}: OrderStatusWithSearchParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.items_by_filter({
      itemName: "log",
      isTemporarilyStored,
      isOrdered,
      page,
      pageSize,
    }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
        page: page.toString(),
        pageSize: pageSize.toString(),
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/logs?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseLogs>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useSearchLogs({
  searchText,
  limit = SEARCH_BOX_ITEMS,
  id,
}: SearchIdRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: logKeys.search(searchText, id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("limit", `${limit}`);
      if (id) {
        params.append("exclude_id", id.toString()); // 現在のIDをリクエストに含める
      }
      const response = await fetch(
        `${config.backendUrl}/api/logs/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Logs>;
    },
    enabled: !!id,
    staleTime: THIRTY_SECONDS,
  });
}
