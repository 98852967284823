import { Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { BlackButton, WhiteButton } from "../../../../uis/Button/Button";
import { useForm, useWatch, useFieldArray } from "react-hook-form";
import {
  useGetBlueprintByReceiver,
  useSearchBlueprints,
  useUpdateBlueprintByReceiver,
} from "../../../../hooks/Blueprint/Blueprint/useBlueprintHook";
import { useUploadFileById } from "../../../../hooks/S3/useSignedURLHook";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../uis/Loading";
import { Error } from "../../../../uis/Error";
import { BlueprintTable } from "./blueprint_table";
import { ItemSearchBox } from "../../../../uis/Item/ItemSearchBox";
import { FileUpload } from "../../../../uis/File/FileUpload";
import { toast } from "react-toastify";
import {
  BlueprintBase,
  ReadonlyGetBlueprint,
} from "../../../../hooks/Blueprint/Blueprint/type";
import BlueprintImageForm from "../CreateBlueprintList/BlueprintImageForm";
import { extractFileName } from "../../../../utils/file";

export const CreateBlueprintDetailList = () => {
  const [searchText, setSearchText] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const [isPolling, setIsPolling] = useState<boolean>(false);
  const [data, setData] = useState<ReadonlyGetBlueprint | null>(null);
  const { mutateAsync: mutateUpdateBlueprint } = useUpdateBlueprintByReceiver();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { blueprint_id } = useParams<{ blueprint_id: string }>();
  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetBlueprintByReceiver(Number(blueprint_id));
  const { data: blueprints, error: searchError } = useSearchBlueprints({
    searchText,
    id: Number(blueprint_id),
  });
  const { mutateAsync: mutateUploadFileToS3, isPending: isMutatePending } =
    useUploadFileById();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<BlueprintBase>({
    defaultValues: {
      blueprint_images: [],
    },
    // resolver: zodResolver(schema),
  });
  const navigate = useNavigate();
  const { fields, append, replace, remove } = useFieldArray({
    control,
    name: "blueprint_details",
  });

  // 初回データ取得時にdataを設定
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData);
      if (fetchedData.blueprint_status?.status === "pending") {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  useEffect(() => {
    let intervalId: number;

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        try {
          const newData = await refetch();
          if (newData && newData.data) {
            setData(newData.data);
            if (newData.data.blueprint_status?.status === "success") {
              setIsPolling(false);
            } else if (newData.data.blueprint_status?.status === "failure") {
              toast.error(newData.data.blueprint_status.failure_reason);
              setIsPolling(false);
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false);
        }
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  // dataの変更時にフォームの値を更新
  useEffect(() => {
    if (data) {
      setValue("title", data.title);
      if (data.blueprint_details && data.blueprint_details.length > 0) {
        replace(data.blueprint_details);
      } else {
        replace([]);
      }
      if (data.blueprint_images && data.blueprint_images.length > 0) {
        setValue("blueprint_images", data.blueprint_images);
      } else {
        setValue("blueprint_images", []);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (formData: BlueprintBase) => {
    formData.id = Number(blueprint_id);
    try {
      await mutateUpdateBlueprint(formData);
      setIsPolling(true);
    } catch (error) {
      console.error(error);
    }
  };

  const watchedTitle = useWatch({ control, name: "title" });
  const watchedBlueprintDetails = useWatch({
    control,
    name: "blueprint_details",
  });
  const watchedBlueprintImages = useWatch({
    control,
    name: "blueprint_images",
  });

  const areAllBlueprintDetailsComplete =
    watchedBlueprintDetails &&
    watchedBlueprintDetails.length > 0 &&
    watchedBlueprintDetails.every((detail) =>
      Object.values(detail).every((value) => value !== "" && value !== 0),
    );

  const hasAtLeastOneImage =
    watchedBlueprintImages &&
    watchedBlueprintImages.length > 0 &&
    watchedBlueprintImages.some((img) => img.file || img.file_path);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = async () => {
    if (!selectedFile || !data) {
      toast.error("エラーが発生しました");
      return;
    }
    try {
      const uploadData = {
        id: data.id,
        file: selectedFile,
        path: "excel_csv/blueprint",
      } as const;
      await mutateUploadFileToS3(uploadData);
      setSelectedFile(null);
      setIsPolling(true); // ポーリングを開始
    } catch (error) {
      console.error(error);
    }
  };

  const isBlackButtonDisabled =
    !watchedTitle || !areAllBlueprintDetailsComplete || !hasAtLeastOneImage;

  if (isPending) {
    return <Loading />;
  }

  if (error || searchError) {
    return <Error message={error?.message || searchError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <ItemSearchBox
        searchText={searchText}
        setSearchText={setSearchText}
        searchClicked={searchClicked}
        setSearchClicked={setSearchClicked}
        items={blueprints}
        placeholder="一時保存したリスト一覧"
        navigateTo={(id) => navigate(`/order/create_blueprint_list/${id}`)}
      />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <FileUpload
          selectedFile={selectedFile}
          filePath={extractFileName(data?.file_path)}
          handleFileChange={handleFileChange}
          accept={["csv", "xlsx"]}
          message="CSV/Excelファイルを取りこむ"
        />
        <TextField
          placeholder="タイトルを入力"
          variant="outlined"
          fullWidth
          {...register("title")}
          error={!!errors.title}
          helperText={errors.title ? errors.title.message : null}
          sx={{
            border: "2px solid black",
            marginBottom: 3,
          }}
          disabled={true}
        />
        <BlueprintTable
          register={register}
          errors={errors}
          fields={fields}
          append={append}
          remove={remove}
        />
        <BlueprintImageForm
          control={control}
          errors={errors}
          blueprintId={Number(blueprint_id)}
          disabled={true}
        />
        <WhiteButton
          isSmallScreen
          text={"CSV/Excelファイルをアップロードする"}
          onClick={handleFileUpload}
          disabled={!selectedFile || isMutatePending}
        />
        <BlackButton
          isSmallScreen
          text={"更新する"}
          type="submit"
          disabled={isBlackButtonDisabled || isSubmitting}
        />
      </form>
    </Box>
  );
};
