import { useParams } from "react-router-dom";
import { useGetMessageRoom } from "../../hooks/Message/useMessageRoomHook";
import { useAuth } from "../../hooks/useAuth";
import { useCreateMessage } from "../../hooks/Message/useMessageHook";
import { MessageTab } from "../common/MessageTab";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { Box } from "@mui/material";

export const MessageRoom = () => {
  const { message_room_id } = useParams<{ message_room_id: string }>();
  const { data, error, isPending } = useGetMessageRoom(Number(message_room_id));

  const { sessionData } = useAuth();
  const sessionUserId = sessionData?.userId;

  const { mutateAsync, isPending: isMutatePending } = useCreateMessage(
    Number(message_room_id),
  );

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  return (
    <Box sx={{ width: "60%" }}>
      <MessageTab
        messages={data?.messages || []}
        user={data?.user}
        partner={data?.partner}
        mutateAsync={mutateAsync}
        isMutatePending={isMutatePending}
        sessionUserId={sessionUserId}
        type="message"
        isDeliveryNoteButtonShown={false}
        isInvoiceButtonShown={false}
        isPaymentShown={false}
      />
    </Box>
  );
};
