import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Stack,
  FormControl,
  Avatar,
  FormLabel,
  Input,
  Divider,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useUpdateUser } from "../../../hooks/useUserHook";
import { Loading } from "../../../uis/Loading";
import { Error } from "../../../uis/Error";
import FormField from "../../../uis/Forms/FormField";
import { useForm, Controller } from "react-hook-form"; // Controllerをインポート
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/S3/useSignedURLHook";
import { UpdateUserSchema, UpdateUserType } from "../../../utils/schema";
import { industries } from "../../../utils/industry";
import { UserContext } from "../../../contexts/UserContext";
import { Color } from "../../../utils/color";
import { zodResolver } from "@hookform/resolvers/zod";
import { useStripeAccountLinkCreate } from "../../../hooks/useStripeHook";
import { AdminPasswordModal } from "../../../uis/Modals/AdminPasswordModal";
import { getCookie } from "../../../utils/cookie";
import { toast } from "react-toastify";

export const UserForm: React.FC = () => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false); // モーダル表示の状態
  const [stripeAccountLinkCreate, setStripeAccountLinkCreate] =
    useState<boolean>(false); // モーダル表示の状態
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useContext(UserContext);
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const {
    data: imagePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const { mutateAsync } = useUpdateUser();
  const { mutateAsync: mutateStripeAccountLinkCreateAsync } =
    useStripeAccountLinkCreate();
  const {
    control, // Controller用
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    setFocus,
  } = useForm<UpdateUserType>({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues: {
      // 他のデフォルト値
      isStripeAccountCreated: false, // boolean型で設定
    },
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false); // 編集モードの状態
  console.log(errors, "errors");
  // エラーが発生したら最初のエラーフィールドにフォーカスを当てる
  useEffect(() => {
    const errorFields = Object.keys(errors) as Array<keyof UpdateUserType>;
    if (errorFields.length > 0) {
      setFocus(errorFields[0]);
    }
  }, [errors, setFocus]);

  useEffect(() => {
    if (user) {
      if (user.industry !== undefined) {
        setValue("industry", user.industry);
      }
      if (user.company_name !== undefined) {
        setValue("companyName", user.company_name);
      }
      if (user.phone_number !== undefined) {
        setValue("phoneNumber", user.phone_number);
      }
      if (user.company_image !== undefined) {
        setValue("companyImage", user.company_image);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (data: UpdateUserType) => {
    let res: string | undefined = undefined;
    try {
      if (file !== null) {
        const uploadData = {
          file,
          path: "img/user",
        } as const satisfies SignedURLRequestToPut;
        res = await mutateUploadFileToS3(uploadData);
      }
      const updateData = {
        ...data,
        companyImage: res,
      };
      console.log(updateData, "updateData");
      await mutateAsync(updateData);
      setIsEditMode(false); // 編集モードを解除
    } catch (error) {
      console.error(error);
    }
  };

  // 電話番号のハイフンを削除する関数
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phoneNumber = e.target.value;
    let hasHyphens = false;
    let hasNonNumeric = false;

    // ハイフンを削除
    if (phoneNumber.includes("-")) {
      phoneNumber = phoneNumber.replace(/-/g, "");
      hasHyphens = true;
    }

    // 数字以外の文字を削除
    const cleanedNumber = phoneNumber.replace(/[^0-9]/g, "");
    if (cleanedNumber !== phoneNumber) {
      phoneNumber = cleanedNumber;
      hasNonNumeric = true;
    }

    // クリーンな電話番号をセット
    setValue("phoneNumber", phoneNumber);
    // エラーメッセージを表示
    if (hasHyphens) {
      toast.error("電話番号にハイフンを含めないでください");
      return;
    }
    if (hasNonNumeric) {
      toast.error("電話番号には数字のみを含めてください");
      return;
    }
  };

  // 郵便番号にハイフンを追加する関数
  const handlePostalCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/[^0-9]/g, ""); // 数字以外を除去
    if (value.length > 8) {
      // 7文字を超えた場合、入力を制限
      value = value.slice(0, 3) + "-" + value.slice(3, 7);
      toast.error("郵便番号は7桁以内で入力してください。"); // エラーメッセージを表示
    } else if (value.length > 3) {
      // 4文字目以降でハイフンを挿入
      value = value.slice(0, 3) + "-" + value.slice(3);
    }
    setValue("postalCode", value); // フォームの値を更新
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      setFile(file);
      const fileUrl = URL.createObjectURL(file);
      setAvatarUrl(fileUrl);
    }
  };

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  /**
   * Stripe アカウントリンク作成ボタンを押した時のハンドラ
   * admin_session_id が無ければモーダルを表示し、
   * それ以外の場合は直接 Stripe アカウントリンク作成処理を行う
   */
  const handleStripeAccountLinkCreate = async () => {
    const adminSessionId = getCookie("admin_session_id");
    if (!adminSessionId) {
      setOpenModal(true);
      setStripeAccountLinkCreate(true);
      return;
    }
    try {
      const res = await mutateStripeAccountLinkCreateAsync();
      if (res.url) {
        window.open(res.url, "_blank");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSuccessClose = async () => {
    setOpenModal(false);
    if (stripeAccountLinkCreate) {
      try {
        // モーダルで検証成功した時点で Cookie がセットされていると想定
        const res = await mutateStripeAccountLinkCreateAsync();
        if (res.url) {
          window.open(res.url, "_blank");
        }
        setStripeAccountLinkCreate(true);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleErrorClose = () => {
    setOpenModal(false);
    setValue("isStripeAccountCreated", false);
  };

  if (getLoading) {
    return <Loading />;
  }

  if (getError) {
    return <Error message={getError?.message} />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      {/* トップラベルと編集ボタン */}
      <Box
        sx={{
          backgroundColor: "#f0f0f0",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
          width: "100%",
        }}
      >
        <Typography variant="h6" sx={{ color: Color.Main, fontWeight: "bold" }}>
          アカウント情報
        </Typography>
        <Button
          onClick={isEditMode ? handleSubmit(onSubmit) : toggleEditMode}
          sx={{
            backgroundColor: isEditMode ? "#B80003" : Color.Main,
            color: "white",
            padding: "6px 1.5rem",
            "&:hover": {
              backgroundColor: isEditMode ? "#B80003" : Color.Main,
            },
          }}
          disabled={isSubmitting}
        >
          {isEditMode ? "編集内容を更新する" : "編集する"}
        </Button>
      </Box>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: "100%",
        }}
      >
        <Stack
          alignItems="stretch"
          sx={{
            width: "100%",
            margin: "0 auto",
          }}
        >
          {/* プロフィール画像とアップロードフォーム */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <FormControl
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FormLabel>{"プロフィール画像"}</FormLabel>
              <input
                type="file"
                style={{ display: "none" }}
                accept="image/*"
                {...register("companyImage", {
                  onChange: handleImageChange,
                })}
                ref={(e) => {
                  register("companyImage").ref(e);
                  inputFileRef.current = e;
                }}
              />
              <Avatar
                alt="Company Icon"
                src={avatarUrl || imagePath}
                sx={{
                  width: isSmallScreen ? 90 : 100,
                  height: isSmallScreen ? 90 : 100,
                  marginBottom: isSmallScreen ? 1 : 2,
                  cursor: "pointer",
                }}
                onClick={handleAvatarClick}
              />
            </FormControl>

            {/* アップロードフォーム */}
            {isEditMode && (
              <Box
                sx={{
                  border: "2px dashed #ccc",
                  width: 100,
                  height: 100,
                  marginLeft: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleAvatarClick}
              >
                <Typography variant="h6" color="#ccc">
                  + upload
                </Typography>
              </Box>
            )}
          </Box>
          <Divider sx={{ backgroundColor: "#ccc" }} />

          {/* フォーム項目 */}
          <FormField<UpdateUserType>
            register={register}
            name="industry"
            isError={!!errors.industry}
            errorMessage={errors.industry?.message}
            formType="select"
            options={industries.map((industry, index) => ({
              label: industry,
              value: index,
            }))}
            placeholder="業種を選択してください"
            labelName="業種（必須）"
            defaultValue={user?.industry}
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField<UpdateUserType>
            placeholder="会社名（必須）"
            register={register}
            name="companyName"
            isError={!!errors.companyName}
            errorMessage={errors.companyName?.message}
            defaultValue={user?.company_name}
            labelName="会社名（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormControl
            disabled={true}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <FormLabel sx={{ width: "30%" }}>担当者メールアドレス</FormLabel>
            <Input
              placeholder="担当者メールアドレス(必須)"
              type="email"
              defaultValue={user?.email}
              sx={{
                width: "70%",
                borderRadius: "0px",
                border: "none",
                paddingLeft: "1rem",
              }}
            />
          </FormControl>
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField<UpdateUserType>
            placeholder="担当者電話番号 / ハイフンなし（必須）"
            register={register}
            name="phoneNumber"
            isError={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber?.message}
            pattern={{
              value: /^[0-9\-+]+$/,
              message: "有効な電話番号を入力してください(ハイフンなし)",
            }}
            defaultValue={user?.phone_number}
            labelName="担当者電話番号 / ハイフンなし（必須）"
            disabled={!isEditMode}
            onChange={handlePhoneNumberChange} // 電話番号のハイフンを
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField<UpdateUserType>
            placeholder="郵便番号 / ハイフンあり（必須）"
            register={register}
            name="postalCode"
            isError={!!errors.postalCode}
            errorMessage={errors.postalCode?.message}
            pattern={{
              value: /^\d{3}-\d{4}$/,
              message: "有効な郵便番号を入力してください（例: 123-4567）",
            }}
            defaultValue={user?.postal_code}
            labelName="郵便番号 / ハイフンあり（必須）"
            disabled={!isEditMode}
            onChange={handlePostalCodeChange}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField<UpdateUserType>
            placeholder="住所（必須）"
            register={register}
            name="address"
            isError={!!errors.address}
            errorMessage={errors.address?.message}
            defaultValue={user?.address}
            labelName="住所（必須）"
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          <FormField<UpdateUserType>
            placeholder="法人番号（任意）"
            register={register}
            name="corporateNumber"
            isError={!!errors.corporateNumber}
            errorMessage={errors.corporateNumber?.message}
            pattern={{
              value: /^\d{13}$/,
              message: "法人番号は13桁の数字で入力してください",
            }}
            defaultValue={user?.corporate_number}
            labelName="法人番号（任意）"
            required={false}
            disabled={!isEditMode}
          />
          <Divider sx={{ backgroundColor: "#ccc" }} />

          {/* Stripeアカウント情報の表示または作成フォームの表示 */}
          {user?.stripe_account_status === "created" ||
          user?.stripe_account_status === "completed" ? (
            // StripeアカウントIDが存在する場合の表示
            <Box
              sx={{
                padding: "1rem",
                backgroundColor: "#e8f5e9",
                borderRadius: "4px",
                marginTop: "1rem",
              }}
            >
              <Button onClick={handleStripeAccountLinkCreate}>
                Stripe Connect アカウント情報を提出する
              </Button>
            </Box>
          ) : (
            // StripeアカウントIDが存在しない場合の作成フォーム
            <FormControl
              component="fieldset"
              sx={{
                padding: "1rem",
              }}
              disabled={!isEditMode}
            >
              <FormLabel component="legend">
                Stripeアカウントを作成しますか？
              </FormLabel>
              <Controller
                name="isStripeAccountCreated"
                control={control}
                defaultValue={false} // デフォルト値を設定
                render={({ field }) => (
                  <RadioGroup
                    row
                    aria-label="isStripeAccountCreated"
                    {...field}
                    value={field.value ? "true" : "false"} // booleanを文字列に変換
                    onChange={(e) => {
                      const value = e.target.value === "true";
                      field.onChange(value);
                      const adminSessionId = getCookie("admin_session_id");
                      if (value && !adminSessionId) setOpenModal(true); // 「はい」を選択した場合にモーダルを開く
                    }}
                  >
                    <FormControlLabel
                      value="true" // 文字列に設定
                      control={<Radio />}
                      label="はい"
                    />
                    <FormControlLabel
                      value="false" // 文字列に設定
                      control={<Radio />}
                      label="いいえ"
                    />
                  </RadioGroup>
                )}
              />
              {errors.isStripeAccountCreated && (
                <Typography color="error" variant="body2">
                  {errors.isStripeAccountCreated.message}
                </Typography>
              )}
            </FormControl>
          )}
          <Divider sx={{ backgroundColor: "#ccc" }} />
        </Stack>
      </form>
      {openModal && (
        <AdminPasswordModal
          open={openModal}
          onSuccessClose={handleSuccessClose}
          onErrorClose={handleErrorClose}
        />
      )}
    </Box>
  );
};
