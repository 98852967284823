import { ItemList } from "../../uis/Item/ItemList";
import { ItemType } from "../../utils/types/item_type";
import { useCancelOrder } from "../../hooks/useOrderHook";

interface OrderHistoryProps {
  itemType: ItemType;
}
export const OrderHistory = ({ itemType }: OrderHistoryProps) => {
  const { mutateAsync } = useCancelOrder();

  const handleCancelOrder = async (itemId: number) => {
    try {
      await mutateAsync({ itemId, type: itemType });
    } catch (error) {
      console.error(error);
    }
  };

  return <ItemList itemName={itemType} handleCancelOrder={handleCancelOrder} />;
};
