import { Box, Typography } from "@mui/material";
import { useUpdateUser } from "../../../hooks/useUserHook";
import { Error } from "../../../uis/Error";
import { Loading } from "../../../uis/Loading";
import { Avatar as MuiAvatar } from "@mui/material";
import { useTheme, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeButton } from "../../../uis/Button/Button";
import { useCreateNotification } from "../../../hooks/useNotificationHook";
import { useContext, useRef, useState } from "react";
import {
  SignedURLRequestToPut,
  useGetFileFromS3,
  useUploadFileToS3,
} from "../../../hooks/S3/useSignedURLHook";
import { UserContext } from "../../../contexts/UserContext";

export const Home = () => {
  const user = useContext(UserContext);
  const { mutateAsync } = useCreateNotification();
  const { mutateAsync: mutateUploadFileToS3 } = useUploadFileToS3();
  const { mutateAsync: mutateUpdateUser } = useUpdateUser();
  const {
    data: imagePath,
    error: getError,
    isLoading: getLoading,
  } = useGetFileFromS3({
    path: user?.company_image,
  });
  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation(); // 現在のルートを取得
  const inputFileRef = useRef<HTMLInputElement | null>(null); // input elementの参照
  const handleNotificationClick = async () => {
    setDisabled(true);
    try {
      const res = await mutateAsync();
      navigate(`/home/notification/${res.notification_id}`);
    } catch (error) {
      console.error(error);
    } finally {
      setDisabled(false);
    }
  };

  const handleNotificationsClick = () => {
    navigate("/home/notifications");
  };

  const handleAvatarClick = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click(); // inputをクリック
    }
  };
  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files[0] && user) {
      const file = event.target.files[0];
      const uploadData = {
        file,
        path: "img/user",
      } as const satisfies SignedURLRequestToPut;
      try {
        const res = await mutateUploadFileToS3(uploadData); // S3にアップロード
        const updateData = {
          companyImage: res,
        } as const;
        await mutateUpdateUser(updateData);
      } catch (error) {
        console.error("Image upload failed", error);
      }
    }
  };

  // 現在のパスを取得
  const currentPath = location.pathname;

  // ボタンごとのリンク先を定義
  const homePath = "/home";
  const notificationDetailPathPrefix = "/home/notification/";
  const notificationsListPath = "/home/notifications";
  const messageRoomsPath = "/talk/message_rooms";

  // 各ボタンがアクティブかどうかを判定
  const isHomeActive = currentPath === homePath;
  const isNotificationDetailActive = currentPath.startsWith(
    notificationDetailPathPrefix,
  );
  const isNotificationsListActive = currentPath === notificationsListPath;
  const isMessageRoomsActive = currentPath === messageRoomsPath;

  if (getLoading) {
    return <Loading />;
  }

  // エラー時の処理
  if (getError) {
    return <Error message={getError?.message} />;
  }

  return (
    <Box
      sx={{
        width: "100%", // 残りの80%をメインコンテンツに割り当て
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* 1: 会社名の上に画像（円形）を追加 */}
        <input
          type="file"
          ref={inputFileRef}
          style={{ display: "none" }} // inputを非表示にする
          onChange={handleImageChange}
          accept="image/*"
          title="ファイルを選択" // ここでtitle属性を追加
        />
        {/* 会社アイコン（クリック可能） */}
        <MuiAvatar
          alt="Company Icon"
          src={imagePath} // ここに会社のアイコン画像のURLが入る
          sx={{
            width: isSmallScreen ? 90 : 100,
            height: isSmallScreen ? 90 : 100,
            marginBottom: isSmallScreen ? 1 : 2,
            cursor: "pointer", // クリックできるようにする
          }}
          onClick={handleAvatarClick} // クリックでファイル選択を開く
        />
        {/* 会社名 */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isSmallScreen ? "h4" : "h1"}
            component="h2"
            sx={{ fontSize: isSmallScreen ? "2rem" : "2.5rem" }}
          >
            {user?.company_name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "80%" }}>
        <ChangeButton
          isSmallScreen
          text={"ホーム"}
          onClick={() => navigate("/home")}
          isActive={isHomeActive} // 色を動的に変更
        />
        <ChangeButton
          isSmallScreen
          text={"会社お知らせを作成"}
          onClick={handleNotificationClick}
          disabled={disabled}
          isActive={isNotificationDetailActive} // 色を動的に変更
        />
        <ChangeButton
          isSmallScreen
          text={"作成済みお知らせ一覧"}
          onClick={handleNotificationsClick}
          isActive={isNotificationsListActive} // 色を動的に変更
        />
        {/* 3: メッセージ一覧ボタン */}
        <ChangeButton
          isSmallScreen
          text={"メッセージ一覧"}
          onClick={() => navigate("/talk/message_rooms")}
          isActive={isMessageRoomsActive} // 色を動的に変更
        />
      </Box>
    </Box>
  );
};
