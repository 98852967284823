import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type TermsDataType = {
  title: string;
  content?: string;
  bulletPoints?: ReadonlyArray<{
    bulletPoint: string;
    subBulletPoints?: ReadonlyArray<string>;
  }>;
};

const termsData = [
  {
    title: "第1条 (本サービスの会員)",
    content:
      "本規約において「会員」とは、本規約を承認した上で、当社所定の手続に従い会員登録を申請し、当社がこれを承認した者をいいます。",
  },
  {
    title: "第2条 (本サービスの利用)",
    content:
      "会員は、本利用規約と利用ガイド、プライバシーポリシーに従い、本サービスを利用するものとします。",
  },
  {
    title: "第3条 (会員登録)",
    bulletPoints: [
      {
        bulletPoint:
          "会員登録を希望する者(以下、「会員希望者」といいます)は、当社指定の方法にて会員登録の申し込みを行うものとします。会員希望者は、申し込みに当たり真実、正確、且つ最新の情報を当社に提供するものとします。",
      },
      {
        bulletPoint:
          "会員登録は前項の申込に対する当社の承認をもって完了とします。",
      },
      {
        bulletPoint:
          "前項の承認をもって本規約が会員と当社の間で成立したものとします。",
      },
      {
        bulletPoint:
          "当社は会員希望者が、以下のいずれかの事由に該当した場合は申請を承認しない場合があります。",
        subBulletPoints: [
          "個人での利用等本サービスの目的外の登録である場合",
          "本規約に違反する恐れがあると当社が判断した場合",
          "本サービスの運営を妨げる、もしくはその恐れがあると当社が判断した場合",
          "既に会員となっている場合",
          "過去に会員登録を取り消し、もしくはサービス停止を受けている場合",
          "申込内容に虚偽、誤記入、記入漏れ、またはその疑いがある事項が含まれている場合",
          "反社会的勢力に属するまたは関与している場合",
          "その他、当社が登録を不適当と判断した場合",
        ],
      },
    ],
  },
  {
    title: "第4条 (登録内容の変更)",
    bulletPoints: [
      {
        bulletPoint:
          "会員は申込時に申請した住所、屋号、連絡先等の事項に変更があった場合には、当社が別途定める方法にて申請または変更するものとします。",
      },
      {
        bulletPoint:
          "当社は前項の申請を受理した場合、変更手続きを行うものとします。",
      },
      {
        bulletPoint:
          "当社は、会員が適宜、変更申請または変更登録を行わなかったことによる何らかの損害が会員に生じたとしても一切責任を負わないものとします。",
      },
    ],
  },
  {
    title: "第5条 (会員メールアドレス・パスワードの管理)",
    bulletPoints: [
      {
        bulletPoint:
          "会員は、本サービス上で設定した会員メールアドレス、パスワード等の管理を行う義務を負うものとします。",
      },
      {
        bulletPoint:
          "会員は、会員資格・会員ID・パスワードを第三者に利用させ、または譲渡、売買、質入、貸与等、その他形態を問わず処分することはできないものとします。",
      },
      {
        bulletPoint:
          "会員ID及びパスワードを利用して行われたあらゆる行為の責任は、第三者が会員本人の同意なく行った場合や不正に使用した場合であっても、会員IDを保有している会員が責任を負うものとし、当社は、一切の責任を負わないものとします。",
      },
      {
        bulletPoint:
          "会員が利用した機器・回線・ソフトウェア等により生じた責任及び損害、並びに、 会員がサービスを利用する上での過誤による責任及び損害は会員が負うものとし、当社は、一切責任を負わないものとします。",
      },
      {
        bulletPoint:
          "会員は、会員ID及びパスワードの情報が第三者に漏洩した場合、またはその疑いがある場合には速やかに当社まで連絡するとともに、当社の指示がある場合にはそれに従うものとします。当社は、当該会員IDとパスワードによるサービス利用の停止または終了を行うことはできるものとし、その情報漏洩によって生じたあらゆる損害について一切の責任を負わないものとします。",
      },
      {
        bulletPoint:
          "会員は、定期的にパスワードを変更する義務を負うものとし、その義務を怠ったことにより損害が生じた場合、当社は一切の責任を負わないものとします。",
      },
    ],
  },
  {
    title: "第6条 (本サービスの利用停止及び会員資格の停止)",
    bulletPoints: [
      {
        bulletPoint:
          "会員に以下の事項に該当する事由が生じた場合、当社は当該会員に事前の通知なしに会員資格の一時停止、または会員資格の取消及び成立の有無を問わず売買契約の一部、または全部を解除することができるものとします。",
        subBulletPoints: [
          "第3条4項のいずれかの事由に該当することが判明した場合",
          "第19条のいずれかの行為に該当した場合",
          "本サービスの利用規約および利用ガイドラインのいずれか、または双方の条項に違反した場合",
          "当社への支払いを滞納した場合",
          "差押え、仮差押え、行政処分、破産、民事再生等その他強制執行が生じた場合",
          "当社与信ガイドラインに抵触し信用力に影響がある場合",
          "その他、当社が不適切と判断した場合",
        ],
      },
      {
        bulletPoint:
          "前項に定める、いずれかまたはすべてに該当した場合、会員は当社に対して負っているすべての債務の支払いを行うものとします。",
      },
      {
        bulletPoint:
          "当社は、本条に基づき当社が行った行為により会員に生じた損害の責任は一切負いません。",
      },
    ],
  },
  {
    title: "第7条 (退会)",
    content:
      "会員は退会を希望する場合、当社に対し退会希望の連絡を行うものとします。当社が、当該届出を受理し、当該会員の会員登録取消が完了した後に退会が認められるものとします。当該登録取消の完了をもって当該会員はすべての会員資格を喪失するものとします。退会は、会員が当社に対して負っているすべての債務の支払いが完了するまで認められないものとします。",
  },
  {
    title: "第8条 (委託)",
    content:
      "当社は本サービスの提供に関する業務の全部もしくは一部を会員の承諾なしに、第三者に委託することができるものとします。この場合、当社は本サービスを会員に提供する目的で、会員登録された情報を委託先に提供できるものとします。",
  },
  {
    title: "第9条 (商品情報のメール配信)",
    bulletPoints: [
      {
        bulletPoint:
          "会員登録の申し込みを行った場合、当社から定期的に配信する商品情報のメール受信を承諾したものとします。受信したメールから利用者が配信停止を希望した場合、当社は配信を停止するものとします。",
      },
      {
        bulletPoint:
          "当社からのメール配信において、会員のメール受信のエラーが連続して発生する場合、当社は会員に通知することなくメール配信を停止することができるものとする。",
      },
    ],
  },
  {
    title: "第10条 (会員情報の取り扱い)",
    bulletPoints: [
      {
        bulletPoint:
          "本サービスの運営に当たり取得した会員の情報は、当社プライバシーポリシーに基づき取り扱うものとします。",
      },
      {
        bulletPoint:
          "当社は、本サービスの改良、サービスの維持管理等を目的とする統計調査のため、会員の本サービスの利用状況、画面・項目の利用頻度等の統計数値を利用し、あるいは統計調査に必要な限度でこれらの情報を解析し、二次加工して活用するものとし、会員はかかる統計調査、二次加工活用を行うことに同意するものとします。",
      },
      {
        bulletPoint:
          "当社は、会員が入力したデータに関し、善良な管理者による注意をもって機密保持とその管理に努めるものとします。",
      },
      {
        bulletPoint:
          "会員は、当社が、裁判所、その他の法的な権限のある官公庁の命令等により本サービスに関する情報の開示ないし提出を求められた場合は、かかる命令等に従って情報の開示ないし提出をすることがあることを承諾し、かかる開示ないし提出に対して異議を述べないものとします。",
      },
    ],
  },
  {
    title: "第11条 (守秘義務)",
    content:
      "会員は、会員期間中または退会後にかかわらず、本サービス及び本サービスに関連して知り得た情報、納品書および納品金額がわかる情報、販売金額の情報、その他当社の機密に属すべき一切の事項を第三者に漏洩、開示、提供してはならないものとします。但し、あらかじめ当社の書面による承諾を受けた場合及び一般に開示されている情報はこの限りではありません。",
  },
  {
    title: "第12条 (連絡の方法)",
    bulletPoints: [
      {
        bulletPoint:
          "当社が会員のメールアドレスに対し、メールを送信した場合、当該メールを会員が受信した時点または当社による送信後30分が経過した時点のいずれか早い時点で通知したものとします。",
      },
      {
        bulletPoint:
          "当社または提携先が会員の住所宛て(以下、「届出住所」といいます)に郵送で送付した場合、当該郵送物を会員が受領した場合、または当社の郵送後2日が経過した時点のいずれか早い時点で通知したものとします。",
      },
      {
        bulletPoint:
          "当社が本サービス内において規約等の変更または本サービス運営等の連絡事項をサイトに掲載した場合、当該の掲載をもって会員に通知したものとします。",
      },
      {
        bulletPoint:
          "会員が当社宛てに連絡した場合、連絡方法の如何にかかわらず、当社から届出メールアドレスに受領した旨の連絡をした時点で通知したものとします。",
      },
    ],
  },
  {
    title: "第13条 (商品の注文)",
    bulletPoints: [
      {
        bulletPoint:
          "会員は、利用ガイドに定める方法に従い、本サービスに掲載される商品等(以下、本商品といいます)を注文することができるものとします。",
      },
      {
        bulletPoint:
          "会員が本サービスを通じて本商品の注文をした場合、注文先の承諾に対し、会員が発注をした段階で当該商品購入の契約が成立したものとします。",
      },
      {
        bulletPoint:
          "ネットワークまたはソフトウェア等の不具合等により、会員からの注文を確認できない場合には、有効な注文はなかったものとします。また、この場合に会員が商品を購入することができないこと、関連して生じる損害について当社は一切の責任を負わないものとします。",
      },
      {
        bulletPoint:
          "当社は本商品の注文受領後も、欠品、出荷不能または当社が不適切と判断する場合、注文のキャンセル(契約成立後は契約解除)をさせていただくことがあります。この場合、当社からの通知の有無に係わらず、会員はこれを承諾するものとします。",
      },
    ],
  },
  {
    title: "第14条 (本商品等の引き渡し)",
    bulletPoints: [
      {
        bulletPoint:
          "本商品等の引き渡しは、会員が指定したお届け先に配送(お届け先建物の内部または入口付近に本商品を差置くことを含む)したことをもって、納品完了したものとします。",
      },
      {
        bulletPoint:
          "会員の登録した住所、名称、屋号または建物の外観や表札等が確認できない場合には本商品をお届けできない場合があります。",
      },
    ],
  },
  {
    title: "第15条 (商品受領拒否等)",
    content:
      "会員が本商品等の受領に応じない場合、その会員の都合で本商品のお届け先への配送ができない場合(不在の場合を含む)、当社は、会員にその旨を通知することで本商品の購入契約を解除できます。これにより生じる当社の損害は会員が責任を負うものとし、当社は会員にこれを請求できるものとします。",
  },
  {
    title: "第16条 (返品・交換・キャンセルの取り扱い)",
    content:
      "返品・交換・キャンセルの取り扱いは、本規約に特別に記載のない場合、利用ガイドに記載の事項を適用するものとします。",
  },
  {
    title: "第17条 (掲載情報の修正・購入価格の調整)",
    bulletPoints: [
      {
        bulletPoint:
          "当社は本商品の掲載情報に修正の必要が生じた場合、掲載情報の修正または削除(以下、「修正等」といいます)を行うものとします。修正等の内容が会員に不利益となると判断される場合、当社は会員に本サービス内で修正等の内容を掲載することをもって、会員への通知とします。",
      },
      {
        bulletPoint:
          "当社では、最新の正確な商品情報を表示するよう最善の努力をします。ただし、本商品の価格が正しく掲載されていなかった場合(掲載ミスによる場合等)、当社は次のように価格調整をすることができるものとします。",
        subBulletPoints: [
          "本商品の本来の販売価格が、商品購入の申込み時に表示されていた商品価格より低い場合、当該価格より低い価格で請求させていただきます。",
          "本商品の本来の販売価格が、商品購入の申込み時に表示されていた商品価格より高い場合、当社の裁量により、当社は、1会員に当該価格より高い価格で購入いただくか、もしくは会員に申込をキャンセルいただくかの確認に関する通知をするか、または2購入契約を解除できるものとします。",
        ],
      },
    ],
  },
  {
    title: "第18条 (ご請求・お支払い)",
    bulletPoints: [
      {
        bulletPoint:
          "本商品等の購入代金、配送料、消費税等のご請求・お支払いについては、利用ガイドに記載の事項を適用するものとします。",
      },
      {
        bulletPoint:
          "会員が支払い期日までに代金の支払いを怠った場合、理由の如何を問わず支払い済みに至るまで年利14.6%の遅延賠償金を別途お支払いいただく場合があります。",
      },
    ],
  },
  {
    title: "第19条 (禁止行為)",
    content:
      "会員は、本サービスの利用にあたり以下の事項に該当する行為を行ってはならないものとします。",
    bulletPoints: [
      {
        bulletPoint: "会員登録の際、虚偽の内容を送信・登録する行為",
      },
      {
        bulletPoint: "本サービスに提供する情報を改ざんする行為",
      },
      {
        bulletPoint:
          "当社、または第三者の著作権その他の知的財産権を侵害し、または侵害する恐れのある行為",
      },
      {
        bulletPoint: "本サービスの運営を妨げる行為、またはその恐れのある行為",
      },
      {
        bulletPoint: "会員登録の際、虚偽の内容を送信・登録する行為",
      },
      {
        bulletPoint: "有害なコンピュータプログラム等を送信しまたは書き込む行為",
      },
      {
        bulletPoint: "当社の定めるプライバシーポリシーに反する行為",
      },
      {
        bulletPoint: "当社、または第三者の誹謗・中傷・名誉を傷つける行為",
      },
      {
        bulletPoint: "その他、当社が不適当・不適切と判断する行為",
      },
    ],
  },
  {
    title: "第20条 (情報の取得・管理)",
    bulletPoints: [
      {
        bulletPoint:
          "当社は利用者のアクセス履歴及び利用状況の調査のため、または利用者へのサービス向上のために利用者のアクセス履歴に関する以下の情報を収集します。",
        subBulletPoints: [
          "利用者が本サービスのサーバーにアクセスする際のIPアドレスまたは携帯端末の機体識別番号に関する情報",
          "当社が、クッキーの技術(Webブラウザを通じてユーザーのコンピューターに一時的にデータを書き込んで利用者が最後にサイトを訪れた日時、そのサイトの訪問回数等を記録保存する技術)を通じて取得する利用者のアクセス情報",
        ],
      },
      {
        bulletPoint:
          "利用者は、利用者がWebブラウザでクッキーを拒否するための設定を行った場合、本サービスの利用が制限される場合があることを予め承諾するものとします。",
      },
    ],
  },
  {
    title: "第21条 (サービス保守)",
    content:
      "当社は、本サービスの稼動状態を良好に保つため、以下のいずれかの場合には、利用者に事前に通知を行うことなく、一時的に本サービスの提供の全部または一部を停止または中止することができるものとします。",
    bulletPoints: [
      {
        bulletPoint:
          "本サービス提供のためのコンピューターシステム(以下、「システム」といいます)の定期保守及び緊急保守の場合",
      },
      {
        bulletPoint:
          "火災、地震、洪水、落雷、大雪等の天変地異により、システムの運用が困難になった場合",
      },
      {
        bulletPoint:
          "戦争、内乱、テロ、暴動、騒乱等の社会不安により、システムの運用が困難になった場合",
      },
      {
        bulletPoint:
          "システムの不良及び第三者からの不正アクセス、コンピューターウィルスの感染等により、システムの運用が困難になった場合",
      },
      {
        bulletPoint: "行政機関・司法機関から相当な根拠に基づき要請された場合",
      },
      {
        bulletPoint:
          "その他やむを得ずシステムの停止または中止が必要と当社が判断した場合",
      },
    ],
  },
  {
    title: "第22条 (本サービスの変更および廃止)",
    content:
      "当社は、サービス提供のための装置の保守点検・設備更新・運営上の必要、及び天災・災害・装置の故障等の事由により本サービスの提供を中断することがあります。これによって会員に損害が生じても、当社はそれについて一切責任を負わないものとします。",
  },
  {
    title: "第23条 (知的財産権)",
    content:
      "本サービスを通じて提供されるコンテンツの知的財産権は、定めのない限りは全て当社に帰属するものであり、当社の事前の承諾なしに利用することはできないものとします。また、目的の如何を問わず、当社のコンテンツの無断複製、無断転載その他の無断二次利用行為等の国内及び国外の著作権法及びその他の法令により禁止される行為が発見された場合には、当社は直ちに法的措置をとるものとします。",
  },
  {
    title: "第24条 (免責事項)",
    bulletPoints: [
      {
        bulletPoint:
          "当社は、本サービス及び本サービスを通じて販売される商品等につき、その品質、その他の欠陥、配送遅延、またはこれらが原因となり生じた損害、損失、不利益等については、いかなる保証・負担も負わないものとします。",
      },
      {
        bulletPoint:
          "本サービスの内容、提供の状態、接続、利用環境、その他本サービスに関連する事項に起因もしくは関連して生じた一切の損害に対して、当社は一切の責任を負わないものとします。",
      },
      {
        bulletPoint:
          "当社は、原則として、利用者がサービスに関連して発信または掲載したコンテンツの内容、品質、正確性、信憑性、適法性(以下、他人の権利の非侵害を含みます)、最新性、有用性などの確認はしません。",
      },
      {
        bulletPoint:
          "当社は、本サービスに関連したコンテンツ(本取引に関する事項、利用者が発信または掲載した事項など)の内容、品質、正確性、信憑性、適法性、最新性、有用性などについては、一切保証せず、一切責任を負わないものとします。",
      },
      {
        bulletPoint:
          "当社は、利用者及び第三者が本サービスを利用することにより生じる損害について、一切責任を負いません。利用者が本サービスに関連して発信または掲載したコンテンツに関する一切の責任は、当該利用者が負うものとします。",
      },
      {
        bulletPoint:
          "当社は、配送先不明等によるトラブルに関しては、会員が登録している連絡先に連絡すること及び商品購入の際に指定された配達先に商品等を配送等することにより、商品等の引渡債務を履行し、当該債務から免責されるものとします。",
      },
      {
        bulletPoint:
          "本サービスから他のウェブサイトもしくはリソースへのリンク、または第三者のウェブサイトもしくはリソースから本サービスへのリンクを提供している場合、当社は、当該リンク先の内容、利用及びその結果(正確性、信憑性、適法性、最新性、有用性など)については、いかなる責任も負わないものとします。なお、当社は、リンク先のウェブサイトまたはリソースの内容が、違法または本サービスの管理・運営上不適切であると合理的に判断した場合には、会員に何らの通知を要することなく、当該リンク先を削除することができるものとします。",
      },
      {
        bulletPoint:
          "本サービス内に広告または宣伝を行っている広告主との取引がある場合、利用者は、自らの判断と責任により、当該広告主との間で取引を行うものであり、これに関して当社は一切責任を負わないものとします。当社は、本サービス中に掲載されている広告または宣伝を経由して行われる取引に起因して、会員にいずれかの損害が発生した場合については一切責任を負わないものとします。",
      },
      {
        bulletPoint:
          "当社は、以下の場合に、一時的に本サービスが停止、中止または変更されたとしても、会員が直接的または間接的に被った一切の損害、損失、不利益等について、いかなる責任も負わないものとします。",
        subBulletPoints: [
          "火災、地震、洪水、落雷、大雪等の天変地異が生じた場合",
          "戦争、内乱、テロ、暴動、騒乱等の社会不安が生じた場合",
          "当社が契約している電話会社、運送会社またはプロバイダから適切なサービスを受けられなかった場合",
          "当社が技術的に対応不可能な事由が生じた場合",
        ],
      },
    ],
  },
  {
    title: "第25条 (反社会的勢力の排除等)",
    bulletPoints: [
      {
        bulletPoint:
          "当社及び会員は、次の各号に掲げる事項を保証するものとします。",
        subBulletPoints: [
          "自らが、暴力団、暴力団関係企業、組織的に犯罪を行う団体、暴力主義的破壊活動を行う団体またはこれらに準ずるもの(以下「反社会的勢力」といいます)に該当しないこと",
          "自らの役職員及び関係者が、反社会的勢力に該当しないこと",
          "自らの役職員及び関係者が、本契約の履行にあたり、著しく粗野なまたは乱暴な言動を用いて不当な要求を行わないこと",
          "自らまたは自らの役職員及び関係者が、反社会的勢力に対する資金提供その他の行為を行うことを通じて、意図して反社会的勢力の維持または運営に協力していないこと",
          "その知る限りにおいて、自らの特別利害関係者(役員及びその配偶者、並びにこれらの者が発行済株式総数の過半数を所有する会社)が前各号に反しないこと",
        ],
      },
      {
        bulletPoint:
          "当社及び会員は、一方当事者が前項に違反した場合、他方当事者は本契約を直ちに解除できるものとし、本条に基づく解除により相手方に損害が発生した場合であっても、相手方に対し当該損害を賠償する責任を負わないものとします。",
      },
      {
        bulletPoint:
          "当社及び会員は、相手方が第1項に違反したことに起因して損害を被った場合、相手方に対し、当該被った損害の全てについて賠償を請求することができるものとします。",
      },
    ],
  },
  {
    title: "第26条 (協議解決)",
    content:
      "本規約または利用ガイドに定めのない事項に関しては、会員と当社で協議の上決定するものとします。",
  },
  {
    title: "第27条 (準拠法及び合意管轄裁判所)",
    content:
      "本規約の準拠法は日本法とし、本規約および個別規約に係わる一切の紛争は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。付則:本規約は2025年1月1日から全ての利用者に適用されるものとします。",
  },
] as const satisfies ReadonlyArray<TermsDataType>;

const TermsOfService: React.FC = () => {
  return (
    <Container maxWidth="md">
      <Box my={4}>
        <Typography variant="h4" align="center" gutterBottom>
          利用規約
        </Typography>
        <Typography variant="body1" paragraph>
          本規約は、Eco Forest
          Friendlyが提供する「EFF木材流通システム」の利用に関して適用されます。
        </Typography>
        {termsData.map((term, index) => (
          <Accordion key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h6">{term.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {"content" in term && <Typography>{term.content}</Typography>}
              {"bulletPoints" in term && (
                <Box component="ul">
                  {term.bulletPoints.map((point, i) => (
                    <Box component="li" key={i}>
                      <Typography>{point.bulletPoint}</Typography>
                      {"subBulletPoints" in point &&
                        Array.isArray(point.subBulletPoints) && (
                          <Box component="ul">
                            {point.subBulletPoints.map((sub, j) => (
                              <Box component="li" key={j}>
                                <Typography>{sub}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                    </Box>
                  ))}
                </Box>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Container>
  );
};

export default TermsOfService;
