import { useGetBlueprint } from "../../../hooks/Blueprint/Blueprint/useBlueprintHook";
import { useSearchBlueprintUsers } from "../../../hooks/useUserHook";
import { useCreateBlueprintOrder } from "../../../hooks/Blueprint/useBlueprintOrderHook";
import { MailingComponent } from "../../common/MailingComponent";

export const BlueprintMailing = () => {
  return (
    <MailingComponent
      idParamName="blueprint_id"
      useGetData={useGetBlueprint}
      useSearchUsers={useSearchBlueprintUsers}
      useCreateOrder={useCreateBlueprintOrder}
      orderDataKey="blueprintId"
      navigateTo="/order"
    />
  );
};
