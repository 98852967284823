import { GetBlueprint } from "../hooks/Blueprint/Blueprint/type";
import { Log } from "../hooks/Log/Log/type";
import { Users } from "../hooks/useUserHook";
import { Wood } from "../hooks/Wood/Wood/type";

type OrderSource = Wood | Log | GetBlueprint;

interface Props {
  users: Users;
  wood?: Wood;
  log?: Log;
  blueprint?: GetBlueprint;
}

// リファクタリング後の関数
export function getUserLists({ users, wood, log, blueprint }: Props) {
  // 使用中のオーダーソースを取得
  const orderSource: OrderSource | undefined = wood || log || blueprint;

  // オーダーの取得
  const getOrders = (source: OrderSource) => {
    if ("wood_orders" in source) return source.wood_orders;
    if ("log_orders" in source) return source.log_orders;
    if ("blueprint_orders" in source) return source.blueprint_orders;
    return [];
  };

  const orders = orderSource ? getOrders(orderSource) : [];

  // 各ユーザーリストの生成
  const isContractingUser = users.find((user) =>
    orders.some(
      (order) => order.is_contract_signed && order.receiver_id === user.user_id,
    ),
  );

  const isReceivingUsers = users.filter((user) =>
    orders.some(
      (order) =>
        !order.is_contract_signed &&
        order.is_received &&
        order.receiver_id === user.user_id,
    ),
  );

  const isPendingUsers = users.filter((user) =>
    orders.some(
      (order) => order.is_pending && order.receiver_id === user.user_id,
    ),
  );

  const isDenyingUsers = users.filter((user) =>
    orders.some(
      (order) => order.is_denied && order.receiver_id === user.user_id,
    ),
  );

  const isApplyingUsers = users.filter((user) =>
    orders.some(
      (order) =>
        !order.is_denied &&
        !order.is_pending &&
        !order.is_received &&
        order.receiver_id === user.user_id,
    ),
  );

  return {
    isContractingUser,
    isReceivingUsers,
    isPendingUsers,
    isDenyingUsers,
    isApplyingUsers,
  };
}
