import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { LoginLayout } from "./layout/LoginLayout";
import { SignIn } from "../pages/Auth/SignIn";
import { SignUp } from "../pages/Auth/SignUp";
import { HomeLayout } from "./layout/HomeLayout";
import { NotificationComponent } from "../pages/Home/Notification";
import { MessageRooms } from "../pages/Home/MessageRooms/MessageRooms";
import { Order } from "../pages/Home/Order";
import { CreateWoodList } from "../pages/Home/Wood/CreateWoodList";
import { AdminLayout } from "./layout/AdminLayout";
import { UserComponent } from "../pages/Home/User";
import { Profile } from "../pages/Home/Profile/index";
import { ProfileLayout } from "./layout/ProfileLayout";
import { CreateLogList } from "../pages/Home/Log/CreateLogList";
import { RequestList } from "../pages/Home/RequestList";
import { LogMailing } from "../pages/Home/Log/LogMailing";
import { ManagementLayout } from "./layout/ManagementLayout";
import { Stock } from "../pages/Home/Stock";
import { Document } from "../pages/Home/Management/Document";
import { Money } from "../pages/Home/Management/Money";
import { InputWoodStock } from "../pages/Home/InputWoodStock";
import { OrderMessageRooms } from "../pages/Home/MessageRooms/OrderMessageRooms";
import { MessageRoom } from "../pages/Home/MessageRoom";
import { OrderMessageRoom } from "../pages/Home/OrderMessageRoom";
import { WoodTemporallyStored } from "../pages/Home/Wood/WoodTemporallyStored";
import { WoodOrderHistory } from "../pages/Home/Wood/WoodOrderHistory";
import { PrecutLayout } from "./layout/PrecutLayout";
import { SawmillLayout } from "./layout/SawmillLayout";
import { LogTemporallyStored } from "../pages/Home/Log/LogTemporallyStored";
import { LogOrderHistory } from "../pages/Home/Log/LogOrderHistory";
import { WoodMailing } from "../pages/Home/Wood/WoodMailing";
import { MessageRoomsLayout } from "./layout/MessageRoomsLayout";
import { LogMarketPrice } from "../pages/Home/LogMarketPrice/LogMarketPrice";
import { DataLayout } from "./layout/DataLayout";
import { LatestLogMarketPrice } from "../pages/Home/LogMarketPrice/LatestLogMarketPrice";
import { CreateBlueprintList } from "../pages/Home/Blueprint/CreateBlueprintList";
import { BlueprintLayout } from "./layout/BlueprintLayout";
import { BlueprintMailing } from "../pages/Home/Blueprint/BlueprintMailing";
import { CreateBlueprintDetailList } from "../pages/Home/Blueprint/CreateBlueprintDetailList";
import { BlueprintTemporallyStored } from "../pages/Home/Blueprint/BlueprintTemporallyStored";
import { BlueprintOrderHistory } from "../pages/Home/Blueprint/BlueprintOrderHistory";
import { Quotation } from "../pages/Home/Quotation";
import { MessageRoomLayout } from "./layout/MessageRoomLayout";
import { ThreeSplitScreenLayout } from "./layout/ThreeSplitScreenLayout";
import { HomeNotificationList } from "../pages/Home/Home/notification_list";
import { QuotationList } from "../pages/Home/Management/Document/QuotationList";
import { OrderFormList } from "../pages/Home/Management/Document/OrderFormList";
import { DeliveryNoteList } from "../pages/Home/Management/Document/DeliveryNoteList";
import { InvoiceList } from "../pages/Home/Management/Document/InvoiceList";
import { WoodOrders } from "../pages/Home/Wood/WoodOrders";
import { LogOrders } from "../pages/Home/Log/LogOrders";
import { BlueprintOrders } from "../pages/Home/Blueprint/BlueprintOrders";
import { OriginCertificate } from "../pages/Home/Management/Document/OriginCertificate";
import TermsOfService from "../pages/TermsOfService";
import { MyNotifications } from "../pages/Home/MyNotifications";
import { PastLogMarketPrices } from "../pages/Home/LogMarketPrice/PastLogMarketPrices";
import { PastLogMarketPrice } from "../pages/Home/LogMarketPrice/PastLogMarketPrice";
import { TransactionAmount } from "../pages/Home/Management/Money/TransactionAmount";
import { PasswordReset } from "../pages/Auth/PasswordReset";
import {
  MANAGEMENT_DOCUMENT_PATH,
  MANAGEMENT_MONEY_PATH,
} from "../utils/constant";
import { Payment } from "../pages/Home/Management/Money/Payment";

export const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LoginLayout />}>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/password_reset" element={<PasswordReset />} />
        </Route>
        <Route element={<HomeLayout />}>
          <Route path="/terms_of_service" element={<TermsOfService />} />
          <Route
            path="/input_stock/wood/:wood_id"
            element={<InputWoodStock />}
          />
          <Route path="/order" element={<Order />} />
          <Route
            path="/data/log_market_price/latest"
            element={<LatestLogMarketPrice />}
          />
          <Route
            path="/data/log_market_price/past"
            element={<PastLogMarketPrices />}
          />
          <Route
            path="/data/log_market_price/:log_market_price_id"
            element={<PastLogMarketPrice />}
          />
        </Route>
        <Route element={<ThreeSplitScreenLayout />}>
          <Route path="/home" element={<HomeNotificationList />} />
          <Route path="/home/notifications" element={<MyNotifications />} />
          <Route
            path="/home/notification/:notification_id"
            element={<NotificationComponent />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/quotation_list`}
            element={<QuotationList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/order_form_list`}
            element={<OrderFormList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/delivery_note_list`}
            element={<DeliveryNoteList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/invoice_list`}
            element={<InvoiceList />}
          />
          <Route
            path={`${MANAGEMENT_DOCUMENT_PATH}/certificate`}
            element={<OriginCertificate />}
          />
          <Route path="/order/request_list" element={<RequestList />} />
          <Route
            path={`${MANAGEMENT_MONEY_PATH}/transaction_amount`}
            element={<TransactionAmount />}
          />
          <Route
            path={`${MANAGEMENT_MONEY_PATH}/payment`}
            element={<Payment />}
          />
        </Route>
        <Route element={<ProfileLayout />}>
          <Route path="/profile" element={<Profile />} />
        </Route>
        <Route element={<SawmillLayout />}>
          <Route path="/order/quotation/:wood_id" element={<Quotation />} />
          <Route path="/order/mailing/log/:log_id" element={<LogMailing />} />
          <Route
            path="/order/create_log_list/:log_id"
            element={<CreateLogList />}
          />
          <Route
            path="/order/log_temporally_stored"
            element={<LogTemporallyStored />}
          />
          <Route
            path="/order/log_order_history"
            element={<LogOrderHistory />}
          />
          <Route path="/order/log_orders" element={<LogOrders />} />
        </Route>
        <Route element={<PrecutLayout />}>
          <Route
            path="/order/create_blueprint_detail_list/:blueprint_id"
            element={<CreateBlueprintDetailList />}
          />
          <Route
            path="/order/create_wood_list/:wood_id"
            element={<CreateWoodList />}
          />
          <Route
            path="/order/wood_temporally_stored"
            element={<WoodTemporallyStored />}
          />
          <Route
            path="/order/wood_order_history"
            element={<WoodOrderHistory />}
          />
          <Route
            path="/order/mailing/wood/:wood_id"
            element={<WoodMailing />}
          />
          <Route path="/order/wood_orders" element={<WoodOrders />} />
        </Route>
        <Route element={<BlueprintLayout />}>
          <Route
            path="/order/create_blueprint_list/:blueprint_id"
            element={<CreateBlueprintList />}
          />
          <Route
            path="/order/mailing/blueprint/:blueprint_id"
            element={<BlueprintMailing />}
          />
          <Route
            path="/order/blueprint_temporally_stored"
            element={<BlueprintTemporallyStored />}
          />
          <Route
            path="/order/blueprint_order_history"
            element={<BlueprintOrderHistory />}
          />
          <Route path="/order/blueprint_orders" element={<BlueprintOrders />} />
        </Route>
        <Route element={<MessageRoomsLayout />}>
          <Route path="/talk/message_rooms" element={<MessageRooms />} />
          <Route
            path="/talk/order_message_rooms"
            element={<OrderMessageRooms />}
          />
        </Route>
        <Route element={<MessageRoomLayout />}>
          <Route
            path="/talk/message_room/:message_room_id"
            element={<MessageRoom />}
          />
          <Route
            path="/talk/wood_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/log_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
          <Route
            path="/talk/blueprint_order_message_room/:order_message_room_id"
            element={<OrderMessageRoom />}
          />
        </Route>
        <Route element={<ManagementLayout />}>
          <Route path="/management/stock" element={<Stock />} />
          <Route path={MANAGEMENT_DOCUMENT_PATH} element={<Document />} />
          <Route path={MANAGEMENT_MONEY_PATH} element={<Money />} />
        </Route>
        <Route element={<DataLayout />}>
          <Route path="/data/log_market_price" element={<LogMarketPrice />} />
        </Route>
        <Route element={<AdminLayout />}>
          <Route path="/user" element={<UserComponent />} />
        </Route>
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </BrowserRouter>
  );
};
