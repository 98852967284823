import { ZodSchema } from "zod";

// バリデーション成功時の型
interface ValidationSuccess {
  success: true;
  errors: null;
}

// バリデーション失敗時の型
interface ValidationFailure {
  success: false;
  errors: string[];
}

// ValidationResponse をユニオン型に変更
type ValidationResponse = ValidationSuccess | ValidationFailure;

/**
 * データとスキーマを受け取り、バリデーションを実行する関数
 * @param data バリデートするデータ
 * @param schema zod スキーマ
 * @returns ValidationResponse
 */
export const validateData = <T>(
  data: T,
  schema: ZodSchema<T>,
): ValidationResponse => {
  const validationResult = schema.safeParse(data);
  if (validationResult.success) {
    return {
      success: true,
      errors: null,
    };
  } else {
    const errorMessages = validationResult.error.errors.map(
      (err) => err.message,
    );
    return {
      success: false,
      errors: errorMessages,
    };
  }
};
