import { SearchPageRequestWithStatus } from "../hooks/useOrderHook";
import { PAGE_SIZE } from "./constant";
import { SearchTransactionAmount } from "./schema";
import {
  OrderStatusWithSearchParams,
  PaginationParams,
  SearchTextWIthPaginationParams,
} from "./types/general_type";

export const userKeysBase = {
  user: ["user"],
  users: ["users"],
} as const;

export const userKeys = {
  ...userKeysBase,
  search: (searchText: string) => [...userKeysBase.users, "search", searchText],
  searchMessageRoom: (searchText: string) => [
    ...userKeysBase.users,
    "searchMessageRoom",
    searchText,
  ],
  searchWood: (searchText: string) => [
    ...userKeysBase.users,
    "searchWood",
    searchText,
  ],
  searchLog: (searchText: string) => [
    ...userKeysBase.users,
    "searchLog",
    searchText,
  ],
  searchBlueprint: (searchText: string) => [
    ...userKeysBase.users,
    "searchBlueprint",
    searchText,
  ],
  order: (params: PaginationParams) => [
    ...userKeysBase.users,
    params.page,
    params.pageSize,
    "order",
  ],
} as const;

export const notificationKeys = {
  notification: (notificationId: number) => ["notification", notificationId],
  notifications: ({
    searchText,
    page,
    pageSize,
  }: SearchTextWIthPaginationParams) => [
    "notifications",
    searchText,
    page,
    pageSize,
  ],
  my_notifications: ({
    searchText,
    page,
    pageSize,
  }: SearchTextWIthPaginationParams) => [
    "my_notifications",
    searchText,
    page,
    pageSize,
  ],
  search: (searchText: string, notificationId?: number) => [
    "notifications",
    searchText,
    "search",
    notificationId,
  ],
} as const;

export const messageRoomKeys = {
  messageRoom: (roomId: number) => ["messageRoom", roomId],
  messageRooms: ["messageRooms"],
} as const;

export const orderMessageRoomKeys = {
  orderMessageRoom: (roomId: number) => ["orderMessageRoom", roomId],
  orderMessageRooms: ["orderMessageRooms"],
  search: ({ searchText, page, pageSize }: SearchTextWIthPaginationParams) => [
    ...orderMessageRoomKeys.orderMessageRooms,
    "search",
    searchText,
    page,
    pageSize,
  ],
} as const;

export const s3Keys = {
  image: (imagePath?: string) => ["image", imagePath],
  file: (dataPath?: string) => ["file", dataPath],
} as const;

export const sessionKeys = {
  session: ["session"],
} as const;

export const woodKeys = {
  wood: (woodId: number) => ["wood", woodId],
  woods: ["woods"],
  search: (searchText: string, woodId?: number) => [
    ...woodKeys.woods,
    "search",
    searchText,
    woodId,
  ],
  wood_receiver: (woodId: number) => ["woodReceiver", woodId],
} as const;

export const logKeys = {
  log: (logId: number) => ["log", logId],
  logs: ["logs"],
  search: (searchText: string, logId?: number) => [
    ...logKeys.logs,
    "search",
    searchText,
    logId,
  ],
} as const;

export const blueprintKeys = {
  blueprint: (blueprintId: number) => ["blueprint", blueprintId],
  blueprints: ["blueprints"],
  search: (searchText: string, blueprintId?: number) => [
    ...blueprintKeys.blueprints,
    "search",
    searchText,
    blueprintId,
  ],
  blueprint_receiver: (blueprintId: number) => ["woodReceiver", blueprintId],
} as const;

export const stockKeys = {
  stock: (stockId: number) => ["stock", stockId],
} as const;

export const logMarketPriceKeys = {
  logMarketPrice: ["logMarketPrice"],
  logMarketPrice_by_id: (id: number) => ["logMarketPrice", id],
  logMarketPrices: ["logMarketPrices"],
  search: (searchText: string, logMarketPriceId?: number) => [
    ...logMarketPriceKeys.logMarketPrices,
    "search",
    searchText,
    logMarketPriceId,
  ],
} as const;

export const documentKeys = {
  quotations: ({ page, pageSize }: PaginationParams) => [
    "quotations",
    page,
    pageSize,
  ],
  orderForms: ({ page, pageSize }: PaginationParams) => [
    "orderForms",
    page,
    pageSize,
  ],
  deliveryNotes: ({ page, pageSize }: PaginationParams) => [
    "deliveryNotes",
    page,
    pageSize,
  ],
  deliveryNotesByReceiver: ({ page, pageSize }: PaginationParams) => [
    "deliveryNotesByReceiver",
    page,
    pageSize,
  ],
  invoices: ({ page, pageSize }: PaginationParams) => [
    "invoices",
    page,
    pageSize,
  ],
} as const;

export const paymentKeys = {
  paymentByRange: ({ startDate, endDate, type }: SearchTransactionAmount) => [
    "paymentByRange",
    startDate,
    endDate,
    type,
  ],
  setupIntent: ["setupIntent"],
} as const;

export const itemKeys = {
  items_by_filter: ({
    itemName,
    isTemporarilyStored,
    isOrdered,
    page,
    pageSize = PAGE_SIZE,
  }: OrderStatusWithSearchParams) => [
    itemName,
    isTemporarilyStored,
    isOrdered,
    page,
    pageSize,
  ],
} as const;

export const orderKeys = {
  searchStatuses: ({
    type,
    searchText,
    page,
    pageSize = PAGE_SIZE,
    statuses,
  }: SearchPageRequestWithStatus) => [
    "orders",
    "searchStatus",
    type,
    searchText,
    page,
    pageSize,
    statuses,
  ],
} as const;
