import { Divider } from "@mui/material";
import { FileDownload } from "./FileDownload";
import { LogOrder } from "../../hooks/Log/useLogOrderHook";
import { WoodOrder } from "../../hooks/Wood/useWoodOrderHook";
import { BlueprintOrder } from "../../hooks/Blueprint/useBlueprintOrderHook";
import { Wood } from "../../hooks/Wood/Wood/type";
import { Log } from "../../hooks/Log/Log/type";
import { GetBlueprint } from "../../hooks/Blueprint/Blueprint/type";

export function isLogByOrder(
  order: LogOrder | WoodOrder | BlueprintOrder,
): order is LogOrder {
  return (order as LogOrder).log !== undefined;
}

export function isWoodByOrder(
  order: LogOrder | WoodOrder | BlueprintOrder,
): order is WoodOrder {
  return (order as WoodOrder).wood !== undefined;
}

export function isBlueprintByOrder(
  order: LogOrder | WoodOrder | BlueprintOrder,
): order is BlueprintOrder {
  return (order as BlueprintOrder).blueprint !== undefined;
}

export const FileDownloadComponent = ({
  order,
  wood,
  log,
  blueprint,
}: {
  order: LogOrder | WoodOrder | BlueprintOrder;
  wood?: Wood;
  log?: Log;
  blueprint?: GetBlueprint;
}) => {
  return (
    <>
      <Divider
        orientation="vertical"
        flexItem
        sx={{ mx: 1, borderColor: "black" }}
      />
      <FileDownload
        file={{
          id: isLogByOrder(order)
            ? order.log_quotation?.id
            : isWoodByOrder(order)
              ? order.wood_quotation?.id
              : isBlueprintByOrder(order)
                ? order.blueprint_quotation?.id
                : undefined,
          itemType: log
            ? "log"
            : wood
              ? "wood"
              : blueprint
                ? "blueprint"
                : undefined,
        }}
        category="quotation"
      />
    </>
  );
};
