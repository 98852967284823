import { useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import { useCreateLog } from "../../hooks/Log/Log/useLogHook";
import { MenuItemComponent } from "../../uis/Item/MenuItem";
import { UserContext } from "../../contexts/UserContext";
import { Industry } from "../../utils/industry";
import { useCreateBlueprint } from "../../hooks/Blueprint/Blueprint/useBlueprintHook";
import { useCreateWood } from "../../hooks/Wood/Wood/useWoodHook";
import { ORDER_BASE_PATH } from "../../utils/constant";

export const Order = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する
  const { mutateAsync } = useCreateWood();
  const { mutateAsync: mutateLogAsync } = useCreateLog();
  const { mutateAsync: mutateBlueprintAsync } = useCreateBlueprint();

  const menuItems = [
    {
      title: "依頼リスト",
      items: [
        { text: "依頼リスト一覧", link: `${ORDER_BASE_PATH}/request_list` },
      ],
    },
    {
      title: "木材リスト",
      items: [
        { text: "一時保存", link: `${ORDER_BASE_PATH}/wood_temporally_stored` },
        { text: "発注履歴", link: `${ORDER_BASE_PATH}/wood_order_history` },
        { text: "注文申請中", link: `${ORDER_BASE_PATH}/wood_orders` },
      ],
    },
    {
      title: "木材リスト作成",
      items: [
        { text: "木材リスト作成", link: `${ORDER_BASE_PATH}/create_wood_list` },
      ],
    },
    {
      title: "丸太リスト",
      items: [
        { text: "一時保存", link: `${ORDER_BASE_PATH}/log_temporally_stored` },
        { text: "発注履歴", link: `${ORDER_BASE_PATH}/log_order_history` },
        { text: "注文申請中", link: `${ORDER_BASE_PATH}/log_orders` },
      ],
    },
    {
      title: "丸太リスト作成",
      items: [
        { text: "丸太リスト作成", link: `${ORDER_BASE_PATH}/create_log_list` },
      ],
    },
    {
      title: "設計図リスト",
      items: [
        {
          text: "一時保存",
          link: `${ORDER_BASE_PATH}/blueprint_temporally_stored`,
        },
        {
          text: "発注履歴",
          link: `${ORDER_BASE_PATH}/blueprint_order_history`,
        },
        { text: "注文申請中", link: `${ORDER_BASE_PATH}/blueprint_orders` },
      ],
    },
    {
      title: "設計図リスト作成",
      items: [
        {
          text: "設計図リスト作成",
          link: `${ORDER_BASE_PATH}/create_blueprint_list`,
        },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）

    switch (link) {
      case `${ORDER_BASE_PATH}/create_wood_list`:
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          const res = await mutateAsync();
          navigate(`${link}/${res.id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      case `${ORDER_BASE_PATH}/create_log_list`:
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          const res = await mutateLogAsync();
          navigate(`${link}/${res.id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      case `${ORDER_BASE_PATH}/create_blueprint_list`:
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          const res = await mutateBlueprintAsync();
          navigate(`${link}/${res.id}`);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      default:
        navigate(link);
    }
  };

  const filteredMenuItems = menuItems.filter((item) => {
    if (!user) return false; // false を返すように修正
    switch (user.industry) {
      case Industry.Forestry:
      case Industry.Market:
      case Industry.Manufacturer:
        return item.title === "依頼リスト";
      case Industry.Builder:
        return (
          item.title === "依頼リスト" ||
          item.title === "設計図リスト" ||
          item.title === "設計図リスト作成"
        );
      case Industry.Sawmill:
        return (
          item.title === "依頼リスト" ||
          item.title === "丸太リスト" ||
          item.title === "丸太リスト作成"
        );
      case Industry.Precut:
        return (
          item.title === "依頼リスト" ||
          item.title === "木材リスト作成" ||
          item.title === "木材リスト"
        );
      case Industry.Viewer:
        return (
          item.title === "依頼リスト" ||
          item.title === "丸太リスト" ||
          item.title === "木材リスト"
        );
      case Industry.SawmillPrecut:
      case Industry.Administrator:
        return (
          item.title === "依頼リスト" ||
          item.title === "丸太リスト作成" ||
          item.title === "丸太リスト" ||
          item.title === "木材リスト作成" ||
          item.title === "木材リスト"
        );
      default:
        return false;
    }
  });

  return (
    <MenuItemComponent
      menuItems={filteredMenuItems}
      handleClick={handleClick}
      disabled={submitProcessing.current}
    />
  );
};
