import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, orderKeys } from "../../utils/query-key";
import { ReadonlyResponse, TimeStamp } from "../../utils/types/general_type";
import { User } from "../useUserHook";
import { GetBlueprint } from "./Blueprint/type";
import { UUID } from "crypto";
import { PAGE_SIZE } from "../../utils/constant";

export interface BlueprintOrder extends TimeStamp {
  id: number;
  blueprint_id: number;
  receiver_id?: UUID;
  is_pending: boolean;
  is_denied: boolean;
  is_pending_sended: boolean;
  is_denied_sended: boolean;
  is_received: boolean;
  is_contract_signed: boolean;
  is_active: boolean;
  user: User;
  blueprint: GetBlueprint;
  blueprint_quotation?: BlueprintQuotation;
  blueprint_order_form?: BlueprintOrderForm;
  blueprint_delivery_note?: BlueprintDeliveryNote;
  blueprint_invoice?: BlueprintInvoice;
}

interface BlueprintQuotation {
  id: number;
  blueprint_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface BlueprintOrderForm {
  id: number;
  blueprint_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface BlueprintDeliveryNote {
  id: number;
  blueprint_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface BlueprintInvoice {
  id: number;
  blueprint_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

export interface CreateBlueprintOrder {
  blueprintId: number;
  isAll: boolean;
  userIds: UUID[];
}

export type BlueprintOrders = ReadonlyArray<BlueprintOrder>;

export function useCreateBlueprintOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateBlueprintOrder) => {
      const { token } = await getAccessToken();
      // userIdsが不要な場合はnullにする
      const userIds = data.isAll ? undefined : data.userIds;

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/blueprint_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          blueprint_id: data.blueprintId,
          is_all: data.isAll,
          user_ids: userIds, // userIdsがundefinedなら送信しない
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "blueprint",
          isTemporarilyStored: false,
          isOrdered: true,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: "blueprint",
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
          statuses: [],
        }),
      });
    },
  });
}
