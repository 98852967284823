import {
  Box,
  Typography,
  Paper,
  Chip,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Documents,
  Document,
  DocumentResponse,
} from "../../hooks/useDocumentHook";
import { Loading } from "../../uis/Loading";
import { Error as CustomError } from "../../uis/Error";
import { AcceptedCategories, FileDownload } from "../../uis/File/FileDownload";
import { useEffect, useState } from "react";
import { useSendEmail } from "../../hooks/useEmailHook";
import { toast } from "react-toastify";
import { ItemType } from "../../utils/types/item_type";
import config from "../../config";
import { FileUpload } from "../../uis/File/FileUpload";
import { QueryObserverResult, RefetchOptions } from "@tanstack/react-query";
import { PaginationParams } from "../../utils/types/general_type";
import { useSearchParams } from "react-router-dom";
import { PaginationComponent } from "../../uis/Pagination";

interface DocumentListProps {
  category: AcceptedCategories;
  useGetDocuments: ({ page, pageSize }: PaginationParams) => {
    data: DocumentResponse | undefined;
    error: Error | null;
    isPending: boolean;
    refetch: (
      options?: RefetchOptions,
    ) => Promise<QueryObserverResult<DocumentResponse, Error>>;
  };
  isOriginalCertificate?: boolean;
}

interface EmailRecipient {
  name: string;
  email: string;
}

const getTitle = (document: Document): string => {
  switch (document.type) {
    case "wood":
      return document.wood?.title || "無題の木材";
    case "log":
      return document.log?.title || "無題の丸太";
    case "blueprint":
      return document.blueprint?.title || "無題の設計図";
    default:
      return "不明なタイプ";
  }
};

const getItemTypeLabel = (type: ItemType): string => {
  switch (type) {
    case "wood":
      return "木材";
    case "log":
      return "丸太";
    case "blueprint":
      return "設計図";
    default:
      return "不明";
  }
};

export const DocumentList = ({
  category,
  useGetDocuments,
  isOriginalCertificate = false,
}: DocumentListProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // useSearchParamsを使用してクエリパラメータを管理
  const [searchParams, setSearchParams] = useSearchParams();

  // URLから初期ページを取得、存在しない場合は1
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const [page, setPage] = useState<number>(
    isNaN(initialPage) || initialPage < 1 ? 1 : initialPage,
  );

  const {
    data: fetchedData,
    error,
    isPending,
    refetch,
  } = useGetDocuments({
    page,
  });
  const [documentIdNumber, setDocumentIdNumber] = useState<number | null>(null);
  const [type, setType] = useState<ItemType | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [data, setData] = useState<Documents>([]); // dataを状態管理
  const { mutateAsync, isPending: isMutatePending } = useSendEmail();
  const [isPolling, setIsPolling] = useState<boolean>(false); // ポーリングの状態管理を追加

  // 初回データ取得時にdataを設定
  // 産地証明書ページでstatusを判別するために必要
  useEffect(() => {
    if (fetchedData) {
      setData(fetchedData.documents);
      const hasPendingStatus = fetchedData.documents.some((data) =>
        data?.delivery_note_emails?.some(
          (email) => email?.status?.status === "pending",
        ),
      );
      if (hasPendingStatus) {
        setIsPolling(true);
      }
    }
  }, [fetchedData]);

  // 産地証明書ページでstatusを判別するために必要
  useEffect(() => {
    let intervalId: number;

    if (isPolling) {
      intervalId = window.setInterval(async () => {
        try {
          const newData = await refetch();
          if (newData && newData.data?.documents) {
            setData(newData.data?.documents);

            // 全てのDocumentsのdelivery_note_emailsのstatusを確認
            const allSuccess = newData.data?.documents.every((document) =>
              document.delivery_note_emails.every(
                (email) => email.status?.status === "success",
              ),
            );

            const allFailure = newData.data?.documents.every((document) =>
              document.delivery_note_emails.every(
                (email) => email.status?.status === "failure",
              ),
            );

            if (allSuccess) {
              setIsPolling(false);
            } else if (allFailure) {
              newData.data?.documents.forEach((document) => {
                document.delivery_note_emails
                  .filter((email) => email.status?.status === "failure")
                  .forEach((email) => {
                    if (email.status?.failure_reason) {
                      toast.error(email.status.failure_reason);
                    }
                  });
              });
              setIsPolling(false);
            }
          }
        } catch (error) {
          console.error(error);
          setIsPolling(false);
        }
      }, 1000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPolling]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    // クエリパラメータを更新
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: value.toString(),
    });
  };

  // クエリパラメータが変更された際に page と searchText を更新
  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);

    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const emailRecipients = [
    {
      name: "京都府産木材(京都府木材連合会)",
      email: config.kyotoEmail!,
    },
    {
      name: "みやこ杣木（京都市域産材供給協会）",
      email: config.miyakoEmail!,
    },
  ] as const satisfies ReadonlyArray<EmailRecipient>;

  const handleSendEmail = async (email: string) => {
    if (!documentIdNumber || !type || !selectedFile) {
      toast.error("ドキュメントが選択されていません");
      return;
    }
    try {
      await mutateAsync({
        deliveryNoteId: documentIdNumber,
        email,
        type,
        selectedFile,
      });
      setDocumentIdNumber(null);
      setType(null);
      setSelectedFile(null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <CustomError message={error?.message} />;
  }

  return (
    <Box sx={{ width: "80%", margin: "0 auto", padding: "2rem" }}>
      {data && data.length > 0 ? (
        data.map((document, index) => (
          <Paper
            key={index}
            elevation={3}
            sx={{ padding: "1.5rem", marginBottom: "1.5rem" }}
          >
            {/* タイトルとアイテムタイプの表示 */}
            <Box sx={{ marginBottom: "1rem" }}>
              <Typography variant="h5" component="h2" gutterBottom>
                {getTitle(document)}
              </Typography>
              <Chip
                label={getItemTypeLabel(document.type)}
                color="primary"
                size="small"
              />
            </Box>

            <Divider sx={{ marginBottom: "1rem" }} />

            {/* 受注状況と契約状況の表示 */}
            <Grid container spacing={2} sx={{ marginBottom: "1rem" }}>
              {document.is_received && (
                <Grid item>
                  <Chip label="受注済み" color="success" />
                </Grid>
              )}
              {document.is_contract_signed && (
                <Grid item>
                  <Chip label="契約済み" color="secondary" />
                </Grid>
              )}
              {document.is_active && (
                <Grid item>
                  <Chip label="手続き中" color="info" />
                </Grid>
              )}
            </Grid>

            <Divider sx={{ marginBottom: "1rem" }} />

            {/* 発注者と受注者の表示 */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle1" component="p">
                  <strong>発注者:</strong>{" "}
                  {document.order.company_name || "不明"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" component="p">
                  <strong>受注者:</strong>{" "}
                  {document.receiver.company_name || "不明"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1" component="p">
                  <strong>発行日:</strong>{" "}
                  {new Date(document.created_at).toLocaleDateString("ja-JP", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ marginBottom: "1rem" }} />

            <Grid container spacing={2}>
              <FileDownload
                file={{
                  id: document.document_id,
                  itemType: document.type,
                }}
                category={category}
              />
            </Grid>
            {isOriginalCertificate && (
              <>
                <Divider sx={{ marginBottom: "1rem" }} />
                <Grid container spacing={2}>
                  <Button
                    onClick={() => {
                      setDocumentIdNumber(document.document_id);
                      setType(document.type);
                    }}
                  >
                    産地証明を発行する
                  </Button>
                </Grid>
              </>
            )}
          </Paper>
        ))
      ) : (
        <Typography variant="h6" component="p" align="center">
          表示するドキュメントがありません。
        </Typography>
      )}
      {/* ページネーション */}
      {fetchedData && fetchedData.totalPages > 1 && (
        <PaginationComponent
          totalPages={fetchedData.totalPages}
          page={page}
          handlePageChange={handlePageChange}
          isSmallScreen={isSmallScreen}
        />
      )}

      <Dialog
        open={documentIdNumber !== null}
        onClose={() => {
          setDocumentIdNumber(null);
          setType(null);
        }}
      >
        <DialogTitle>メール送付先を選択</DialogTitle>
        <FileUpload
          selectedFile={selectedFile}
          filePath={""}
          handleFileChange={handleFileChange}
          accept={["xlsx"]}
          message="CSV/Excelファイルを取りこむ"
        />
        <DialogContent>
          <Grid container spacing={2}>
            {emailRecipients
              .filter((emailRecipient) => {
                const selectedDocument = data.find(
                  (it) => it.document_id === documentIdNumber,
                );
                return !selectedDocument?.delivery_note_emails.some(
                  (deliveryNoteEmail) =>
                    deliveryNoteEmail.email === emailRecipient.email,
                );
              })
              .map((recipient) => (
                <Grid item xs={12} key={recipient.email}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => handleSendEmail(recipient.email)}
                    disabled={isMutatePending || selectedFile === null}
                  >
                    {recipient.name} ({recipient.email})
                  </Button>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setType(null);
              setDocumentIdNumber(null);
            }}
            disabled={isMutatePending}
          >
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
