import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { Password, PasswordSchema } from "../../utils/schema";
import { zodResolver } from "@hookform/resolvers/zod";
import FormField from "../Forms/FormField";
import { useForm } from "react-hook-form";
import { useVerifyAdminPassword } from "../../hooks/usePasswordHook";
import CloseIcon from "@mui/icons-material/Close";

interface AdminPasswordModalProps {
  open: boolean;
  onSuccessClose: () => void;
  onErrorClose: () => void;
}

export const AdminPasswordModal = ({
  open,
  onSuccessClose,
  onErrorClose,
}: AdminPasswordModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Password>({
    resolver: zodResolver(PasswordSchema),
  });
  const { mutateAsync } = useVerifyAdminPassword();

  const onSubmit = async (data: Password) => {
    try {
      await mutateAsync(data);
      onSuccessClose();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = (
    reason: "backdropClick" | "escapeKeyDown" | "closeButton",
  ) => {
    if (reason === "closeButton") {
      onErrorClose();
    }
    // Do nothing for backdropClick and escapeKeyDown to prevent closing
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="admin-password-modal-title"
      aria-describedby="admin-password-modal-description"
      disableEscapeKeyDown
      hideBackdrop={false}
    >
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 400 },
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 4,
          outline: "none",
        }}
      >
        {/* バツボタンの追加 */}
        <IconButton
          onClick={() => onErrorClose()}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            width: "100%",
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
            管理者用パスワードを入力してください
          </Typography>
          <FormField<Password>
            placeholder="管理者用パスワードを入力して下さい"
            register={register}
            name="adminPassword"
            isError={!!errors.adminPassword}
            errorMessage={errors.adminPassword?.message}
            labelName="管理者用パスワードを入力して下さい"
          />
          <Box sx={{ mt: 3 }}>
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              variant="contained"
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                height: "3rem",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              送信
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
