import { useGetWood } from "../../../hooks/Wood/Wood/useWoodHook";
import { useSearchWoodUsers } from "../../../hooks/useUserHook";
import { useCreateWoodOrder } from "../../../hooks/Wood/useWoodOrderHook";
import { MailingComponent } from "../../common/MailingComponent";

export const WoodMailing = () => {
  return (
    <MailingComponent
      idParamName="wood_id"
      useGetData={useGetWood}
      useSearchUsers={useSearchWoodUsers}
      useCreateOrder={useCreateWoodOrder}
      orderDataKey="woodId"
      navigateTo="/order"
    />
  );
};
