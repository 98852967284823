import { Box, Typography } from "@mui/material";
import { Color } from "../utils/color";

export const Title = ({ title }: { title: string }) => {
  return (
    <Box sx={{ mt: 4, mb: 2 }}>
      <Typography
        variant="h6"
        sx={{
          color: Color.Main,
          textAlign: "left",
          position: "relative",
          pb: 1,
          "&::after": {
            content: '""',
            position: "absolute",
            left: 0,
            bottom: 0,
            width: "100%",
            height: "2px",
            backgroundColor: "green",
          },
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
