import { ItemList } from "../../uis/Item/ItemList";
import { ItemType } from "../../utils/types/item_type";

interface TemporallyStoredProps {
  itemName: ItemType;
}

export const TemporallyStored = ({ itemName }: TemporallyStoredProps) => {
  return <ItemList isOrderHistory={false} itemName={itemName} />;
};
