import { useParams, useLocation } from "react-router-dom";
import {
  BlueprintMessage,
  BlueprintOrderMessageRoom,
  LogMessage,
  LogOrderMessageRoom,
  useGetOrderMessageRoom,
  WoodMessage,
  WoodOrderMessageRoom,
} from "../../hooks/useOrderMessageRoomHook";
import { useCreateOrderMessage } from "../../hooks/useOrderMessageHook";
import { useContext, useState } from "react";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { TabBar } from "../../uis/Bars/Tab";
import { Box } from "@mui/material";
import { MessageTab } from "../common/MessageTab";
import { DetailsTab } from "../common/DetailsTab";
import { UserContext } from "../../contexts/UserContext";
import {
  isBlueprintUserIndustry,
  isLogReceiverIndustry,
  isPrecutUserIndustry,
  isSawmillUserIndustry,
} from "../../utils/industry";
import { ItemType } from "../../utils/types/item_type";

// 型ガード関数の定義
export const isLog = (
  room:
    | Readonly<LogOrderMessageRoom>
    | Readonly<WoodOrderMessageRoom>
    | Readonly<BlueprintOrderMessageRoom>,
): room is Readonly<LogOrderMessageRoom> => {
  return (room as Readonly<LogOrderMessageRoom>).log_order !== undefined;
};

export const isWood = (
  room:
    | Readonly<LogOrderMessageRoom>
    | Readonly<WoodOrderMessageRoom>
    | Readonly<BlueprintOrderMessageRoom>,
): room is Readonly<WoodOrderMessageRoom> => {
  return (room as Readonly<WoodOrderMessageRoom>).wood_order !== undefined;
};

export const isBlueprint = (
  room:
    | Readonly<LogOrderMessageRoom>
    | Readonly<WoodOrderMessageRoom>
    | Readonly<BlueprintOrderMessageRoom>,
): room is Readonly<BlueprintOrderMessageRoom> => {
  return (
    (room as Readonly<BlueprintOrderMessageRoom>).blueprint_order !== undefined
  );
};

export const OrderMessageRoom = () => {
  const user = useContext(UserContext);
  const { order_message_room_id } = useParams<{
    order_message_room_id: string;
  }>();
  const location = useLocation();
  const isWoodOrder = location.pathname.includes("wood_order_message_room");
  const isLogOrder = location.pathname.includes("log_order_message_room");
  const isBlueprintOrder = location.pathname.includes(
    "blueprint_order_message_room",
  );
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const type = isWoodOrder
    ? "wood"
    : isLogOrder
      ? "log"
      : isBlueprintOrder
        ? "blueprint"
        : undefined;

  const { data, error, isPending } = useGetOrderMessageRoom({
    type,
    messageRoomId: Number(order_message_room_id),
  });

  const { mutateAsync, isPending: isMutatePending } = useCreateOrderMessage({
    type,
    messageRoomId: Number(order_message_room_id),
  });

  const sessionUserId = user?.user_id;

  const tabs = [{ label: "メッセージ" }, { label: "詳細" }] as const;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <Error message={error.message} />;
  }

  const renderMessageTab = () => {
    if (!type || !data.messages || !user || user.industry === undefined)
      return null;
    let isDeliveryNoteButtonShown = false;
    let isInvoiceButtonShown = false;
    // 請求書、納品書の発行が行われて、相手側のstripe connectが実装され、自分が発注者側の時にtrueにする。
    let isPaymentShown = false;
    let orderId: number | undefined = undefined;

    const messageUser =
      data.order.user_id === sessionUserId ? data.order : data.receiver;
    const partner =
      data.order.user_id === sessionUserId ? data.receiver : data.order;
    if (isLog(data)) {
      isDeliveryNoteButtonShown =
        !Boolean(data.log_order.log_delivery_note) &&
        isLogReceiverIndustry(user.industry);
      isInvoiceButtonShown =
        !Boolean(data.log_order.log_invoice) &&
        isLogReceiverIndustry(user.industry);
      isPaymentShown =
        Boolean(data.log_order.log_delivery_note) &&
        Boolean(data.log_order.log_invoice) &&
        isSawmillUserIndustry(user.industry) &&
        Boolean(partner.stripe_account_status === "completed");
      orderId = data.log_order.id;
    } else if (isWood(data)) {
      isDeliveryNoteButtonShown =
        !Boolean(data.wood_order.wood_delivery_note) &&
        isSawmillUserIndustry(user.industry);
      isInvoiceButtonShown =
        !Boolean(data.wood_order.wood_invoice) &&
        isSawmillUserIndustry(user.industry);
      isPaymentShown =
        Boolean(data.wood_order.wood_delivery_note) &&
        Boolean(data.wood_order.wood_invoice) &&
        isPrecutUserIndustry(user.industry) &&
        Boolean(partner.stripe_account_status === "completed");
      orderId = data.wood_order.id;
    } else if (isBlueprint(data)) {
      isDeliveryNoteButtonShown =
        !Boolean(data.blueprint_order.blueprint_delivery_note) &&
        isPrecutUserIndustry(user.industry);
      isInvoiceButtonShown =
        !Boolean(data.blueprint_order.blueprint_invoice) &&
        isPrecutUserIndustry(user.industry);
      isPaymentShown =
        Boolean(data.blueprint_order.blueprint_delivery_note) &&
        Boolean(data.blueprint_order.blueprint_invoice) &&
        isBlueprintUserIndustry(user.industry) &&
        Boolean(partner.stripe_account_status === "completed");
      orderId = data.blueprint_order.id;
    }

    const commonProps = {
      mutateAsync,
      isMutatePending,
      sessionUserId,
      type: type as ItemType,
      user: messageUser,
      partner,
      isDeliveryNoteButtonShown,
      isInvoiceButtonShown,
      isPaymentShown,
      orderId,
    };

    switch (type) {
      case "wood":
        return (
          <MessageTab
            {...commonProps}
            messages={data.messages as WoodMessage[]}
          />
        );
      case "log":
        return (
          <MessageTab
            {...commonProps}
            messages={data.messages as LogMessage[]}
          />
        );
      case "blueprint":
        return (
          <MessageTab
            {...commonProps}
            messages={data.messages as BlueprintMessage[]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "60%" }}>
      <TabBar
        tabs={tabs}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      {selectedTab === 0 && renderMessageTab()}
      {selectedTab === 1 && <DetailsTab data={data} />}
    </Box>
  );
};
