import { useGetDeliveryNotesByReceiver } from "../../../../hooks/useDocumentHook";
import { DocumentList } from "../../../common/DocumentList";

export const OriginCertificate = () => {
  return (
    <DocumentList
      category="delivery_note"
      useGetDocuments={useGetDeliveryNotesByReceiver}
      isOriginalCertificate={true}
    />
  );
};
