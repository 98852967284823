import { Box, Typography } from "@mui/material";
import { industryLabels } from "../utils/industry";
import { User } from "../hooks/useUserHook";

export const UserInfo: React.FC<{ user: User; status: string }> = ({
  user,
  status,
}) => (
  <Box sx={{ mb: 2 }}>
    <Typography variant="subtitle1">
      会社名: {user.company_name || "N/A"}
    </Typography>
    <Typography variant="subtitle1">
      業界:{" "}
      {user.industry !== undefined ? industryLabels[user.industry] : "N/A"}
    </Typography>
    <Typography variant="subtitle2" color="textSecondary">
      ステータス: {status}
    </Typography>
  </Box>
);
