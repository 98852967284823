import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { DetailSection } from "../DetailsSection";
import { OrderDetailModal } from "../Modals/OrderDetailModal";
import { ItemType, UsersItem } from "../../utils/types/item_type";
import {
  isBlueprint,
  isLog,
  isWood,
} from "../../pages/common/MailingComponent";
import { BlueprintOrder } from "../../hooks/Blueprint/useBlueprintOrderHook";
import { LogOrder } from "../../hooks/Log/useLogOrderHook";
import { WoodOrder } from "../../hooks/Wood/useWoodOrderHook";
import { getUserLists } from "../../utils/user";
import { UserInfo } from "../UserInfo";
import { extractOrders } from "../../utils/extract";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Title } from "../Title";
import { conversionItemName } from "../../utils/conversion";
import { useGetItems } from "../../hooks/useItemHook";
import { Loading } from "../Loading";
import { Error } from "../Error";
import { PaginationComponent } from "../Pagination";

interface ItemListProps {
  isOrderHistory?: boolean;
  itemName: ItemType;
  handleCancelOrder?: (itemId: number) => Promise<void>;
}

export function ItemList({
  isOrderHistory = true,
  itemName,
  handleCancelOrder,
}: ItemListProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [order, setOrder] = useState<UsersItem | null>(null);
  const navigate = useNavigate();
  // useSearchParamsを使用してクエリパラメータを管理
  const [searchParams, setSearchParams] = useSearchParams();

  // URLから初期ページを取得、存在しない場合は1
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const [page, setPage] = useState<number>(
    isNaN(initialPage) || initialPage < 1 ? 1 : initialPage,
  );

  const { data, error, isPending } = useGetItems({
    itemName,
    isTemporarilyStored: !isOrderHistory,
    isOrdered: isOrderHistory,
    page,
  });
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    // クエリパラメータを更新
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: value.toString(),
    });
  };

  // クエリパラメータが変更された際にページを更新
  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (isPending) return <Loading />;
  if (error) return <Error message={error.message} />;

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Title
        title={`${conversionItemName(itemName)}リスト　${isOrderHistory ? "発注" : "一時保存"}履歴`}
      />
      <List
        sx={{ width: "95%", bgcolor: "background.paper", margin: "0 auto" }}
      >
        {data &&
          data.items.length > 0 &&
          data.items.map((item, index) => {
            const log = isLog(item) ? item : undefined;
            const wood = isWood(item) ? item : undefined;
            const blueprint = isBlueprint(item) ? item : undefined;

            const itemName = wood
              ? "wood"
              : log
                ? "log"
                : blueprint
                  ? "blueprint"
                  : "";
            const orders:
              | readonly (BlueprintOrder | LogOrder | WoodOrder)[]
              | undefined = extractOrders({ wood, log, blueprint });

            const users = log
              ? log.log_orders.map((it) => it.user)
              : wood
                ? wood.wood_orders.map((it) => it.user)
                : blueprint
                  ? blueprint.blueprint_orders.map((it) => it.user)
                  : [];

            const {
              isContractingUser,
              isReceivingUsers,
              isPendingUsers,
              isDenyingUsers,
              isApplyingUsers,
            } = getUserLists({ users, wood, log, blueprint });

            return (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{
                  borderBottom: "1px solid black",
                  minHeight: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ListItemText
                  primary={
                    log ? (
                      <Box>
                        <Typography variant="h5" sx={{ fontSize: "1.5rem" }}>
                          {log.title ?? "タイトルなし"}
                        </Typography>
                        {log.total_amount !== undefined && (
                          <Typography variant="subtitle1" color="textSecondary">
                            見積もり金額（合計）: ¥
                            {log.total_amount?.toLocaleString()}
                          </Typography>
                        )}
                      </Box>
                    ) : (
                      // カスタムタイトルを使用
                      (wood?.title ??
                      blueprint?.title ??
                      "タイトルが存在しません")
                    )
                  }
                  secondary={
                    isOrderHistory &&
                    (handleCancelOrder && orders && orders.length > 0 ? (
                      // 注文申請中で発注するボタンを押すまでは注文を取り消すことができる
                      orders.every((it) => it.is_active) ? (
                        <Button
                          variant="outlined"
                          onClick={() => handleCancelOrder(item.id)}
                          sx={{
                            borderRadius: "30px",
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            border: "2px solid #000000",
                            padding: "8px 16px",
                            fontSize: "1rem",
                            transition: "all 0.3s",
                            "&:hover": {
                              backgroundColor: "#f5f5f5",
                              borderColor: "#333333",
                            },
                          }}
                        >
                          注文を取り消す
                        </Button>
                      ) : (
                        <Typography>契約済み</Typography>
                      )
                    ) : (
                      <Typography>注文情報なし</Typography>
                    ))
                  }
                />
                {/* ユーザーセクション */}
                {isOrderHistory && (
                  <Box sx={{ mb: 4 }}>
                    {/* 契約しているユーザー */}
                    {isContractingUser && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          契約しているユーザー
                        </Typography>
                        <UserInfo
                          key={isContractingUser.user_id}
                          user={isContractingUser}
                          status="契約中"
                        />
                      </Box>
                    )}

                    {/* 受注したユーザー */}
                    {isReceivingUsers.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          受注したユーザー
                        </Typography>
                        {isReceivingUsers.map((user) => (
                          <UserInfo
                            key={user.user_id}
                            user={user}
                            status="受注中"
                          />
                        ))}
                      </Box>
                    )}

                    {/* 保留中のユーザー */}
                    {isPendingUsers.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          保留中のユーザー
                        </Typography>
                        {isPendingUsers.map((user) => (
                          <UserInfo
                            key={user.user_id}
                            user={user}
                            status="保留中"
                          />
                        ))}
                      </Box>
                    )}

                    {/* 拒否したユーザー */}
                    {isDenyingUsers.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          拒否したユーザー
                        </Typography>
                        {isDenyingUsers.map((user) => (
                          <UserInfo
                            key={user.user_id}
                            user={user}
                            status="拒否済み"
                          />
                        ))}
                      </Box>
                    )}

                    {/* 申請中のユーザー */}
                    {isApplyingUsers.length > 0 && (
                      <Box sx={{ mb: 2 }}>
                        <Typography variant="h6" gutterBottom>
                          申請中のユーザー
                        </Typography>
                        {isApplyingUsers.map((user) => (
                          <UserInfo
                            key={user.user_id}
                            user={user}
                            status="申請中"
                          />
                        ))}
                      </Box>
                    )}

                    {/* すべてのユーザーが表示された場合の注意 */}
                    {!isContractingUser &&
                      [
                        isReceivingUsers,
                        isPendingUsers,
                        isDenyingUsers,
                        isApplyingUsers,
                      ].every((list) => list.length === 0) && (
                        <Typography variant="body1">
                          ユーザーが存在しません。
                        </Typography>
                      )}
                  </Box>
                )}
                <Box
                  sx={{
                    width: isSmallScreen ? "70%" : "55%",
                  }}
                >
                  {/* 詳細・ファイルダウンロードのセクション */}
                  {isOrderHistory ? (
                    <Box
                      sx={{
                        backgroundColor: "#e0e0e0",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "4px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      <DetailSection
                        order={orders?.find((it) => it.is_received)}
                        wood={wood}
                        log={log}
                        blueprint={blueprint}
                        setOrder={setOrder}
                        users={users}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          mr: 1,
                          color: "black", // 文字を黒色に
                          borderColor: "black", // 枠線を黒色に
                          backgroundColor: "white", // 背景を白色に
                          width: "35%",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)", // ホバー時の背景色を少し灰色に
                            borderColor: "black",
                          },
                        }}
                        onClick={() =>
                          setOrder({
                            users: [],
                            item: {
                              wood,
                              log,
                              blueprint,
                            },
                          })
                        }
                      >
                        詳細確認
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          mr: 1,
                          color: "red",
                          borderColor: "red",
                          backgroundColor: "white",
                          width: "35%",
                          "&:hover": {
                            backgroundColor: "rgba(255, 0, 0, 0.1)", // ホバー時の背景色を少し赤っぽく
                            borderColor: "red",
                          },
                        }}
                        onClick={() =>
                          navigate(`/order/create_${itemName}_list/${item.id}`)
                        }
                      >
                        作成を再開
                      </Button>
                    </Box>
                  )}
                </Box>
              </ListItem>
            );
          })}
        {data && data.totalPages > 1 && (
          <PaginationComponent
            totalPages={data.totalPages}
            page={page}
            handlePageChange={handlePageChange}
            isSmallScreen={isSmallScreen}
          />
        )}
      </List>

      {order !== null && (
        <OrderDetailModal
          userItem={order}
          open={order !== null}
          onClose={() => setOrder(null)}
          isUserShown={isOrderHistory}
        />
      )}
    </Box>
  );
}
