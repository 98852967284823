import React from "react";
import { Box, Typography } from "@mui/material";
import { Color } from "../../utils/color";

interface HeaderProps {
  title: string;
}

export const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between", // 左右の要素を両端に配置
        alignItems: "center", // 垂直方向に中央揃え
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        position: "relative",
        zIndex: "10",
        height: "7vh",
        padding: "0 1rem", // 左右に余白を追加
      }}
    >
      <Typography sx={{ fontSize: "1.5rem", color: Color.Main }}>
        {title}
      </Typography>
    </Box>
  );
};
