import { useQuery } from "@tanstack/react-query";
import { PAGE_SIZE } from "../utils/constant";
import { OrderStatusWithSearchParams } from "../utils/types/general_type";
import { useAuth } from "./useAuth";
import config from "../config";
import { handleResponse } from "../utils/response";
import { Woods } from "./Wood/Wood/type";
import { Logs } from "./Log/Log/type";
import { GetBlueprints } from "./Blueprint/Blueprint/type";
import { itemKeys } from "../utils/query-key";

export interface ReadonlyResponseItems {
  items: Woods | Logs | GetBlueprints;
  totalPages: number;
}

export function useGetItems({
  itemName,
  isTemporarilyStored,
  isOrdered,
  page,
  pageSize = PAGE_SIZE,
}: OrderStatusWithSearchParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.items_by_filter({
      itemName,
      isTemporarilyStored,
      isOrdered,
      page,
      pageSize,
    }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
        page: page.toString(),
        pageSize: pageSize.toString(),
      } as const;

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/${itemName}s?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseItems>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
