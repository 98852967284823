export const extractFileName = (path?: string): string => {
  if (!path) {
    return "ファイルが存在しません";
  }
  const segments = path.split("/");
  return segments[segments.length - 1]; // 最後の要素がファイル名
};

export function getFileExtension(url: string): string {
  const parsedUrl = new URL(url);
  const pathname = parsedUrl.pathname;
  const lastDot = pathname.lastIndexOf(".");
  if (lastDot === -1) return "";
  return pathname.substring(lastDot);
}
