import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import { Error } from "../../uis/Error";
import { useEffect, useState } from "react";
import { ReadonlyNotificationResponse } from "../../hooks/useNotificationHook";
import { PaginationComponent } from "../../uis/Pagination";
import { useSearchParams } from "react-router-dom";
import { UseQueryResult } from "@tanstack/react-query";
import { SearchTextWIthPaginationParams } from "../../utils/types/general_type";

interface NotificationListProps {
  useGetNotifications: ({
    searchText,
    page,
    pageSize,
  }: SearchTextWIthPaginationParams) => UseQueryResult<
    ReadonlyNotificationResponse,
    Error
  >;
  isHome?: boolean;
}

export const NotificationList = ({
  useGetNotifications,
  isHome = true,
}: NotificationListProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  // useSearchParamsを使用してクエリパラメータを管理
  const [searchParams, setSearchParams] = useSearchParams();

  // URLから初期ページを取得、存在しない場合は1
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const initialSearchText = searchParams.get("search") || "";
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [page, setPage] = useState<number>(
    isNaN(initialPage) || initialPage < 1 ? 1 : initialPage,
  );

  const { data, error: notificationsError } = useGetNotifications({
    searchText,
    page,
  });
  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    setPage(1); // 検索テキストが変更されたらページをリセット

    // クエリパラメータを更新: search を新しい検索テキストに、page を1にリセット
    setSearchParams({
      ...Object.fromEntries(searchParams),
      search: newSearchText,
      page: "1",
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    // クエリパラメータを更新
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: value.toString(),
    });
  };

  // クエリパラメータが変更された際に page と searchText を更新
  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    const currentSearchText = searchParams.get("search") || "";

    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }

    if (currentSearchText !== searchText) {
      setSearchText(currentSearchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  // エラー時の処理
  if (notificationsError) {
    return <Error message={notificationsError?.message} />;
  }

  return (
    <Box sx={{ width: "90%" }}>
      {/* 4: お知らせを検索ボックス */}
      <Box sx={{ width: "90%" }}>
        <SearchBox
          isSmallScreen
          placeholder="お知らせを検索(会社名・タイトル)"
          onClick={handleSearchBoxClick}
          onChange={handleSearchBoxChange}
          value={searchText}
          searchClicked={searchClicked} // ここで渡す
        />
      </Box>
      {/* 5: 会社一覧 */}
      <Box
        sx={{
          width: "100%",
          marginTop: isSmallScreen ? 1 : 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {data && data.notifications && data.notifications.length > 0 ? (
          data.notifications.map((notification) => (
            <Box
              key={notification.id}
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: isSmallScreen ? 1 : 2,
                padding: isSmallScreen ? 1 : 2,
                border: "1px solid lightgray",
                borderRadius: "8px",
              }}
            >
              {/* 会社アイコン */}
              <Avatar user={notification.user} isSmallScreen={isSmallScreen} />
              {/* 会社名とお知らせ内容 */}
              <Box>
                <Typography variant={isSmallScreen ? "body1" : "h6"}>
                  {notification.user.company_name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {notification.title}
                </Typography>
              </Box>
            </Box>
          ))
        ) : isHome ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "200px",
              border: "1px dashed lightgray",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              textAlign: "center",
              padding: 2,
            }}
          >
            <Typography variant="h6" color="textPrimary">
              お知らせが存在しません。
            </Typography>
            <Typography variant="body2" color="textSecondary">
              新しいお知らせが追加されるとここに表示されます。
            </Typography>
          </Box>
        ) : null}
        {/* ページネーション */}
        {data && data.totalPages > 1 && (
          <PaginationComponent
            totalPages={data.totalPages}
            page={page}
            handlePageChange={handlePageChange}
            isSmallScreen={isSmallScreen}
          />
        )}
      </Box>
    </Box>
  );
};
