export const THIRTY_SECONDS = 30 * 1000;
export const FIVE_MINUTES = 5 * 60 * 1000;
export const TEN_MINUTES = 10 * 60 * 1000;
export const THIRTY_MINUTES = 30 * 60 * 1000;

export const SEARCH_BOX_ITEMS = 5;
export const PAGE_SIZE = 20;
export const ORDER_BASE_PATH = "/order";
export const MANAGEMENT_DOCUMENT_PATH = "/management/document";
export const MANAGEMENT_MONEY_PATH = "/management/money";
