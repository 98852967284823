import React from "react";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { usePayStripe } from "../../hooks/useStripeHook";
import config from "../../config";
import { Button, Dialog, DialogContent } from "@mui/material";
import { ItemType } from "../../utils/types/item_type";
import { Loading } from "../Loading";
import { Error as CustomError } from "../Error";
import { useGetSetupIntent } from "../../hooks/usePaymentHook";

if (!config.stripePublicKey) {
  throw new Error("REACT_APP_STRIPE_PUBLIC_KEY is not set");
}
const stripePromise = loadStripe(config.stripePublicKey); // 公開可能キーを使用

interface StripeDialogProps {
  open: boolean;
  onClose: () => void;
  type: ItemType;
  orderId: number;
}

const InnerStripeDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { mutateAsync: mutateStripeAsync, isPending } = usePayStripe();
  const handlePayment = async () => {
    try {
      await mutateStripeAsync();
      onClose(); // 支払い成功後にダイアログを閉じる
    } catch (error) {
      console.error(error);
      // エラーハンドリングを追加することも検討してください
    }
  };

  return (
    <>
      <PaymentElement />
      <Button
        variant="outlined"
        color="secondary"
        onClick={handlePayment}
        disabled={isPending}
        sx={{ mt: 1 }}
      >
        支払いをする
      </Button>
    </>
  );
};

export const StripeDialog = ({
  open,
  onClose,
  type,
  orderId,
}: StripeDialogProps) => {
  const { data, error, isPending } = useGetSetupIntent({ type, orderId });
  if (isPending) {
    return <Loading />;
  }

  if (error) {
    return <CustomError message={error?.message} />;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: data.setupIntent.client_secret as string }}
        >
          <InnerStripeDialog onClose={onClose} />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};
