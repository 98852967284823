import { Button } from "@mui/material";
import { Color } from "../../utils/color";

interface BlackButtonProps {
  isSmallScreen: boolean;
  text: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}

export const BlackButton = ({
  isSmallScreen,
  text,
  onClick,
  type,
  disabled = false,
}: BlackButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "black",
        color: "white",
        borderRadius: "8px",
        marginTop: isSmallScreen ? 1 : 2,
        padding: isSmallScreen ? "8px 16px" : "10px 20px",
        fontSize: isSmallScreen ? "0.8rem" : "1rem",
        width: "100%",
      }}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

interface WhiteButtonProps {
  isSmallScreen: boolean;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const WhiteButton = ({
  isSmallScreen,
  text,
  onClick,
  disabled = false,
}: WhiteButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: "white",
        color: "black",
        borderColor: "black",
        borderRadius: "8px",
        marginTop: isSmallScreen ? 1 : 2,
        padding: isSmallScreen ? "8px 16px" : "10px 20px",
        fontSize: isSmallScreen ? "0.8rem" : "1rem",
        width: "100%",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

interface ChangeButtonProps {
  isSmallScreen: boolean;
  text: string;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
}
export const ChangeButton = ({
  isSmallScreen,
  text,
  isActive,
  onClick,
  disabled = false,
}: ChangeButtonProps) => {
  return (
    <Button
      variant="outlined"
      sx={{
        backgroundColor: isActive ? Color.Main : "white",
        color: isActive ? "white" : "black",
        borderColor: isActive ? "none" : "black",
        borderRadius: "8px",
        marginTop: isSmallScreen ? 1 : 2,
        padding: isSmallScreen ? "8px 16px" : "16px 24px",
        fontSize: isSmallScreen ? "1rem" : "1.2rem",
        width: "100%",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};
