import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import FormField from "../../../uis/Forms/FormField";
import { useForm } from "react-hook-form";
import {
  ChangePassword,
  useUpdatePassword,
} from "../../../hooks/usePasswordHook";

export const PasswordForm: React.FC = () => {
  const [showPasswordForm, setShowPasswordForm] = useState(false); // 管理者用パスワードフォームの表示
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
  } = useForm<ChangePassword>();
  const { mutateAsync } = useUpdatePassword();

  const onSubmit = async (data: ChangePassword) => {
    try {
      await mutateAsync(data);
      setShowPasswordForm(false);
    } catch (error) {
      console.error(error);
    }
  };

  const togglePasswordForm = () => {
    setShowPasswordForm((prev) => !prev);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "2rem 0",
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={togglePasswordForm}
        >
          管理者用パスワードを編集
        </Button>
      </Box>
      {/* 管理者用パスワード入力フォーム */}
      {showPasswordForm && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <FormField<ChangePassword>
            placeholder="現在の管理者用パスワードを入力してください"
            register={register}
            name="oldPassword"
            isError={!!errors.oldPassword}
            errorMessage={errors.oldPassword?.message}
            labelName="現在の管理者用パスワードを入力してください"
            sx={{ marginBottom: "1rem" }}
          />
          <FormField<ChangePassword>
            placeholder="現在の管理者用パスワードを入力してください"
            register={register}
            name="newPassword"
            isError={!!errors.newPassword}
            errorMessage={errors.newPassword?.message}
            labelName="新たな管理者用パスワードを入力してください"
            sx={{ marginBottom: "1rem" }}
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting}
              sx={{
                background: "black",
                color: "white",
                border: "none",
                width: "100%",
                height: "3.5rem",
              }}
            >
              確認
            </Button>
          </Box>
        </form>
      )}
    </>
  );
};
