import { useGetMyNotifications } from "../../hooks/useNotificationHook";
import { NotificationList } from "../common/NotificationList";

export const MyNotifications = () => {
  return (
    <NotificationList
      useGetNotifications={useGetMyNotifications}
      isHome={false}
    />
  );
};
