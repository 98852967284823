import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { SignInSchema, SignInType } from "../../utils/schema";
import { useForm } from "react-hook-form";
import { validateData } from "../../utils/validation";
import { TextFieldForm } from "../../uis/TextField/TextFieldForm";
import { Color } from "../../utils/color";

export const SignIn = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<SignInType>();

  const onSubmit = async (data: SignInType) => {
    const validationResponse = validateData(data, SignInSchema);
    if (validationResponse.success) {
      try {
        const result = await auth.signIn(data);
        if (result.success) {
          navigate(`/home`);
        } else {
          console.error(result.message);
          setErrorMessage(`${result.message}`);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("ログインに失敗しました");
      }
    } else {
      setErrorMessage(validationResponse.errors[0]);
    }
  };

  return (
    <Box
      sx={{
        width: "80%", // 残りの80%をメインコンテンツに割り当て
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("/background.png")', // バックグラウンド画像の設定
      }}
    >
      <Box
        sx={{
          width: "50%", // メインコンテンツの50%の横幅
          backgroundColor: "#F2F2F2",
          padding: "40px",
          borderRadius: "8px", // 角を少し丸める
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // 影を追加して浮き上がり感を出す
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          ログイン
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {errorMessage && (
              <Alert severity="error" sx={{ color: "red" }}>
                {errorMessage}
              </Alert>
            )}
            {/* メールアドレス */}
            <TextFieldForm
              name="email"
              control={control}
              errors={errors}
              labelName="担当者メールアドレス"
              type="email"
              rules={{
                required: "メールアドレスは必須です",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "正しいメールアドレスの形式で入力してください",
                },
              }}
            />
            {/* パスワード */}
            <TextFieldForm
              name="password"
              control={control}
              errors={errors}
              labelName="パスワード"
              type="password"
              rules={{
                required: "パスワードは必須です",
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上でなければなりません",
                },
              }}
            />
            {/* ログインボタン */}
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{
                backgroundColor: Color.Main,
                color: "#FFFFFF",
                height: "50px",
                "&:hover": {
                  backgroundColor: "#006f3d", // ホバー時に少し明るく
                },
              }}
            >
              {isSubmitting ? "ログイン中..." : "ログイン"}
            </Button>
            {/* パスワードリセットリンク */}
            <Box textAlign="center">
              <Link
                to={`/password_reset`}
                style={{
                  color: Color.Main, // テキスト色を#004831に設定
                  fontSize: "14px",
                  textDecoration: "none",
                }}
              >
                パスワードを忘れた方はこちら
              </Link>
            </Box>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};
