import { BlueprintDetail } from "../hooks/Blueprint/Blueprint/type";
import { LogDetail } from "../hooks/Log/Log/type";
import { WoodDetail } from "../hooks/Wood/Wood/type";
import { PicSameDetailProperties } from "./types/item_type";

const defaultRow: PicSameDetailProperties = {
  origin: "",
  material: "",
  species: "",
  grade: "",
  length: 0,
  unit_volume: 0,
  quantity: 0,
  total_volume: 0,
};

export const defaultWoodRow: WoodDetail = {
  ...defaultRow,
  id: 0,
  name: "",
  width: 0,
  height: 0,
};

export const defaultLogRow: LogDetail = {
  ...defaultRow,
  top_diameter: 0,
};

export const defaultBlueprintRow: BlueprintDetail = {
  ...defaultRow,
  name: "",
  width: 0,
  height: 0,
  amount: 0,
};
