import { useQuery } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { User } from "./useUserHook";
import { orderMessageRoomKeys } from "../utils/query-key";
import { MessageBase } from "./Message/useMessageHook";
import { MessageRoomBase } from "./Message/useMessageRoomHook";
import { SearchTextWIthPaginationParams } from "../utils/types/general_type";
import { FIVE_MINUTES, PAGE_SIZE } from "../utils/constant";
import { LogOrder } from "./Log/useLogOrderHook";
import { WoodOrder } from "./Wood/useWoodOrderHook";
import { BlueprintOrder } from "./Blueprint/useBlueprintOrderHook";
import { ItemType, OrderMessageRoomType } from "../utils/types/item_type";
import { UUID } from "crypto";

export interface LogMessage extends MessageBase {
  log_order_message_room_id: number;
}
export interface WoodMessage extends MessageBase {
  wood_order_message_room_id: number;
}

export interface BlueprintMessage extends MessageBase {
  blueprint_order_message_room_id: number;
}

interface ItemMessageMap {
  log: LogMessage;
  wood: WoodMessage;
  blueprint: BlueprintMessage;
}

export interface ItemOrderMessageRoomMap {
  log: LogOrderMessageRoom;
  wood: WoodOrderMessageRoom;
  blueprint: BlueprintOrderMessageRoom;
}

type ItemMessage<T extends ItemType> = ItemMessageMap[T];

interface BaseOrderMessageRoom<T extends ItemType> extends MessageRoomBase {
  order_id: UUID;
  receiver_id: UUID;
  messages: ItemMessage<T>[];
  order: User;
  receiver: User;
}

// 特定の注文メッセージルームインターフェース
export interface LogOrderMessageRoom extends BaseOrderMessageRoom<"log"> {
  log_order: LogOrder;
}

export interface WoodOrderMessageRoom extends BaseOrderMessageRoom<"wood"> {
  wood_order: WoodOrder;
}

export interface BlueprintOrderMessageRoom
  extends BaseOrderMessageRoom<"blueprint"> {
  blueprint_order: BlueprintOrder;
}

interface OrderMessageRoom {
  id: number;
  user: User;
  message?: {
    content: string;
    createdAt: Date;
  };
  title: string;
  unreadCount: number;
  type: ItemType;
}

export type ReadonlyOrderMessageRoom<T extends ItemType> = Readonly<
  ItemOrderMessageRoomMap[T]
>;

export function useGetOrderMessageRoom({
  messageRoomId,
  type,
}: OrderMessageRoomType) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderMessageRoomKeys.orderMessageRoom(messageRoomId),
    queryFn: async () => {
      const { token } = await getAccessToken();
      if (!type) {
        throw new Error("Type is required");
      }
      const response = await fetch(
        `${config.backendUrl}/api/${type}_order_message_room/${messageRoomId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        ReadonlyOrderMessageRoom<typeof type>
      >;
    },
    enabled: !!type,
  });
}

interface OrderMessageRoomResponse {
  orderMessageRooms: ReadonlyArray<OrderMessageRoom>;
  totalPages: number;
}
export function useSearchOrderMessageRooms({
  searchText,
  page,
  pageSize = PAGE_SIZE,
}: SearchTextWIthPaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: orderMessageRoomKeys.search({ searchText, page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/order_message_rooms/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<
        Readonly<OrderMessageRoomResponse>
      >;
    },
    staleTime: FIVE_MINUTES,
  });
}
