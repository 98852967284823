import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Error } from "../../uis/Error";
import { SearchBox } from "../../uis/TextField/SearchBox";
import Avatar from "../../uis/Avatar";
import { OrderStatusWithApplying } from "../../utils/types/general_type";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { ItemType, UsersItem } from "../../utils/types/item_type";
import { toast } from "react-toastify";
import { isBlueprint, isLog, isWood } from "./MailingComponent";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { LogOrder } from "../../hooks/Log/useLogOrderHook";
import { WoodOrder } from "../../hooks/Wood/useWoodOrderHook";
import { BlueprintOrder } from "../../hooks/Blueprint/useBlueprintOrderHook";
import { Color } from "../../utils/color";
import { DetailSection } from "../../uis/DetailsSection";
import { FileDownloadComponent } from "../../uis/File/FileDownloadComponent";
import { extractOrders } from "../../utils/extract";
import { Title } from "../../uis/Title";
import { conversionItemName } from "../../utils/conversion";
import {
  useApplyForOrderAgain,
  useContractOrder,
  useSearchOrderStatuses,
} from "../../hooks/useOrderHook";
import { PaginationComponent } from "../../uis/Pagination";

interface OrderComponentProps {
  itemName: ItemType;
}

/**
 * 要求仕様まとめ：
 * 1. itemごとの囲みをグレー枠で囲み、上部左にitem.titleを表示し、右側にプラス/マイナスボタンで開閉状態を示す。
 *    タイトルは開閉どちらでも表示する。開いている場合はタイトル文字色白、背景緑、閉じている場合は文字色黒、背景薄いグレー。
 * 2. order状態表示（承諾、保留中、辞退、申請中）は左に寄せ、上下中央。各状態に応じて色分けする。
 *    - 承諾: 赤背景＋白文字
 *    - 保留中: 白背景＋赤文字＋赤枠線
 *    - 辞退: グレー背景＋白文字
 *    - 申請中: 白背景＋グレー文字＋グレー枠線
 * 3. {order.is_received ? "承諾" : ...}部分をListItem内で左配置、垂直方向中央。
 * 4. 「詳細を確認する」部分とFileDownload部分を一つのまとまり（グレー背景）にし、
 *    「詳細を確認する」の下に「詳細確認」というボタンを配置し、それらを黒い縦線で区切る。
 *    このまとまりはListItem内部の右側に配置し、上下中央揃えにする。
 * 5. 状態が保留、辞退の場合は「再度注文する」ボタン（白文字・黒背景）を表示。
 *    状態が承諾の場合は「契約する」ボタンではなく「発注する」ボタン（白文字・赤背景）。
 *    状態が申請中の場合は「契約する」ボタン（元々のボタン名）を表示。
 */
export const OrderComponent = ({ itemName }: OrderComponentProps) => {
  // useSearchParamsを使用してクエリパラメータを管理
  const [searchParams, setSearchParams] = useSearchParams();
  // URLから初期ページを取得、存在しない場合は1
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const initialSearchText = searchParams.get("search") || "";
  const initialStatuses = searchParams.get("statuses") || "";
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [page, setPage] = useState<number>(
    isNaN(initialPage) || initialPage < 1 ? 1 : initialPage,
  );
  const [searchClicked, setSearchClicked] = useState(false);
  const [order, setOrder] = useState<UsersItem | null>(null);
  const [statuses, setStatuses] = useState<OrderStatusWithApplying[]>(
    initialStatuses
      ? (initialStatuses.split(",") as OrderStatusWithApplying[])
      : [],
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error } = useSearchOrderStatuses({
    type: itemName,
    searchText,
    statuses,
    page,
  });
  const { mutateAsync, isPending } = useApplyForOrderAgain();
  const { mutateAsync: mutateContractAsync, isPending: isContractPending } =
    useContractOrder();

  // 開閉状態を管理するためのstate
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({});

  const handleToggle = (id: number) => {
    setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev);
  };

  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    setPage(1); // 検索テキストが変更されたらページをリセット

    // クエリパラメータを更新: search を新しい検索テキストに、page を1にリセット
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("search", newSearchText);
      params.set("page", "1");
      return params;
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    // クエリパラメータを更新
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("page", value.toString());
      return params;
    });
  };

  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    const currentSearchText = searchParams.get("search") || "";
    const currentStatusesParam = searchParams.get("statuses") || "";

    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }

    if (currentSearchText !== searchText) {
      setSearchText(currentSearchText);
    }
    if (currentStatusesParam) {
      setStatuses(currentStatusesParam.split(",") as OrderStatusWithApplying[]);
    } else {
      setStatuses([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleClick = (type: OrderStatusWithApplying) => {
    setStatuses((prevStatuses) => {
      let updatedStatuses: OrderStatusWithApplying[];
      if (prevStatuses.includes(type)) {
        updatedStatuses = prevStatuses.filter((status) => status !== type);
      } else {
        updatedStatuses = [...prevStatuses, type];
      }

      // クエリパラメータを更新
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        if (updatedStatuses.length > 0) {
          params.set("statuses", updatedStatuses.join(","));
        } else {
          params.delete("statuses");
        }
        return {
          ...Object.fromEntries(params),
          page: "1",
        };
      });

      return updatedStatuses;
    });
  };

  const handleApplyForOrderAgain = async (
    orderId: number,
    isContract: boolean,
    itemId?: number,
  ) => {
    if (!itemId) {
      toast.error("エラーが発生しました");
      return;
    }
    try {
      if (isContract) {
        await mutateContractAsync({ type: itemName, orderId, itemId });
      } else {
        await mutateAsync({ type: itemName, orderId });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (error) {
    return <Error message={error?.message} />;
  }

  const getOrderStatusStyle = (
    order: LogOrder | WoodOrder | BlueprintOrder,
  ) => {
    // 共通のCSSスタイルを定義
    const baseSx = {
      padding: "4px 8px",
      borderRadius: "4px",
      display: "inline-block",
    };

    // 状態に応じたCSSを追加
    if (order.is_received) {
      // 承諾
      return {
        ...baseSx,
        backgroundColor: "red",
        color: "white",
      };
    } else if (order.is_pending) {
      // 保留中
      return {
        ...baseSx,
        backgroundColor: "white",
        color: "red",
        border: "1px solid red",
      };
    } else if (order.is_denied) {
      // 辞退
      return {
        ...baseSx,
        backgroundColor: "gray",
        color: "white",
      };
    } else {
      // 申請中
      return {
        ...baseSx,
        backgroundColor: "white",
        color: "gray",
        border: "1px solid gray",
      };
    }
  };

  const getOrderActionButton = (
    order: LogOrder | WoodOrder | BlueprintOrder,
    itemId: number | undefined,
  ) => {
    // 状態に応じたボタン（契約する/発注する/再度注文する）
    // 承諾 -> 発注する
    let buttonText = "発注する";
    let buttonStyle = {
      backgroundColor: "red",
      color: "white",
      marginRight: "8px",
    };
    let isContract = true;
    let isDisabled = false;
    // 2度注文を保留・辞退したユーザー
    if (
      (order.is_denied_sended || order.is_pending_sended) &&
      !order.is_received
    ) {
      // 保留・辞退 -> 再度注文する
      buttonText = "再度保留・辞退しました";
      buttonStyle = {
        backgroundColor: "black",
        color: "white",
        marginRight: "8px",
      };
      isContract = false;
      isDisabled = true;
    } else if (order.is_pending || order.is_denied) {
      // 保留・辞退 -> 再度注文する
      buttonText = "再度注文する";
      buttonStyle = {
        backgroundColor: "black",
        color: "white",
        marginRight: "8px",
      };
      isContract = false;
      isDisabled = false;
    }

    return (
      <Button
        variant="contained"
        size="small"
        sx={buttonStyle}
        onClick={() => handleApplyForOrderAgain(order.id, isContract, itemId)}
        disabled={isPending || isContractPending || isDisabled}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <Box
      sx={{
        width: "55%",
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Title title={`${conversionItemName(itemName)}リスト　注文申請中`} />
      <SearchBox
        placeholder="タイトルを検索"
        isSmallScreen={isSmallScreen}
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked}
      />
      <Box sx={{ mb: 2 }}>
        <Button onClick={() => handleClick("applying")}>
          {statuses.includes("applying") ? "申請中を取り消す" : "申請中"}
        </Button>
        <Button onClick={() => handleClick("receiving")}>
          {statuses.includes("receiving") ? "承諾を取り消す" : "承諾"}
        </Button>
        <Button onClick={() => handleClick("pending")}>
          {statuses.includes("pending") ? "保留を取り消す" : "保留"}
        </Button>
        <Button onClick={() => handleClick("denying")}>
          {statuses.includes("denying") ? "辞退を取り消す" : "辞退"}
        </Button>
      </Box>
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.items.length > 0 &&
          data.items.map((item) => {
            const log = isLog(item) ? item : undefined;
            const wood = isWood(item) ? item : undefined;
            const blueprint = isBlueprint(item) ? item : undefined;

            const orders = extractOrders({ wood, log, blueprint });

            const totalAmount = item?.total_amount;
            const open = openItems[item?.id || 0] || false;

            return (
              <List
                key={item.id}
                sx={{
                  border: "1px solid gray",
                  borderRadius: "8px",
                  mb: 2,
                  paddingTop: 0,
                  paddingBottom: 0,
                }}
              >
                <ListItem
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    borderBottomRightRadius: open ? 0 : "8px",
                    borderBottomLeftRadius: open ? 0 : "8px",
                    background: open ? Color.Main : "#f0f0f0",
                    mb: open && orders && orders.length > 0 ? 2 : 0,
                  }}
                >
                  {/* タイトル表示部（左上） */}
                  <Box
                    sx={{
                      color: open ? "white" : "black",
                      padding: "4px 8px",
                      borderRadius: "4px",
                      fontWeight: "bold",
                    }}
                  >
                    {item?.title || "タイトルが存在しません"}
                  </Box>
                  {/* 開閉ボタン（右上） */}
                  <IconButton onClick={() => handleToggle(item.id)}>
                    {open ? (
                      <ExpandLess sx={{ color: open ? "white" : "black" }} />
                    ) : (
                      <ExpandMore sx={{ color: open ? "white" : "black" }} />
                    )}
                  </IconButton>
                </ListItem>
                {open && orders && orders.length > 0 && (
                  <Box sx={{ pl: 1, pr: 1 }}>
                    {orders.map((order) => (
                      <ListItem
                        key={order.id}
                        alignItems="center"
                        sx={{
                          display: "flex",
                          flexDirection: isSmallScreen ? "column" : "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 2,
                        }}
                      >
                        <Box width={{ width: "10%" }}>
                          <Typography sx={getOrderStatusStyle(order)}>
                            {order.is_received
                              ? "承諾"
                              : order.is_pending
                                ? "保留中"
                                : order.is_denied
                                  ? "辞退"
                                  : "申請中"}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: isSmallScreen ? "100%" : "45%",
                            mb: isSmallScreen ? 1 : 0,
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar
                              user={order.user}
                              isSmallScreen={isSmallScreen}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={order.user.company_name}
                            secondary={
                              <Box>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  見積もり金額（合計）: ¥
                                  {totalAmount && totalAmount !== null
                                    ? totalAmount.toLocaleString()
                                    : "データが存在しません"}
                                </Typography>
                                {/* 契約する/発注する/再度注文するボタン */}
                                {order.is_received ||
                                order.is_pending ||
                                order.is_denied
                                  ? getOrderActionButton(order, item?.id)
                                  : null}
                              </Box>
                            }
                            sx={{ ml: 2 }}
                          />
                        </Box>

                        <Box></Box>

                        {/* 詳細や契約・発注、再度注文するボタンなど（右側、上下中央） */}
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: isSmallScreen ? "100%" : "45%",
                          }}
                        >
                          {/* 詳細・ファイルダウンロードのセクション */}
                          <Box
                            sx={{
                              backgroundColor: "#e0e0e0",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              padding: "4px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            <DetailSection
                              order={order}
                              wood={wood}
                              log={log}
                              blueprint={blueprint}
                              setOrder={setOrder}
                              users={[order.user]}
                            />
                            {!order?.is_pending &&
                            !order?.is_denied &&
                            order?.is_received ? (
                              <FileDownloadComponent
                                order={order}
                                wood={wood}
                                log={log}
                                blueprint={blueprint}
                              />
                            ) : null}
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                  </Box>
                )}
              </List>
            );
          })}
        {/* ページネーション */}
        {data && data.totalPages > 1 && (
          <PaginationComponent
            totalPages={data.totalPages}
            page={page}
            handlePageChange={handlePageChange}
            isSmallScreen={isSmallScreen}
          />
        )}
      </List>
      {order !== null && (
        <OrderDetailModal
          userItem={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
