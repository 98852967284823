import { List, Box, Typography, Button, Divider } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Color } from "../../utils/color";

interface MenuItemType {
  title: string;
  items: ReadonlyArray<{
    text: string;
    link: string;
  }>;
}

interface MenuProps<T extends MenuItemType> {
  menuItems: ReadonlyArray<T>; // ReadonlyArrayを使って配列全体をreadonlyにする
  handleClick: (link: T["items"][number]["link"]) => Promise<void>; // link の型を menuItems の link 型に合わせる
  disabled: boolean;
}

export const MenuItemComponent = <T extends MenuItemType>({
  menuItems,
  handleClick,
  disabled,
}: MenuProps<T>) => {
  const location = useLocation();

  return (
    <Box
      sx={{
        backgroundColor: "#f0f0f0", // グレーの背景色
        minHeight: "100vh", // 画面全体をカバー
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        padding: "1rem",
      }}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 600,
          backgroundColor: "#ffffff", // 白色の背景
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        {menuItems.map((menuItem, index) => (
          <Box key={index}>
            <Box sx={{ width: "90%", margin: "0 auto" }}>
              {/* タイトル */}
              <Typography
                variant="h6"
                sx={{
                  color: Color.Main,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.5rem",
                }}
              >
                ・{menuItem.title}
              </Typography>
              {/* メニューアイテム */}
              {menuItem.items.map((item, idx) => (
                <Button
                  key={idx}
                  variant="contained"
                  sx={{
                    backgroundColor: location.pathname.includes(item.link)
                      ? Color.Main // 現在のリンクと一致した場合は緑色
                      : "#000000", // 一致しない場合は黒色
                    color: "#ffffff", // 白色の文字
                    textTransform: "none",
                    width: "100%",
                    justifyContent: "space-between",
                    padding: "0.75rem 1rem",
                    marginBottom: "0.5rem",
                    borderRadius: "50px",
                    "&:hover": {
                      backgroundColor: "#333333",
                    },
                  }}
                  onClick={() => handleClick(item.link)}
                  disabled={disabled}
                >
                  {item.text}
                  ▶︎
                </Button>
              ))}
            </Box>
            {/* メニュー項目間の仕切り線 */}
            {index < menuItems.length - 1 && (
              <Divider
                sx={{
                  backgroundColor: "#d3d3d3", // グレーの線
                  marginY: "1rem",
                }}
              />
            )}
          </Box>
        ))}
      </List>
    </Box>
  );
};
