import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../config";
import { useAuth } from "./useAuth";
import { handleResponse } from "../utils/response";
import { toast } from "react-toastify";
import { User } from "./useUserHook";
import { notificationKeys } from "../utils/query-key";
import { PAGE_SIZE, SEARCH_BOX_ITEMS, THIRTY_SECONDS } from "../utils/constant";
import {
  SearchIdRequest,
  ReadonlyResponse,
  SearchTextWIthPaginationParams,
} from "../utils/types/general_type";
import { UUID } from "crypto";

export interface Notification {
  id: number;
  user_id: UUID;
  title?: string;
  content?: string;
  image_path?: string;
  is_temporarily_stored: boolean;
  is_published: boolean;
  created_at: Date;
  user: User;
}

interface NotificationResponse {
  message: string;
  notification_id: number;
}

type ReadonlyNotification = Readonly<Notification>;

type Notifications = ReadonlyArray<Notification>;

export function useCreateNotification() {
  const { getAccessToken } = useAuth();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/notification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<
        Readonly<NotificationResponse>
      >;
    },
  });
}

export function useUpdateNotification(searchText: string) {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Notification) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/notification`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          title: data.title,
          content: data.content,
          image_path: data.image_path,
          is_temporarily_stored: data.is_temporarily_stored,
          is_published: data.is_published,
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_published) {
        toast.success("お知らせを投稿しました");
      }
      if (variables.is_temporarily_stored) {
        toast.success("お知らせを一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: notificationKeys.notification(variables.id),
      });
      // 検索結果の再フェッチ
      await queryClient.invalidateQueries({
        queryKey: notificationKeys.search(searchText, variables.id),
      });
    },
  });
}

export function useGetNotification(notificationId: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: notificationKeys.notification(notificationId),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const response = await fetch(
        `${config.backendUrl}/api/notification/${notificationId}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyNotification>;
    },
  });
}

interface NotificationResponse {
  notifications: Notifications;
  totalPages: number;
}
export type ReadonlyNotificationResponse = Readonly<NotificationResponse>;

export function useGetNotifications({
  searchText,
  page,
  pageSize = PAGE_SIZE,
}: SearchTextWIthPaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: notificationKeys.notifications({ searchText, page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/notifications/users/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyNotificationResponse>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useGetMyNotifications({
  searchText,
  page,
  pageSize = PAGE_SIZE,
}: SearchTextWIthPaginationParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: notificationKeys.my_notifications({ searchText, page, pageSize }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("page", page.toString());
      params.append("page_size", pageSize.toString());
      const response = await fetch(
        `${config.backendUrl}/api/my_notifications/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyNotificationResponse>;
    },
    staleTime: THIRTY_SECONDS,
  });
}

export function useSearchNotifications({
  searchText,
  limit = SEARCH_BOX_ITEMS,
  id,
}: SearchIdRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: notificationKeys.search(searchText, id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("limit", `${limit}`);
      if (id) {
        params.append("exclude_id", id.toString()); // 現在のIDをリクエストに含める
      }
      const response = await fetch(
        `${config.backendUrl}/api/notifications/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Notifications>;
    },
    enabled: !!id,
    staleTime: THIRTY_SECONDS,
  });
}
