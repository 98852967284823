import { useNavigate } from "react-router-dom";
import { useContext, useRef } from "react";
import { MenuItemComponent } from "../../../uis/Item/MenuItem";
import { useCreateLogMarketPrice } from "../../../hooks/useLogMarketPriceHook";
import { UserContext } from "../../../contexts/UserContext";

export const LogMarketPrice = () => {
  const navigate = useNavigate();
  const { mutateAsync } = useCreateLogMarketPrice();
  const submitProcessing = useRef(false); // ① 送信処理を管理するuseRefを作成する
  const user = useContext(UserContext);

  const menuItems = [
    {
      title: "丸太相場",
      items: [
        { text: "現在の丸太相場", link: "/data/log_market_price/latest" },
        { text: "過去の丸太相場", link: "/data/log_market_price/past" },
      ],
    },
  ] as const;
  type MenuLink = (typeof menuItems)[number]["items"][number]["link"];

  const handleClick = async (link: MenuLink) => {
    if (submitProcessing.current) return; // ② 送信処理中かを判定する（trueなら抜ける）

    switch (link) {
      case "/data/log_market_price/latest":
        submitProcessing.current = true; // ③ 送信処理中フラグを立てる
        try {
          if (user?.is_admin) {
            await mutateAsync();
          }
          navigate(link);
        } catch (error) {
          console.error(error);
        } finally {
          submitProcessing.current = false; // ⑤ 送信処理中フラグを下げる
        }
        break; // フォールスルーを防ぐために break を追加
      default:
        navigate(link);
    }
  };

  return (
    <MenuItemComponent
      menuItems={menuItems}
      handleClick={handleClick}
      disabled={submitProcessing.current}
    />
  );
};
