import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Title } from "../../../uis/Title";
import { useNavigate } from "react-router-dom";
import { useGetLogMarketPrices } from "../../../hooks/useLogMarketPriceHook";
import { Error } from "../../../uis/Error";
import { Loading } from "../../../uis/Loading";

function formatMarketPriceDate(createdAt: Date | null) {
  if (!createdAt) {
    return "タイトルが存在しません";
  }

  // Dateを希望のフォーマットに変換
  const year = createdAt.getFullYear();
  const month = createdAt.getMonth() + 1; // 月は0から始まるので +1 が必要

  return `${year}年${month}月の丸太相場`;
}

export function PastLogMarketPrices() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { data, error, isPending } = useGetLogMarketPrices();

  if (isPending) return <Loading />;
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Title title={`過去の丸太相場`} />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {data &&
          data.length > 0 &&
          data.map((logMarketPrice, index) => {
            return (
              <ListItem
                key={index}
                alignItems="flex-start"
                sx={{ borderBottom: "1px solid black" }}
              >
                <ListItemText
                  primary={formatMarketPriceDate(logMarketPrice.created_at)}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: isSmallScreen ? "70%" : "45%",
                  }}
                >
                  <Box>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        mr: 1,
                        color: "black", // 文字を黒色に
                        borderColor: "black", // 枠線を黒色に
                        backgroundColor: "white", // 背景を白色に
                        "&:hover": {
                          backgroundColor: "rgba(0, 0, 0, 0.1)", // ホバー時の背景色を少し灰色に
                          borderColor: "black",
                        },
                      }}
                      onClick={() =>
                        navigate(`/data/log_market_price/${logMarketPrice.id}`)
                      }
                    >
                      詳細確認
                    </Button>
                  </Box>
                </Box>
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
}
