import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, orderKeys } from "../../utils/query-key";
import {
  OrderPaymentStatusEnum,
  ReadonlyResponse,
  TimeStamp,
} from "../../utils/types/general_type";
import { User } from "../useUserHook";
import { Log } from "./Log/type";
import { UUID } from "crypto";
import { PAGE_SIZE } from "../../utils/constant";

export interface LogOrder extends TimeStamp {
  id: number;
  log_id: number;
  receiver_id?: UUID;
  is_pending: boolean;
  is_denied: boolean;
  is_pending_sended: boolean;
  is_denied_sended: boolean;
  is_received: boolean;
  is_contract_signed: boolean;
  is_active: boolean;
  log: Log;
  user: User;
  log_quotation?: LogQuotation;
  log_order_form?: LogOrderForm;
  log_delivery_note?: LogDeliveryNote;
  log_invoice?: LogInvoice;
}

interface LogQuotation {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface LogOrderForm {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface LogDeliveryNote {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface LogInvoice {
  id: number;
  log_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

export interface CreateLogOrder {
  logId: number;
  isAll: boolean;
  userIds: UUID[];
}

export interface LogOrderPayment {
  log_order_id: number;
  amount: number;
  status: OrderPaymentStatusEnum;
}

export type LogOrders = ReadonlyArray<LogOrder>;

export function useCreateLogOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateLogOrder) => {
      const { token } = await getAccessToken();
      // userIdsが不要な場合はnullにする
      const userIds = data.isAll ? undefined : data.userIds;

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/log_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          log_id: data.logId,
          is_all: data.isAll,
          user_ids: userIds, // userIdsがundefinedなら送信しない
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "log",
          isTemporarilyStored: false,
          isOrdered: true,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: "log",
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
          statuses: [],
        }),
      });
    },
  });
}
