import { Box } from "@mui/material";
import {
  BlueprintOrderMessageRoom,
  LogOrderMessageRoom,
  WoodOrderMessageRoom,
} from "../../hooks/useOrderMessageRoomHook";
import { FileDownload } from "../../uis/File/FileDownload";
import { isBlueprint, isLog, isWood } from "../Home/OrderMessageRoom";
import { FileReference } from "../../utils/types/item_type";
import { ItemsRenderDetails } from "../../uis/RenderDetails";

interface DetailsTabProps {
  data:
    | Readonly<LogOrderMessageRoom>
    | Readonly<WoodOrderMessageRoom>
    | Readonly<BlueprintOrderMessageRoom>;
}

export const DetailsTab: React.FC<DetailsTabProps> = ({ data }) => {
  // タイトルと総額の取得
  let quotation: FileReference | null = null;
  let orderForm: FileReference | null = null;
  let deliveryNote: FileReference | null = null;
  let invoice: FileReference | null = null;

  if (isLog(data)) {
    // LogOrderMessageRoom の処理
    quotation = data.log_order.log_quotation
      ? { id: data.log_order.log_quotation.id, itemType: "log" }
      : null;
    orderForm = data.log_order.log_order_form
      ? { id: data.log_order.log_order_form.id, itemType: "log" }
      : null;
    deliveryNote = data.log_order.log_delivery_note
      ? { id: data.log_order.log_delivery_note.id, itemType: "log" }
      : null;
    invoice = data.log_order.log_invoice
      ? { id: data.log_order.log_invoice.id, itemType: "log" }
      : null;
  } else if (isWood(data)) {
    // WoodOrderMessageRoom の処理
    quotation = data.wood_order.wood_quotation
      ? { id: data.wood_order.wood_quotation.id, itemType: "wood" }
      : null;
    orderForm = data.wood_order.wood_order_form
      ? { id: data.wood_order.wood_order_form.id, itemType: "wood" }
      : null;
    deliveryNote = data.wood_order.wood_delivery_note
      ? { id: data.wood_order.wood_delivery_note.id, itemType: "wood" }
      : null;
    invoice = data.wood_order.wood_invoice
      ? { id: data.wood_order.wood_invoice.id, itemType: "wood" }
      : null;
  } else if (isBlueprint(data)) {
    // BlueprintOrderMessageRoom の処理
    quotation = data.blueprint_order.blueprint_quotation
      ? {
          id: data.blueprint_order.blueprint_quotation.id,
          itemType: "blueprint",
        }
      : null;
    orderForm = data.blueprint_order.blueprint_order_form
      ? {
          id: data.blueprint_order.blueprint_order_form.id,
          itemType: "blueprint",
        }
      : null;
    deliveryNote = data.blueprint_order.blueprint_delivery_note
      ? {
          id: data.blueprint_order.blueprint_delivery_note.id,
          itemType: "blueprint",
        }
      : null;
    invoice = data.blueprint_order.blueprint_invoice
      ? { id: data.blueprint_order.blueprint_invoice.id, itemType: "blueprint" }
      : null;
  } else {
    // 安全策としてデフォルト値を設定
    quotation = null;
    orderForm = null;
    deliveryNote = null;
    invoice = null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        bgcolor: "background.paper",
        p: 2,
      }}
    >
      <Box sx={{ width: "100%", mt: 4 }}>
        <ItemsRenderDetails
          log={isLog(data) ? data.log_order.log : undefined}
          wood={isWood(data) ? data.wood_order.wood : undefined}
          blueprint={
            isBlueprint(data) ? data.blueprint_order.blueprint : undefined
          }
        />
        <FileDownload
          file={{ id: quotation?.id, itemType: quotation?.itemType }}
          category="quotation"
        />
        <FileDownload
          file={{ id: orderForm?.id, itemType: orderForm?.itemType }}
          category="order_form"
        />
        {deliveryNote && (
          <FileDownload
            file={{ id: deliveryNote.id, itemType: deliveryNote.itemType }}
            category="delivery_note"
          />
        )}
        {invoice && (
          <FileDownload
            file={{ id: invoice.id, itemType: invoice.itemType }}
            category="invoice"
          />
        )}
      </Box>
    </Box>
  );
};
