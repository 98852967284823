import { Box, Typography } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useNavigate } from "react-router-dom";
import { useCreateMessageRoom } from "../hooks/Message/useMessageRoomHook";

export const Talk = ({ userId }: { userId?: string }) => {
  const { mutateAsync: createMessageRoom, isPending: isMutatePending } =
    useCreateMessageRoom();

  const navigate = useNavigate();
  const handleCreate = async (userId?: string) => {
    if (!userId) {
      navigate("/signin");
      return;
    }
    try {
      const res = await createMessageRoom({ partner_id: userId });
      navigate(`/talk/message_room/${res.message_room_id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box sx={{ width: "30%", display: "flex", alignItems: "center" }}>
      {/* トークアイコン */}
      <ChatBubbleOutlineIcon sx={{ color: "green", mr: 1 }} />

      {/* テキスト */}
      <Typography
        onClick={() => {
          if (!isMutatePending) {
            handleCreate(userId);
          }
        }}
        sx={{
          color: isMutatePending ? "gray" : "green", // 変化する色
          textDecoration: "underline",
          cursor: isMutatePending ? "not-allowed" : "pointer", // 変化するカーソル
          "&:hover": {
            textDecoration: isMutatePending ? "underline" : "none", // ホバー時の下線
          },
        }}
      >
        トークで相談する
      </Typography>
    </Box>
  );
};
