import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Stack, Typography } from "@mui/material";
import { SignInSchema, SignInType } from "../../utils/schema";
import { validateData } from "../../utils/validation";
import { TextFieldForm } from "../../uis/TextField/TextFieldForm";
import { Color } from "../../utils/color";

export const SignUp = () => {
  const auth = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }, // isSubmitting を追加
  } = useForm<SignInType>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const onSubmit = async (data: SignInType) => {
    const validationResponse = validateData(data, SignInSchema);
    if (validationResponse.success) {
      try {
        const result = await auth.signUp(data);
        if (result.success) {
          navigate(`/signin`);
        } else {
          console.error(result.message);
          setErrorMessage(`${result.message}`);
        }
      } catch (error) {
        console.error(error);
        setErrorMessage("作成に失敗しました");
      }
    } else {
      setErrorMessage(validationResponse.errors[0]);
    }
  };

  // const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const input = e.target.value;
  //   let phoneNumber: string;
  //   // プレフィックスとしての"+"が消されないようにする
  //   if (input.startsWith("+")) {
  //     phoneNumber = input;
  //   } else {
  //     phoneNumber = "+" + input.replace(/^\+/, "");
  //   }
  //   setValue("phoneNumber", phoneNumber, { shouldValidate: true }); // フォームの値を更新
  // };

  return (
    <Box
      sx={{
        width: "80%", // 残りの80%をメインコンテンツに割り当て
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: 'url("/background.png")', // バックグラウンド画像の設定
      }}
    >
      <Box
        sx={{
          width: "50%", // メインコンテンツの50%の横幅
          backgroundColor: "#F2F2F2",
          padding: "40px",
          borderRadius: "8px", // 角を少し丸める
          boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // 影を追加して浮き上がり感を出す
        }}
      >
        <Typography
          variant="h4"
          component="h2"
          sx={{ marginBottom: "20px", textAlign: "center" }}
        >
          アカウント登録
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            {errorMessage && (
              <Alert severity="error" sx={{ color: "red" }}>
                {errorMessage}
              </Alert>
            )}

            {/* <FormField<SignInType>
            register={register}
            name="industry"
            isError={!!errors.industry}
            errorMessage={errors.industry?.message}
            isSelected={false} // Selectに指定
            options={industries.map((industry, index) => ({
              label: industry,
              value: index,
            }))}
            placeholder="業種を選択してください"
            labelName="業種"
          />
          <FormField<SignInType>
            placeholder="会社名"
            register={register}
            name="companyName"
            isError={!!errors.companyName}
            errorMessage={errors.companyName?.message}
          />
          <FormField<SignInType>
            placeholder="担当者電話番号"
            register={register}
            name="phoneNumber"
            isError={!!errors.phoneNumber}
            errorMessage={errors.phoneNumber?.message}
            pattern={{
              value: /^[0-9\-+]+$/,
              message: "有効な電話番号を入力してください",
            }}
            defaultValue="+"
            onChange={handlePhoneNumberChange}
          /> */}
            <TextFieldForm
              name="email"
              control={control}
              errors={errors}
              labelName="担当者メールアドレス"
              type="email"
              rules={{
                required: "メールアドレスは必須です",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "正しいメールアドレスの形式で入力してください",
                },
              }}
            />

            {/* パスワード */}
            <TextFieldForm
              name="password"
              control={control}
              errors={errors}
              labelName="パスワード"
              type="password"
              rules={{
                required: "パスワードは必須です",
                minLength: {
                  value: 8,
                  message: "パスワードは8文字以上でなければなりません",
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{
                backgroundColor: Color.Main,
                color: "#FFFFFF",
                height: "50px",
                "&:hover": {
                  backgroundColor: "#006f3d", // ホバー時に少し明るく
                },
              }}
            >
              {isSubmitting ? "登録中..." : "登録"}
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};
