import { useGetLog } from "../../../hooks/Log/Log/useLogHook";
import { useSearchLogUsers } from "../../../hooks/useUserHook";
import { useCreateLogOrder } from "../../../hooks/Log/useLogOrderHook";
import { MailingComponent } from "../../common/MailingComponent";

export const LogMailing = () => {
  return (
    <MailingComponent
      idParamName="log_id"
      useGetData={useGetLog}
      useSearchUsers={useSearchLogUsers}
      useCreateOrder={useCreateLogOrder}
      orderDataKey="logId"
      navigateTo="/order"
    />
  );
};
