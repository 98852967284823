import { MouseEvent, useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
  useMediaQuery,
  ListItemText,
  useTheme,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Loading } from "../../uis/Loading";
import { Error } from "../../uis/Error";
import { useGetOrderUsers } from "../../hooks/useUserHook";
import Avatar from "../../uis/Avatar";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OrderDetailModal } from "../../uis/Modals/OrderDetailModal";
import { useReceiveOrder } from "../../hooks/useOrderHook";
import { ItemType, UsersItem } from "../../utils/types/item_type";
import { useDownloadImages } from "../../hooks/S3/useS3ImageHook";
import { OrderStatusType } from "../../utils/types/general_type";
import { WoodDetail } from "../../hooks/Wood/Wood/type";
import { BlueprintDetail } from "../../hooks/Blueprint/Blueprint/type";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Talk } from "../../uis/Talk";
import { Title } from "../../uis/Title";
import {
  isBlueprintByOrder,
  isLogByOrder,
  isWoodByOrder,
} from "../../uis/File/FileDownloadComponent";
import { PaginationComponent } from "../../uis/Pagination";

export const RequestList = () => {
  // useSearchParamsを使用してクエリパラメータを管理
  const [searchParams, setSearchParams] = useSearchParams();

  // URLから初期ページを取得、存在しない場合は1
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const [page, setPage] = useState<number>(
    isNaN(initialPage) || initialPage < 1 ? 1 : initialPage,
  );

  const { data, error, isPending } = useGetOrderUsers({ page });
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { mutateAsync: mutateReceiveOrderAsync } = useReceiveOrder();
  const { mutateAsync: mutateDownloadAsync } = useDownloadImages();
  // メニュー制御用の状態
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedItem, setSelectedItem] = useState<UnifiedItem | null>(null);

  // 各注文ごとのdisabled状態を管理するためのステート
  const [disabledOrders, setDisabledOrders] = useState<Record<string, boolean>>(
    {},
  );
  const [order, setOrder] = useState<UsersItem | null>(null);

  const handleClick = async (
    key: string, // 一意のキーを追加
    status: OrderStatusType,
    userId?: string,
    id?: number,
    type?: ItemType,
  ) => {
    // 現在の注文をdisabledに設定
    setDisabledOrders((prev) => ({ ...prev, [key]: true }));

    // ユーザーIDの確認
    if (!userId) {
      toast.error("ユーザーが存在しません");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    if (!id || !type) {
      toast.error("エラーが発生しました。");
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
      return;
    }

    try {
      await mutateReceiveOrderAsync({
        id, // 必ず number 型
        type: type, // プロパティ名 'type' を追加
        status,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setDisabledOrders((prev) => ({ ...prev, [key]: false }));
    }
  };

  const handleDownload = async (id?: number) => {
    if (!id) {
      toast.error("idが存在しません");
      return;
    }
    try {
      await mutateDownloadAsync({ id, category: "blueprint" });
    } catch (error) {
      console.error(error);
    }
  };

  const open = Boolean(anchorEl);

  const handleMenuOpen = (
    event: MouseEvent<HTMLElement>,
    item: UnifiedItem,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedItem(item);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    // クエリパラメータを更新
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: value.toString(),
    });
  };

  // クエリパラメータが変更された際にページを更新
  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (isPending) {
    return <Loading />;
  }

  // エラー時の処理
  if (error) {
    return <Error message={error?.message || "エラーが発生しました。"} />;
  }

  // dataが存在しない場合の処理
  if (!data) {
    return (
      <Typography variant="h6" color="textSecondary">
        データが存在しません。
      </Typography>
    );
  }

  const unifiedItems = data.orders.map((order) => {
    const wood = isWoodByOrder(order) ? order.wood : undefined;
    const log = isLogByOrder(order) ? order.log : undefined;
    const blueprint = isBlueprintByOrder(order) ? order.blueprint : undefined;

    return {
      id: wood?.id ?? log?.id ?? blueprint?.id,
      title: wood?.title ?? log?.title ?? blueprint?.title, // Using the 'wood' variable
      type: (wood
        ? "wood"
        : log
          ? "log"
          : blueprint
            ? "blueprint"
            : undefined) as ItemType | undefined,
      orderId: order.id,
      user: wood?.order ?? log?.order ?? blueprint?.order,
      totalAmount:
        wood?.total_amount ?? log?.total_amount ?? blueprint?.total_amount,
      isPending: order.is_pending_sended,
      isDenied: order.is_denied_sended,
      details:
        wood?.wood_details ?? log?.log_details ?? blueprint?.blueprint_details,
      wood,
      log,
      blueprint,
    };
  });
  type UnifiedItem = (typeof unifiedItems)[number];

  return (
    <Box
      sx={{
        width: "55%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <Title title="依頼リスト一覧" />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        {unifiedItems.map((item) => (
          <ListItem
            key={`${item.type}-${item.id}`}
            alignItems="flex-start"
            sx={{
              borderBottom: "1px solid black",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }} // ここでボーダーを追加
          >
            <Box width={{ width: "10%" }}>
              {item.isPending ? (
                <Typography>一度保留した依頼</Typography>
              ) : item.isDenied ? (
                <Typography>一度辞退した依頼</Typography>
              ) : null}
              {
                // 1) item.type が log の場合 → item.id が存在すれば表示
                ((item.type === "log" && item.id) ||
                  // 2) item.type が wood の場合 → details が存在して、すべての WoodDetail.amount が null でなければ表示
                  (item.type === "wood" &&
                    Array.isArray(item.details) &&
                    (item.details as WoodDetail[]).every(
                      (woodDetail: WoodDetail) => woodDetail.amount !== null,
                    )) ||
                  // 3) item.type が blueprint の場合 → details が存在して、すべてのデータが undefined / null でなければ表示
                  (item.type === "blueprint" &&
                    Array.isArray(item.details) &&
                    item.details.length > 0 &&
                    (item.details as BlueprintDetail[]).every(
                      (blueprintDetail: BlueprintDetail) =>
                        Object.values(blueprintDetail).every(
                          (value) => value !== undefined && value !== null,
                        ),
                    ))) && (
                  <>
                    <Button
                      variant="outlined"
                      color="inherit"
                      endIcon={<KeyboardArrowDownIcon />}
                      onClick={(e) => handleMenuOpen(e, item)}
                      sx={{
                        borderColor: "black",
                        color: "black",
                        "&:hover": {
                          borderColor: "black",
                          backgroundColor: "rgba(0, 0, 0, 0.04)",
                        },
                      }}
                    >
                      選択
                    </Button>
                  </>
                )
              }
            </Box>
            <ListItemAvatar sx={{ width: "5%" }}>
              {/* Avatarコンポーネントを使用 */}
              <Avatar user={item.user} isSmallScreen={isSmallScreen} />
            </ListItemAvatar>
            <Box
              sx={{
                display: "flex",
                width: "50%",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Box sx={{ width: "70%" }}>
                <ListItemText
                  primary={item?.user?.company_name || "会社名がありません"} // 名前表示
                />
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {item?.title || "タイトルが存在しません"}{" "}
                    {/* メッセージの内容 */}
                  </Typography>

                  <Typography variant="body2" color="text.secondary">
                    見積もり金額（合計）: ¥
                    {item?.totalAmount && item?.totalAmount !== null
                      ? item.totalAmount.toLocaleString()
                      : "データが存在しません"}
                  </Typography>
                </Box>
              </Box>
              <Talk userId={item?.user?.user_id} />
            </Box>
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "flex-end",
                width: "35%",
                alignItems: "center",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    mr: 1,
                    color: "black", // 文字を黒色に
                    borderColor: "black", // 枠線を黒色に
                    backgroundColor: "white", // 背景を白色に
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // ホバー時の背景色を少し灰色に
                      borderColor: "black",
                    },
                  }}
                  onClick={() => {
                    if (item?.user) {
                      setOrder({
                        users: [item.user],
                        item: item,
                      });
                    } else {
                      toast.error("ユーザーが存在しません");
                    }
                  }}
                >
                  詳細確認
                </Button>
                {item.type === "wood" && item.id && (
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      mr: 1,
                      color: "red",
                      borderColor: "red",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "rgba(255, 0, 0, 0.1)", // ホバー時の背景色を少し赤っぽく
                        borderColor: "red",
                      },
                    }}
                    onClick={() => navigate(`/order/quotation/${item.id}`)}
                  >
                    見積もりを作成する
                  </Button>
                )}
                {item.type === "blueprint" && item.id && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() =>
                        navigate(
                          `/order/create_blueprint_detail_list/${item.id}`,
                        )
                      }
                    >
                      木材リストを作成する
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={() => handleDownload(item.id)}
                    >
                      設計図面をダウンロードする
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </ListItem>
        ))}
        {/* ページネーション */}
        {data && data.totalPages > 1 && (
          <PaginationComponent
            totalPages={data.totalPages}
            page={page}
            handlePageChange={handlePageChange}
            isSmallScreen={isSmallScreen}
          />
        )}
      </List>

      {/* 単一のMenuコンポーネントを使用 */}
      <Menu
        anchorEl={anchorEl}
        open={open && selectedItem !== null}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            mt: 1,
            minWidth: 150,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            if (selectedItem) {
              handleClick(
                `${selectedItem.type}-${selectedItem.id}`,
                "receiving",
                selectedItem.user?.user_id,
                selectedItem.id,
                selectedItem.type,
              );
            }
            handleMenuClose();
          }}
          sx={{ color: "black" }}
          disabled={
            selectedItem
              ? disabledOrders[`${selectedItem.type}-${selectedItem.id}`] ||
                false
              : false
          }
        >
          注文を承諾する
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (selectedItem) {
              handleClick(
                `${selectedItem.type}-${selectedItem.id}`,
                "pending",
                selectedItem.user?.user_id,
                selectedItem.id,
                selectedItem.type,
              );
            }
            handleMenuClose();
          }}
          sx={{ color: "black" }}
          disabled={
            selectedItem
              ? disabledOrders[`${selectedItem.type}-${selectedItem.id}`] ||
                false
              : false
          }
        >
          注文を保留する
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (selectedItem) {
              handleClick(
                `${selectedItem.type}-${selectedItem.id}`,
                "denying",
                selectedItem.user?.user_id,
                selectedItem.id,
                selectedItem.type,
              );
            }
            handleMenuClose();
          }}
          sx={{ color: "black" }}
          disabled={
            selectedItem
              ? disabledOrders[`${selectedItem.type}-${selectedItem.id}`] ||
                false
              : false
          }
        >
          注文を辞退する
        </MenuItem>
      </Menu>

      {order !== null && (
        <OrderDetailModal
          userItem={order}
          open={order !== null}
          onClose={() => setOrder(null)}
        />
      )}
    </Box>
  );
};
