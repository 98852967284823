import { Box, Button, Typography } from "@mui/material";
import { LogOrder } from "../hooks/Log/useLogOrderHook";
import { WoodOrder } from "../hooks/Wood/useWoodOrderHook";
import { BlueprintOrder } from "../hooks/Blueprint/useBlueprintOrderHook";
import { Wood } from "../hooks/Wood/Wood/type";
import { Log } from "../hooks/Log/Log/type";
import { GetBlueprint } from "../hooks/Blueprint/Blueprint/type";
import { UsersItem } from "../utils/types/item_type";
import { Users } from "../hooks/useUserHook";

/**
 * 「詳細を確認する」ボタンとFileDownload部分をひとまとまりにする。
 * グレー背景にして「詳細を確認する」の下に「詳細確認」ボタン、さらにFileDownloadがあれば黒い縦線で区切る。
 * 全ては右寄せ、上下中央揃えを想定するため、Flexコンテナで対応。
 */
export const DetailSection = ({
  order,
  wood,
  log,
  blueprint,
  setOrder,
  users,
}: {
  order?: LogOrder | WoodOrder | BlueprintOrder;
  wood?: Wood;
  log?: Log;
  blueprint?: GetBlueprint;
  setOrder: (value: React.SetStateAction<UsersItem | null>) => void;
  users: Users;
}) => {
  return (
    <Box>
      {/* 詳細を確認するテキスト */}
      <Typography sx={{ fontSize: "0.9rem", fontWeight: "bold" }}>
        詳細を確認する
      </Typography>
      {/* 詳細確認ボタン */}
      <Button
        variant="contained"
        size="small"
        sx={{
          backgroundColor: "#f4f0f0",
          color: "black",
          border: "1px solid black",
        }}
        onClick={() =>
          setOrder({
            users: users,
            item: {
              wood,
              log,
              blueprint,
            },
          })
        }
      >
        詳細確認
      </Button>
    </Box>
  );
};
