import { useMutation, useQueryClient } from "@tanstack/react-query";
import config from "../../config";
import { useAuth } from "../useAuth";
import { handleResponse } from "../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, orderKeys } from "../../utils/query-key";
import { ReadonlyResponse, TimeStamp } from "../../utils/types/general_type";
import { User } from "../useUserHook";
import { Wood } from "./Wood/type";
import { UUID } from "crypto";
import { PAGE_SIZE } from "../../utils/constant";

export interface WoodOrder extends TimeStamp {
  id: number;
  wood_id: number;
  receiver_id?: UUID;
  is_pending: boolean;
  is_denied: boolean;
  is_pending_sended: boolean;
  is_denied_sended: boolean;
  is_received: boolean;
  is_contract_signed: boolean;
  is_active: boolean;
  wood: Wood;
  user: User;
  wood_quotation?: WoodQuotation;
  wood_order_form?: WoodOrderForm;
  wood_delivery_note?: WoodDeliveryNote;
  wood_invoice?: WoodInvoice;
}

interface WoodQuotation {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface WoodOrderForm {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface WoodDeliveryNote {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

interface WoodInvoice {
  id: number;
  wood_order_id: number;
  excel_file_path: string;
  pdf_file_path: string;
}

export interface CreateWoodOrder {
  woodId: number;
  isAll: boolean;
  userIds: UUID[];
}

export type WoodOrders = ReadonlyArray<WoodOrder>;

export function useCreateWoodOrder() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: CreateWoodOrder) => {
      const { token } = await getAccessToken();
      // userIdsが不要な場合はnullにする
      const userIds = data.isAll ? undefined : data.userIds;

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood_order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          wood_id: data.woodId,
          is_all: data.isAll,
          user_ids: userIds, // userIdsがundefinedなら送信しない
        }),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (res) => {
      toast.success(res.message);
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "wood",
          isTemporarilyStored: false,
          isOrdered: true,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: orderKeys.searchStatuses({
          type: "wood",
          searchText: "",
          page: 1,
          pageSize: PAGE_SIZE,
          statuses: [],
        }),
      });
    },
  });
}
