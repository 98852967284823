import { Tabs, Tab, SxProps, TabsProps } from "@mui/material";

export interface TabDefinition {
  label: string;
}

export interface TabProps<T extends TabDefinition> {
  tabs: ReadonlyArray<T>;
  tabIndicatorProps?: TabsProps["TabIndicatorProps"];
  tabsSx?: SxProps;
  tabSx?: (selected: boolean) => SxProps;
  spanStyle?: (selected: boolean) => React.CSSProperties;
}

interface TabBarProps<T extends TabDefinition> extends TabProps<T> {
  selectedTab: number;
  handleTabChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const TabBar = ({
  tabs,
  tabSx,
  tabsSx,
  tabIndicatorProps,
  handleTabChange,
  selectedTab,
  spanStyle,
}: TabBarProps<TabDefinition>) => {
  return (
    <Tabs
      value={selectedTab}
      onChange={handleTabChange}
      aria-label="navigation tabs"
      sx={{
        width: "100%",
        ...tabsSx,
      }}
      TabIndicatorProps={
        tabIndicatorProps || { style: { backgroundColor: "green" } }
      }
      variant="fullWidth" // 各タブが画面幅いっぱいに広がる設定
    >
      {tabs.map((tab, index) => (
        <Tab
          key={tab.label}
          label={
            <span
              style={spanStyle ? spanStyle(selectedTab === index) : undefined}
            >
              {tab.label}
            </span>
          }
          sx={
            tabSx
              ? tabSx(selectedTab === index)
              : {
                  color: selectedTab === index ? "green" : "inherit",
                  borderBottom:
                    selectedTab === index ? "2px solid green" : "none",
                }
          }
        />
      ))}
    </Tabs>
  );
};
