import { Item } from "./types/item_type";

export function extractOrders({ log, wood, blueprint }: Item) {
  return log
    ? log.log_orders
    : wood
      ? wood.wood_orders
      : blueprint
        ? blueprint.blueprint_orders
        : undefined;
}
