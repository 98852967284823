import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SearchBox } from "../../../uis/TextField/SearchBox";
import { Error } from "../../../uis/Error";
import Avatar from "../../../uis/Avatar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UnreadBadge } from "../../../uis/Badge";
import { useSearchOrderMessageRooms } from "../../../hooks/useOrderMessageRoomHook";
import { useReadOrderMessage } from "../../../hooks/useOrderMessageHook";
import { ItemType } from "../../../utils/types/item_type";
import { PaginationComponent } from "../../../uis/Pagination";

export const OrderMessageRooms = () => {
  const navigate = useNavigate();
  // useSearchParamsを使用してクエリパラメータを管理
  const [searchParams, setSearchParams] = useSearchParams();
  // URLから初期ページを取得、存在しない場合は1
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const initialSearchText = searchParams.get("search") || "";
  const [searchText, setSearchText] = useState<string>(initialSearchText);
  const [page, setPage] = useState<number>(
    isNaN(initialPage) || initialPage < 1 ? 1 : initialPage,
  );
  const [searchClicked, setSearchClicked] = useState(false); // 検索ボックスがクリックされたかを管理
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, error } = useSearchOrderMessageRooms({
    searchText,
    page,
  });

  const { mutateAsync: mutateReadAsync } = useReadOrderMessage();

  const handleSearchBoxClick = () => {
    setSearchClicked((prev) => !prev); // 現在の値とは反対の値を設定
  };
  const handleSearchBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newSearchText = event.target.value;
    setSearchText(newSearchText);
    setPage(1); // 検索テキストが変更されたらページをリセット

    // クエリパラメータを更新: search を新しい検索テキストに、page を1にリセット
    setSearchParams({
      ...Object.fromEntries(searchParams),
      search: newSearchText,
      page: "1",
    });
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
    // クエリパラメータを更新
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: value.toString(),
    });
  };

  // クエリパラメータが変更された際に page と searchText を更新
  useEffect(() => {
    const currentPage = parseInt(searchParams.get("page") || "1", 10);
    const currentSearchText = searchParams.get("search") || "";

    if (!isNaN(currentPage) && currentPage !== page) {
      setPage(currentPage);
    }

    if (currentSearchText !== searchText) {
      setSearchText(currentSearchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleClick = async (roomId: number, type: ItemType) => {
    try {
      await mutateReadAsync({
        type: type,
        messageRoomId: roomId,
      });
      // 現在のクエリパラメータを取得
      const currentSearchParams = Object.fromEntries(searchParams.entries());
      const queryString = new URLSearchParams(currentSearchParams).toString();
      const navigatePath = `/talk/${type}_order_message_room/${roomId}${
        queryString ? `?${queryString}` : ""
      }`;

      navigate(navigatePath);
    } catch (error) {
      console.error(error);
    }
  };
  // エラー時の処理
  if (error) {
    return <Error message={error?.message} />;
  }

  return (
    <Box
      sx={{
        width: "95%",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        margin: "0 auto",
        height: "80vh",
      }}
    >
      <SearchBox
        placeholder="会社・プロジェクトを検索"
        isSmallScreen
        onClick={handleSearchBoxClick}
        onChange={handleSearchBoxChange}
        value={searchText}
        searchClicked={searchClicked} // ここで渡す
      />
      <List sx={{ width: "100%", bgcolor: "background.paper", mt: 2 }}>
        {data && data.orderMessageRooms && data.orderMessageRooms.length > 0 ? (
          data.orderMessageRooms.map((room) => (
            <ListItem
              key={`${room.id}-${room.type}`} // 一意な識別子に変更
              alignItems="flex-start"
              onClick={() => handleClick(room.id, room.type)}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: theme.palette.action.hover,
                },
                padding: theme.spacing(2),
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              <ListItemAvatar>
                {/* アイコンの表示、ここでは仮にAvatarを使用 */}
                <Avatar user={room.user} isSmallScreen={isSmallScreen} />
              </ListItemAvatar>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginLeft: theme.spacing(2),
                }}
              >
                <Typography
                  variant={isSmallScreen ? "subtitle1" : "h6"}
                  component="div"
                  color="text.primary"
                  sx={{ fontWeight: "bold", mb: 0.5 }}
                >
                  {room.user.company_name}
                </Typography>
                <Typography
                  variant="body2"
                  component="div"
                  color="text.secondary"
                  sx={{ mb: 0.5 }}
                >
                  プロジェクトのタイトル: {room.title}
                </Typography>
                {room.message && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 1,
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      color="text.secondary"
                    >
                      最終メッセージ: {room.message.content}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                      sx={{ mt: 0.5 }}
                    >
                      {room.message.createdAt.toLocaleString()}
                    </Typography>
                  </Box>
                )}
              </Box>
              {/* 未読件数の表示 */}
              {room.unreadCount > 0 && (
                <UnreadBadge
                  badgeContent={room.unreadCount}
                  color="error"
                  overlap="circular"
                />
              )}
            </ListItem>
          ))
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mt: 2, textAlign: "center" }}
          >
            メッセージルームが見つかりません。
          </Typography>
        )}
        {/* ページネーション */}
        {data && data.totalPages > 1 && (
          <PaginationComponent
            totalPages={data.totalPages}
            page={page}
            handlePageChange={handlePageChange}
            isSmallScreen={isSmallScreen}
          />
        )}
      </List>
    </Box>
  );
};
