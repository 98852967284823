import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  DataLogo,
  LoginLogo,
  Logo,
  LogoutLogo,
  ManagementLogo,
  OrderLogo,
  SignupLogo,
} from "../svg";
import HomeIcon from "@mui/icons-material/Home";
import ChatIcon from "@mui/icons-material/Chat";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { Color } from "../../utils/color";

interface SidebarProps {
  isLoggedIn: boolean;
}

interface SidebarItems {
  children: React.ReactNode;
  label: string;
  link: string;
}

const Sidebar: React.FC<SidebarProps> = ({ isLoggedIn = true }) => {
  const navigate = useNavigate();
  const location = useLocation(); // 現在のパスを取得
  const { signOut } = useAuth();

  const sidebarLoginItems = [
    {
      children: <LoginLogo />,
      label: "ログイン",
      link: "/signin",
    },
    {
      children: <SignupLogo />,
      label: "はじめての方",
      link: "/signup",
    },
  ] as const satisfies ReadonlyArray<SidebarItems>;
  type SidebarLoginLink = (typeof sidebarLoginItems)[number]["link"];

  const sidebarHomeItems = [
    {
      children: <HomeIcon />,
      label: "ホーム",
      link: "/home",
    },
    {
      children: <OrderLogo />,
      label: "注文",
      link: "/order",
    },
    {
      children: <ChatIcon />,
      label: "トーク",
      link: "/talk/message_rooms",
    },
    {
      children: <ManagementLogo />,
      label: "管理",
      link: "/management/stock",
    },
    {
      children: <DataLogo />,
      label: "データ",
      link: "/data/log_market_price",
    },
    {
      children: <AccountCircleIcon sx={{ color: "white" }} />,
      label: "マイページ",
      link: "/profile",
    },
    {
      children: <LogoutLogo />,
      label: "ログアウト",
      link: "/signout",
    },
  ] as const satisfies ReadonlyArray<SidebarItems>;
  type SidebarHomeLink = (typeof sidebarHomeItems)[number]["link"];

  const handleClick = async (link: SidebarLoginLink | SidebarHomeLink) => {
    if (link === "/signout") {
      try {
        // ログアウト処理をここに追加する
        const res = await signOut();
        if (res.success) {
          navigate("/signin");
        } else {
          console.error(res.message);
          toast.error(res.message);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      navigate(link);
    }
  };

  return (
    <Box
      sx={{
        width: "20%",
        minWidth: "200px", // 最小幅を設定
        minHeight: "100vh",
        backgroundColor: Color.Main,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        boxSizing: "border-box",
      }}
    >
      {/* ロゴとタイトル */}
      <Box
        sx={{ textAlign: "center", marginBottom: "40px" }}
        onClick={() => navigate("/home")}
      >
        <Logo />
        <Typography variant="h6" sx={{ color: "white", marginTop: "10px" }}>
          EFF木材流通システム
        </Typography>
      </Box>
      {isLoggedIn
        ? sidebarHomeItems.map((item, index) => (
            <Button
              key={index}
              onClick={() => handleClick(item.link)}
              sx={{
                width: "100%",
                color:
                  location.pathname.split("/")[1] === item.link.split("/")[1]
                    ? "#006747"
                    : "white",
                backgroundColor:
                  location.pathname.split("/")[1] === item.link.split("/")[1]
                    ? "white"
                    : "#006f3d",
                borderTop: "0.5px solid white",
                borderBottom: "0.5px solid white",
                justifyContent: "start",
                padding: "1.5rem 1rem",
                fontSize: "1rem",
                borderRadius: 0,
                "&:hover": {
                  backgroundColor:
                    location.pathname === item.link ? "white" : "#54c291",
                },
              }}
            >
              {item.children}
              {item.label}
            </Button>
          ))
        : sidebarLoginItems.map((item, index) => (
            <Button
              key={index}
              onClick={() => handleClick(item.link)}
              sx={{
                width: "100%",
                color: location.pathname === item.link ? "#006747" : "white",
                backgroundColor:
                  location.pathname === item.link ? "white" : "#006f3d",
                borderTop: "0.5px solid white",
                borderBottom: "0.5px solid white",
                justifyContent: "start",
                padding: "1.5rem 1rem",
                fontSize: "1rem",
                borderRadius: 0,
                "&:hover": {
                  backgroundColor:
                    location.pathname === item.link ? "white" : "#54c291",
                },
              }}
            >
              {item.children}
              {item.label}
            </Button>
          ))}
      <Box>
        <Link
          to="/terms_of_service"
          style={{ color: "white", textDecoration: "none" }}
        >
          利用規約
        </Link>
      </Box>
    </Box>
  );
};

export default Sidebar;
