import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import config from "../../../config";
import { useAuth } from "../../useAuth";
import { handleResponse } from "../../../utils/response";
import { toast } from "react-toastify";
import { itemKeys, userKeys, woodKeys } from "../../../utils/query-key";
import {
  PAGE_SIZE,
  SEARCH_BOX_ITEMS,
  THIRTY_SECONDS,
} from "../../../utils/constant";
import {
  OrderStatusWithId,
  SearchIdRequest,
  ReadonlyResponse,
  ReadonlyResponseWithId,
  OrderStatusWithSearchParams,
} from "../../../utils/types/general_type";
import {
  ReadonlyResponseWoods,
  ReadonlyWood,
  UpdateWood,
  Wood,
  Woods,
} from "./type";

export function useCreateWood() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      return handleResponse(response) as Promise<ReadonlyResponseWithId>;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "wood",
          isTemporarilyStored: true,
          isOrdered: false,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}

export function useUpdateWood() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Wood) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify({
          id: data.id,
          title: data.title,
          file_path: data.file_path,
          is_temporarily_stored: data.is_temporarily_stored,
          is_ordered: data.is_ordered,
          wood_details: data.wood_details.map((detail) => ({
            name: detail.name,
            species: detail.species,
            material: detail.material,
            origin: detail.origin,
            grade: detail.grade,
            width: detail.width,
            height: detail.height,
            length: detail.length,
            unit_volume: detail.unit_volume,
            quantity: detail.quantity,
            total_volume: detail.total_volume,
          })),
        } satisfies UpdateWood),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      if (variables.is_temporarily_stored) {
        toast.success("木材リストを一時保存しました");
      }
      await queryClient.invalidateQueries({
        queryKey: woodKeys.wood(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: itemKeys.items_by_filter({
          itemName: "wood",
          isTemporarilyStored: true,
          isOrdered: false,
          page: 1,
          pageSize: PAGE_SIZE,
        }),
      });
    },
  });
}

export function useGetWood({
  id,
  isTemporarilyStored,
  isOrdered,
}: OrderStatusWithId) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.wood(id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/wood/${id}?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyWood>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useGetWoods({
  isTemporarilyStored,
  isOrdered,
  page,
  pageSize = PAGE_SIZE,
}: OrderStatusWithSearchParams) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: itemKeys.items_by_filter({
      itemName: "wood",
      isTemporarilyStored,
      isOrdered,
      page,
      pageSize,
    }),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = {
        is_temporarily_stored: isTemporarilyStored.toString(), // booleanをstringに変換
        is_ordered: isOrdered.toString(), // booleanをstringに変換
        page: page.toString(),
        pageSize: pageSize.toString(),
      };

      // URLSearchParamsでクエリパラメータに変換
      const queryString = new URLSearchParams(params).toString();

      const response = await fetch(
        `${config.backendUrl}/api/woods?${queryString}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyResponseWoods>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}

export function useSearchWoods({
  searchText,
  limit = SEARCH_BOX_ITEMS,
  id,
}: SearchIdRequest) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.search(searchText, id),
    queryFn: async () => {
      const { token } = await getAccessToken();
      const params = new URLSearchParams();
      params.append("search_text", searchText);
      params.append("limit", `${limit}`);
      if (id) {
        params.append("exclude_id", id.toString()); // 現在のIDをリクエストに含める
      }
      const response = await fetch(
        `${config.backendUrl}/api/woods/search?${params.toString()}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<Woods>;
    },
    enabled: !!id,
    staleTime: THIRTY_SECONDS,
  });
}

export function useUpdateWoodByReceiver() {
  const { getAccessToken } = useAuth();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: Wood) => {
      const { token } = await getAccessToken();

      // タイトル、内容、画像URLをサーバーに送信
      const response = await fetch(`${config.backendUrl}/api/wood/receiver`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
        body: JSON.stringify(data),
      });
      return handleResponse(response) as Promise<ReadonlyResponse>;
    },
    onSuccess: async (_, variables) => {
      toast.success("金額を入力しました");
      await queryClient.invalidateQueries({
        queryKey: woodKeys.wood_receiver(variables.id),
      });
      await queryClient.invalidateQueries({
        queryKey: userKeys.order({ page: 1, pageSize: PAGE_SIZE }),
      });
    },
  });
}

export function useGetWoodByReceiver(id: number) {
  const { getAccessToken } = useAuth();
  return useQuery({
    queryKey: woodKeys.wood_receiver(id),
    queryFn: async () => {
      const { token } = await getAccessToken();

      const response = await fetch(
        `${config.backendUrl}/api/wood/receiver/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `${token}`,
          },
        },
      );
      return handleResponse(response) as Promise<ReadonlyWood>;
    },
    staleTime: Infinity,
    gcTime: Infinity,
  });
}
