export const handleResponse = async (response: Response) => {
  const responseJson = await response.json();
  if (response.ok) {
    // データが配列の場合、日付を変換
    if (Array.isArray(responseJson)) {
      return responseJson.map((item) => ({
        ...item,
        created_at: item.created_at ? new Date(item.created_at) : null,
        updated_at: item.updated_at ? new Date(item.updated_at) : null,
      }));
    }

    // データがオブジェクトの場合
    if (responseJson.created_at || responseJson.updated_at) {
      return {
        ...responseJson,
        created_at: responseJson.created_at
          ? new Date(responseJson.created_at)
          : null,
        updated_at: responseJson.updated_at
          ? new Date(responseJson.updated_at)
          : null,
      };
    }

    return responseJson;
  } else {
    const error = responseJson.error || "エラーが発生しました";
    throw new Error(error);
  }
};
