import { Box, Pagination } from "@mui/material";

interface PaginationProps {
  totalPages: number;
  page: number;
  handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
  isSmallScreen: boolean;
}

export const PaginationComponent = ({
  totalPages,
  page,
  handlePageChange,
  isSmallScreen,
}: PaginationProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
      <Pagination
        count={totalPages}
        page={page}
        onChange={handlePageChange}
        color="primary"
        variant="outlined"
        shape="rounded"
        size={isSmallScreen ? "small" : "medium"}
      />
    </Box>
  );
};
